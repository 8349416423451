import { useMutation } from 'react-query'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { AxiosError } from 'axios'

import { AuthApi } from '@api'
import { ROUTE_KEYS } from '@constants'

const useChangePassword = () => {
  const { t } = useTranslation()

  const history = useHistory()

  const { LOGIN } = ROUTE_KEYS

  const { mutate: changePassword, isLoading } = useMutation(AuthApi.changePassword, {
    onSuccess: () => {
      notification.success({
        message: t('login.change_password_success'),
      })
      history.push(LOGIN)
    },
    onError: (error: AxiosError) => {
      const message =
        error.response?.status === 401 ? t('login.link_expired') : t('login.change_password_error')
      notification.error({
        message,
      })
      history.push(`${LOGIN}/recover`)
    },
  })

  return {
    changePassword,
    isLoading,
  }
}

export { useChangePassword }

import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useGetPriorities = () => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.PRIORITIES],
    () => {
      return DataApi.getPriorities()
    },
    { staleTime: Infinity },
  )
  return { priorities: data?.data.values, isLoading }
}

export { useGetPriorities }

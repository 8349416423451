import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useGetDelegationsCombo = () => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.DELEGATIONS_COMBO],
    () => {
      return DataApi.getDelegationsCombo()
    },
    { staleTime: Infinity },
  )
  return { delegations: data?.data, isLoading }
}

export { useGetDelegationsCombo }

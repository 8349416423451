import { useEffect, useState } from 'react'
import { useRouteMatch, Redirect, Route } from 'react-router-dom'
// eslint-disable-next-line import/no-unresolved
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'

import { FiltersProvider, useUser } from '@contexts'
import { LOCAL_STORAGE_KEYS, ROUTE_KEYS } from '@constants'
import { ComponentList } from '@types'

import { CustomNavLink } from '../../components/CustomNavLink'
import { RouterLayout } from '../../components/RouterLayout'
import { ContentWrapper, LabelDisplayer, MenuLabelsDisplayer, Nav } from '../common/components'

interface AdminRouterProps {
  options: ComponentList | undefined
}

const DinamicRouter = ({ options }: AdminRouterProps) => {
  const { url } = useRouteMatch()
  const [displayLabels, changeDisplayLabels] = useState(false)
  const [urlState, setUrlState] = useState<string | undefined>(undefined)
  const { state } = useUser()
  const { BASE_URL, HOME } = ROUTE_KEYS[state!.level]

  const handleDisplayLabels = () => {
    if (displayLabels) {
      changeDisplayLabels(false)
    } else {
      changeDisplayLabels(true)
    }
  }

  const lastLocation = window.localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_LOCATION)

  useEffect(() => {
    if (url.length > 1) {
      setUrlState(url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RouterLayout>
      <Nav>
        {options?.map(option => (
          <CustomNavLink
            key={option.name}
            url={urlState}
            option={option.name as string}
            displayLabels={displayLabels}
            icon={option.icon}
          />
        ))}
        <LabelDisplayer>
          <MenuLabelsDisplayer onClick={handleDisplayLabels}>
            {displayLabels === false ? <RightCircleOutlined /> : <LeftCircleOutlined />}
          </MenuLabelsDisplayer>
        </LabelDisplayer>
      </Nav>
      <ContentWrapper>
        <Redirect
          exact
          path="/"
          to={lastLocation !== null ? `${lastLocation}` : `${BASE_URL + HOME}`}
        />
        {options?.map(option => (
          <FiltersProvider key={option.name}>
            <Route path={`${url}/${option.name as string}`} component={option.component} />
          </FiltersProvider>
        ))}
      </ContentWrapper>
    </RouterLayout>
  )
}

export { DinamicRouter }

/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useContext } from 'react'

import { FilterBulkUploadType, FilterContextType } from '@types'

type ContextState = {
  filters: FilterContextType
  setFilters: (newstate: FilterContextType) => void
  filtersAux: FilterContextType
  setFiltersAux: (newstate: FilterContextType) => void
  bulkDataFilters: FilterBulkUploadType
  setBulkDataFilters: (newstate: FilterBulkUploadType) => void
}

const initialState = {
  filters: {
    filters: {
      limit: 10,
    },
    orderBy: {
      field: 'id',
      order: 'desc',
    },
  },
  setFilters: () => {},
  filtersAux: {
    filters: {
      limit: 10,
    },
    orderBy: {
      field: 'id',
      order: 'desc',
    },
  },
  setFiltersAux: () => {},
  bulkDataFilters: {
    filters: {
      limit: 5,
    },
    orderBy: {
      field: 'id',
      order: 'desc',
    },
  },
  setBulkDataFilters: () => {},
}

const FiltersContext = React.createContext<ContextState>(initialState)

export const FiltersProvider: React.FC = ({ children }) => {
  const [filters, setFilters] = useState<FilterContextType>(initialState.filters)
  const [filtersAux, setFiltersAux] = useState<FilterContextType>(initialState.filtersAux)
  const [bulkDataFilters, setBulkDataFilters] = useState<FilterBulkUploadType>(
    initialState.bulkDataFilters,
  )
  return (
    <FiltersContext.Provider
      value={{
        filters,
        setFilters,
        filtersAux,
        setFiltersAux,
        bulkDataFilters,
        setBulkDataFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}

export function useFilters(): ContextState {
  const context = useContext(FiltersContext)
  if (!context) throw Error('This hook can only be used under the FilterProvider component')
  return context
}

import { useMutation, useQueryClient } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useModifyOrder = () => {
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation(DataApi.updateOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.ORDERS)
      queryClient.invalidateQueries(QUERY_KEYS.ORDER)
    },
  })

  return { put: mutate, isLoading }
}

export { useModifyOrder }

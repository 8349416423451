import { useQuery } from 'react-query'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'
import { RecordTypeEnum } from '@types'

const useGetPdfRecord = (surveyId: number, recordTypeAux: RecordTypeEnum) => {
  const { t } = useTranslation()
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.PDF_RECORD, surveyId],
    () => {
      return DataApi.getPdfRecord(surveyId, recordTypeAux)
    },
    {
      onError: () => {
        notification.error({
          message: t('globals.download_pdf_error'),
        })
      },
      enabled: !!recordTypeAux && !!surveyId && surveyId > 0,
      staleTime: Infinity,
    },
  )
  return { pdf: data, pdfIsLoading: isLoading }
}

export { useGetPdfRecord }

import { useMutation } from 'react-query'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { DataApi } from '@api'

const useDownloadAttachment = () => {
  const { t } = useTranslation()
  const { mutate, isLoading } = useMutation(DataApi.downloadAttachment, {
    onSuccess: async res => {
      notification.close('downloadingFile')
      notification.success({
        message: `${res.filename} ${t('globals.download_attachment_success')}`,
      })
      const link = document.createElement('a')
      const url = window.URL.createObjectURL(res.data)
      link.href = url
      link.setAttribute('download', res.filename)
      document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)
    },
    onError: () => {
      notification.close('downloadingFile')
      notification.error({
        message: t('globals.download_attachment_error'),
      })
    },
  })

  return { download: mutate, isDownloading: isLoading }
}

export { useDownloadAttachment }

const size = {
  xs: '575px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
}
const primaryColor = '#97b539'
const primaryColorHover = '#96B539E0'
const secundaryColor = '#625d20'
const darkGreenText = '#59551b'
const darkText = '#262626'
const inputLabel = '#4D4D4D'
const inputBg = '#FFFFFF'
const lightGray = '#F0F2F5'
const gray = '#D9D9D9'
const darkGray = '#CBCBCB'
const lightBlack = '#5F5E5E'
const secondaryDarkGray = '#939393'
const grayCE = '#CECECE'
const green = '#3DD78D'
const red = '#E1394D'
const backgroundColor = '#FAFAFA'
const disabledInput = '#F2F2F2'
const oddRows = '#F9F9F9'
const selectedRows = '#96B5391E'
const recordTitlesBg = '#B2B097'
const infantRecordColor = '#F6A44C'
const adolescentRecordColor = '#42B8B2'
const ielrRecordColor = '#E51E2F'
const defaultRecordClientColor = '#8C861B'
const greenHeaderModal = '#385623'
const yellow = '#FFD333'

const theme = {
  xs: `(max-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
  xxl: `(min-width: ${size.xxl})`,
  '@primary-color': primaryColor,
  '@input-bg': inputBg,
  '@border-radius-base': '2px',
  '@table-header-bg': 'white',
  '@divider-color': 'black',
  primaryColor,
  primaryColorHover,
  secundaryColor,
  darkGreenText,
  darkText,
  inputBg,
  lightGray,
  gray,
  darkGray,
  lightBlack,
  secondaryDarkGray,
  grayCE,
  red,
  green,
  backgroundColor,
  disabledInput,
  inputLabel,
  oddRows,
  selectedRows,
  recordTitlesBg,
  infantRecordColor,
  adolescentRecordColor,
  ielrRecordColor,
  defaultRecordClientColor,
  greenHeaderModal,
  status: {
    green,
    yellow,
    red,
  },
}

module.exports = { theme }

/* eslint-disable camelcase */
import { FileData } from 'chonky'
import { Moment } from 'moment'
import React from 'react'

export type SVGIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>

export interface User extends ElectronicAddress {
  id: number
  lastName: string
  roles: Role[]
  level: LevelEnum
  token: string
  tokenTtl: number
  name: string
  mail: string
  ttlRefreshToken: number
  refreshToken: string
  refresh: string
}

export enum LevelEnum {
  ADMIN = 'ADMIN',
  INSPECTOR = 'INSPECTOR',
  EMPLOYER = 'EMPLOYER',
  EMPLOYER_NOT_REGISTERED = 'EMPLOYER_NOT_REGISTERED',
}

type Role = {
  id: number
  description: string
}

export interface BinaryObject {
  id: number | string
  name: string
}

export type FilterContextType = {
  orderBy?: OrderByType
  filters?: Filters
}
export type FilterBulkUploadType = {
  orderBy?: OrderByType
  filters?: Filters
}

export type Filters = {
  document?: number
  ageFrom?: number
  ageTo?: number
  surveyId?: number
  provisionalId?: number
  orderId?: number
  dateRange?: Moment[]
  dateFrom?: string
  dateTo?: string
  cuit?: string
  name?: string
  priority?: number
  delegationId?: number[]
  lastname?: string
  file?: string
  limit?: number
  page?: number
  [key: string]: unknown
}

export type OrderFormFilterValues = {
  dateRange?: Moment[]
  dateFrom?: string
  dateTo?: string
  cuit?: string
  priority?: number
  delegationId?: number[]
  limit?: number
  page?: number
  field?: string
  order?: string
}

export type InspectorsFormFilterValues = {
  name: string
  lastname: string
  delegationId: number[]
  file?: string
  limit?: number
  page?: number
  order?: string
  orderBy?: string
}

export type SurveyFormFilterValues = {
  dateRange?: Moment[]
  dateFrom?: string
  dateTo?: string
  surveyId?: number
  provisionalId?: number
  orderId?: number
  limit?: number
  page?: number
  field?: string
  order?: string
  cuit?: string
  responsibleInspector?: string
}

export type WorkersFormFilterValues = {
  lastname?: string
  document?: number
  ageFrom?: number
  ageTo?: number
  orderId?: number
  limit?: number
  page?: number
  field?: string
  order?: string
}

export type OrderByType = {
  field?: string
  order?: string
}

export interface ComboType {
  id: number
  name: string
}

export interface ComboWithRequiresDetailType extends ComboType {
  requires_detail?: boolean
}

export interface ModifyFormVars {
  orderId?: number
  visible: boolean
}

export interface DbExceptionType {
  data: { code: string; message: string }
}

export interface RefreshToken {
  refreshToken: string
}

export interface SearchParams {
  value: string | number
  endpointCategory: string
}

export interface AuthResponse {
  data: {
    user: User
    token: string
    refreshToken: string
  }
}

export type InspectorNoteType = {
  is_responsible: boolean
  date: string
  fullname: string
  note: string
  name: string
}

export type MediaType = {
  original: string
  thumbnail: string
}

export interface SurveyType {
  [key: string]: unknown
  id: number
  surveyId: number
  as: string
  provisionalId: string
  inspectionOrderId: string
  reason: string
  reasonDetail: string
  startDate: string
  startTime: string
  endDate: string
  documentType: string
  documentNumber: string
  endTime: string
  cuit: string
  business: string
  establishmentName: string
  fiscalizationDate: string
  responsibleInspector: string
  auxiliarInspectors: string[]
  presents: string
  workersQuantity: number
  solidarityCuits: SolidaryCuit[]
  hasIELR: boolean
  solidarityObservations: string
  peopleByBedRoom: string
  cookingFuel: string
  cookingElementsState: string
  ielrObservations: string
  hasAccommodationForWorkers: boolean
  electricalInstallationState: string
  bedroomsQuantity: number
  hasElectricity: string
  otherHasForSleep: string
  hasForSleep: string
  hasBathroom: boolean
  hasBathroomInside: boolean
  drinkingWather: boolean
  hasRefrigerator: boolean
  whaterCameFrom: string
  streetType: string
  streetNumber: number
  streetName: string
  province: string
  department: string
  locality: string
  place: string
  latitude: string
  mainActivity: string
  secundaryActivity: string
  longitude: string
  attendedBy: string
  recordTypes: string[]
  expContribution: number
  expInfraction: string
  status: string
  employerObservations: string
  bathroomHas: string
  ielrOtherBathroomHas: string
  ielrOtherFloorMaterial: string
  ielrOtherRoofMaterial: string
  ielrOtherWallMaterial: string
  roofMaterial: string
  wallMaterial: string
  floorMaterial: string
  isResponsible?: boolean
}

export interface RecordType {
  id: number
  inspectionOrderId: string
  startDate: string
  cuit: string
  establishment: string
  recordTypes: string[]
  [key: string]: unknown
}

export enum SheetTypeEnum {
  ORIGINAL = 'ORIGINAL',
  DUPLICATED = 'DUPLICATED',
}

export type RecordDatesType = {
  startDate: Date
  endDate: Date
}

export enum RecordTypeEnum {
  SURVEY_RECORD = 'SURVEY_RECORD',
  ADOLESCENT_WORK_VERIFICATION_RECORD = 'ADOLESCENT_WORK_VERIFICATION_RECORD',
  INFANT_WORK_VERIFICATION_RECORD = 'INFANT_WORK_VERIFICATION_RECORD',
  IELR_COMPLEMENTARY_RECORD = 'IELR_COMPLEMENTARY_RECORD',
  RURAL_EMPLOYMENT_CONTRACT_INDICATORS = 'RURAL_EMPLOYMENT_CONTRACT_INDICATORS',
  FIRST_VERIFICATION = 'FIRST_VERIFICATION',
}

export enum SignOtherRequiredInfoEnum {
  DNI = 'DNI',
  NUMBER = 'N°',
  CARACTER = 'CARÁCTER',
}

export interface InspectorsType {
  id: number
  name: string
  lastName: string
  email: string
  publicKey: string
  privateKey: string
  file: string
  password?: string
}
export interface RecordInterface {
  workers: CompleteWorkerDataInterface[][]
  recordId: number
  surveyId: number
  recordType: RecordTypeEnum
  provisionalId: number
  inspectionOrder: number
  inspectors: InspectorsType[]
  completeName: string
  date: RecordDatesType
  responsibleInspector: string
  cuit: string
  bussiness: string
  establishmentName: string
  streetType: string
  streetName: string
  streetNumber: string
  province: string
  postalCode: string
  department: string
  locality: string
  place: string
  latitude: string
  longitude: string
  mainActivity: string
  secundaryActivity: string
  workersQuantity: number
  attendedBy: string
  as: string
  documentType: string
  documentNumber: string
  solidaryCuit: string
  solidaryBussiness: string
  observations: string
  hasIelr: boolean
  suppliesLivingPlace: string
  principalFloorMaterial: string
  principalExteriorWallsMaterial: string
  principalRoofMaterial: string
  toSleepHas: string
  howManyRooms: string
  howManyPersonsPerRoom: number
  hasBathroom: boolean
  bathroomInTheHouse: boolean
  ielrOtherBathroomHas: string
  hasDrinkingWater: boolean
  consumedWaterComesFrom: string
  hasElectricity: boolean
  electricityPresents: string
  cookingFuel: string
  kitchenArtifacts: string
  hasFridge: boolean
}
export interface CompleteWorkerDataInterface extends InfantWorkerType, AdolescentWorkerType {
  workerId: number
  firstname: string
  lastname: string
  address: string
  gender: string
  birthdate: string
  birthdateNSNC: boolean
  age: number
  documentType: string
  documentNumber: string
  showDocument: boolean
  birthplace: string
  countryEntryDate: string
  hasValidDocumentationToWork: boolean
  livesInEstablishment: boolean
  canReadAndWrite: boolean
  attendsToSchool: boolean
  educationLevel: string
  workingSituation: string
  hasIelr: boolean
  observations: string
  streetType: string
  streetName: string
  streetNumber: string
  streetFloor: string
  province: string
  department: string
  locality: string
  workEntryDate: string
  currentActivity: string
  paymentMethod: string
  paymentMethodName: string
  netRemuneration: number
  workMethod: string
  workMethodDetail: string
  workerFromEmploymentOffice: boolean
  hasExtraHours: boolean
  hasExtraHoursPayment: boolean
  dailyRest: string
  workingDays: workingDaysType[]
  paymentModalityObservations: string
  performDangerousTasks: boolean
  performDangerousTasksDetail: string
  sleepOnSpot: boolean
  liveWith: string
  childrenExploitationChildDetailObservations: string
  hasTimeExtensionAuthorization: boolean
  hasParentalAuthorization: boolean
  hasPhysicalFitnessCertificate: boolean
  hasSchoolCertificate: boolean
  hasEducationalInternshipCertificate: boolean
  hasChildren: boolean
  childrenNumber: string
  worksInFamilyBusiness: boolean
  hasYoungerSinblingsWorking: boolean
  hasYoungerSinblingsWorkingInThisEstablishment: boolean
  childrenExploitationLastname: string
  childrenExploitationFirstname: string
  childrenExploitationDocumentType: string
  childrenExploitationDocumentNumber: string
  relationshipLink: string
  generalAgriculturalWorkCondition: string
  generalAgriculturalWorkConditionType: RuralContractIndicatorsTypeEnum
  supervisor: string
  supervisorWorksFor: string
  whoPaysSalary: string
  whoPaysTools: string
  workerIsSuppliedOf: BinaryObject[]
  hasOwnWorkers: boolean
  hasOtherActivity: boolean
  workingFor: string
  cooperativeName: string
  workSinceDate: string
  signedApplication: boolean
  admissionFree: boolean
  hasRegulationCopy: boolean
  attendCooperativeAssemblies: boolean
  citedToAssemblies: boolean
  howWasItCited: string
  knowAuthorities: boolean
  relationshipGrade: string
  liveInEstablishment: boolean
  thereMembersWithoutRelationship: boolean
  linkType: string
  signedContract: boolean
  display: boolean
  isApprobed: boolean
  whoSellsAndDistributes: string
  linkTypeObservations: string
  howHaveYouHeardAboutWork: string
  howHaveYouHeardAboutWorkDetail: string
  howDidYouGetToWork: string
  howDidYouGetToWorkDetail: string
  employerPaysTripExpenses: boolean
  withheldDocumentation: boolean
  whoWithheldDocumentation: string
  freeToLeaveTheEstablishment: boolean
  reasonsForRestrictiveCirculation: string
  reasonsForRestrictiveCirculationDetail: string
  canCommunicateWithFamily: boolean
  cannotCommunicateWithFamilyDetail: string
  forcedToSleepThere: boolean
  receivedALoan: boolean
  salaryIsDiscounted: boolean
  discountedConcepts: BinaryObject[]
  discountedConceptsDetail: string
  salariesPaidOnTime: boolean
  owePayments: boolean
  sufferedViolence: boolean
  whatTypeOfViolences: BinaryObject[]
  whatTypeOfViolenceDetail: string
  hasWorkwear: boolean
  hasEpp: boolean
  workerIelrObservations: string
  employmentExchangeWorker: boolean
  workerObservations: string
  nationality: string
  childrenExploitationObservations: string
}

export type workingDaysType = {
  dayOfWeek: string
  startsAt: string
  endsAt: string
}

export enum DataUploadStatusEnum {
  CREATED = 'CREATED',
  IN_PROCESS = 'IN_PROCESS',
  PROCESSED = 'PROCESSED',
  PROCESSED_WITH_ERRORS = 'PROCESSED_WITH_ERRORS',
  NOT_PROCESSED = 'NOT_PROCESSED',
}

export type DataUploadHistoryType = {
  id: number
  createdAt: {
    date: Date
    timezone: string
    timezone_type: number
  }
  name: string
  state: DataUploadStatusEnum
  observations?: string
}

export enum SignEntityEnum {
  RESPONSIBLE = 'Inspector responsable',
  INSPECTORS = 'Inspector/es Actuante/s',
  EMPLOYER = 'Empleador o Responsable',
}

export type SignFieldType = {
  entity: SignEntityEnum
  requiredInfo?: SignOtherRequiredInfoEnum
}

export interface FileAttachType {
  name: string
  size: number
  file: unknown
}

export interface FileAttachPayloadType {
  surveyId: number
  data: FileAttachType
}

export interface AttachmentType {
  id: number | string
  filename: string
}

export enum ActionsEnum {
  DELETE = 'DELETE',
  DOWNLOAD = 'DOWNLOAD',
}

export interface Establishment {
  establishmentId: number
  establishmentName?: string
  streetType?: string | null
  streetName?: string | null
  streetNumber?: number | null
  provinceId?: number | null
  departmentId?: number | null
  localityId?: number | null
  place?: string | null
  delegationId?: number | null
  activityId?: number | null
  latitude?: string | null
  longitude?: string | null
}

export interface Employer {
  employerCuit: string
  bussinessName: string
  employeesQuantity?: number | null
  establishments?: Establishment[] | null
}

export interface EmployerSurveys {
  employerCuit?: string | undefined
  bussinessName?: string | undefined
  surveys?: EmployerSurvey[] | undefined
}

export interface EmployerSurvey {
  surveyNumber: string | number
  establishmentName: string
}

export interface GetEmployerResponse {
  data: Employer
}

export interface SolidaryCuit {
  cuit: number
  business: string
}

export interface AuxiliarInspector {
  fullname: string
  file: string
}

export interface Attachment extends FileData {
  original_filename: string
}

export interface SurveyRecordType {
  recordId: number
  recordType: RecordTypeEnum
  inspectors: string[]
  surveyId: number
  surverId: number
  provisionalId: number
  printDate: string
  inspectionOrder: number
  completeName: string
  startDate: string
  endDate: string
  responsibleInspector: string
  cuit: string
  business: string
  establishmentName: string
  streetType: string
  streetName: string
  streetNumber: string
  province: string
  postalCode: string
  department: string
  locality: string
  place: string
  latitude: string
  longitude: string
  mainActivity: string
  secundaryActivity: string
  attendedBy: string
  as: string
  documentType: string
  documentNumber: string
  solidarityCuits: {
    cuit: string
    business: string
  }[]
  solidarityObservations: string
  observations: string
  hasIelr: boolean
  suppliesLivingPlace: string
  principalFloorMaterial: string
  principalExteriorWallsMaterial: string
  principalRoofMaterial: string
  toSleepHas: string
  howManyRooms: string
  howManyPersonsPerRoom: string
  hasBathroom: boolean
  bathroomInTheHouse: boolean
  ielrOtherBathroomHas: string
  hasDrinkingWater: boolean
  consumedWaterComesFrom: string
  hasElectricity: boolean
  electricityPresents: string
  cookingFuel: string
  kitchenArtifacts: string
  hasFridge: boolean
  workers: CompleteWorkerDataInterface[]
}

export interface InfantWorkerType extends RecordWorkerType {
  attendsToSchool: boolean
  workEntryDate: string
  currentActivity: string
  worksInFamilyBusiness: boolean
  paymentMethod: string
  paymentMethodName: string
  netRemuneration: number
  paymentModalityObservations: string
  performDangerousTasks: boolean
  performDangerousTasksDetail: string
  liveWith: string
  liveWithDetail: string | null
  relationshipLink: string
  relationshipLinkDetail: string | null
  childrenExploitationFirstname: string
  childrenExploitationLastname: string
  childrenExploitationDocumentType: string
  childrenExploitationDocumentNumber: string
  hasYoungerSinblingsWorking: boolean
  hasYoungerSinblingsWorkingInThisEstablishment: boolean
  childrenExploitationChildDetailObservations: string
  childrenExploitationObservations: string
}

export interface AdolescentWorkerType extends RecordWorkerType {
  hasValidDocumentationToWork: boolean
  attendsToSchool: boolean
  workEntryDate: string
  currentActivity: string
  paymentMethod: string
  paymentMethodName: string
  netRemuneration: number
  workMethod: string
  workMethodDetail: string
  workerFromEmploymentOffice: boolean
  performDangerousTasks: boolean
  performDangerousTasksDetail: string
  liveWith: string
  hasTimeExtensionAuthorization: boolean
  hasParentalAuthorization: boolean
  hasPhysicalFitnessCertificate: boolean
  hasSchoolCertificate: boolean
  hasEducationalInternshipCertificate: boolean
  hasChildren: boolean
  howManyChildren: string
  workerObservations: string
  employmentExchangeWorker: boolean
}

export interface RecordWorkerType {
  workerId: number
  firstname: string
  lastname: string
  gender: string
  canReadAndWrite: boolean
  birthdate: string
  age: number
  totalWorkTime: {
    hours: string | number
    minutes: string | number
  }
  workingDays: workingDaysType[]
  documentType: string
  documentNumber: string
  educationLevel: string
  showDocument: boolean
  nationality: string
  countryEntryDate: string
  livesInEstablishment: boolean
  streetType: string
  streetName: string
  streetNumber: string
  streetFloor: string
  province: string
  department: string
  city: string
  paymentCollectMethod: string | null
  paymentCollectMethodDetail: string
}

export enum RuralContractIndicatorsEnum {
  NONE = 'Ninguna',
  COOPERATIVE = 'Cooperativa',
  FAMILIAR_ECONOMY = 'Economía Familiar',
  LEASE_SHARECROPPING_DAIRY_ASSOCIATIVISM = 'Arrendamiento - Aparcería - Mediería ó Asociativismo Tambero',
}

export enum MediaTypes {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
}

export enum RuralContractIndicatorsTypeEnum {
  SHARECROPPING = 'SHARECROPPING',
  COOPERATIVE = 'COOPERATIVE',
  FAMILIAR_ECONOMY = 'FAMILIAR_ECONOMY',
  NONE = 'NONE',
}

export interface ExpedientType {
  id: number
  createdAt: string
  status: string
  procedure: string
  surveyId: number
  cuit: string
  establishment: string
  editable: boolean
  surveys: number[]
  [key: string]: unknown
}

export type Certificate = string

export interface UrlSignParams {
  certificates?: Certificate[]
  itemType?: RecordTypeEnum | string
  itemId?: string
  signed?: boolean
  [key: string]: unknown
}

export type ComponentList = ComponentOption[]

export interface ComponentOption {
  name: string
  component: () => JSX.Element
  icon: () => JSX.Element
  allowedRoles: LevelEnum[]
}

export interface InspectionOrder {
  activity: string
  city: string
  createdAt: string
  cuit: string
  delegation: string
  department: string
  employer: string
  establishment: string
  fiscalizationDate: string
  id: number
  priority: string
  province: string
  responsibleInspector: string
  status: string
  toInspect: boolean
}

export interface ElectronicAddress {
  cuit: number
  email: string
  enableNotifications: boolean
}

export interface Georeference {
  latitude?: string | number
  longitude?: string | number
  description: string
}

export interface UnregisteredPayload {
  cuit: string
  provisionalId: string
  recaptchaToken: string
}

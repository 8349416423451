import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'
import { FilterBulkUploadType } from '@types'

const useGetBulkDataHistory = (params?: FilterBulkUploadType) => {
  const { data, isLoading } = useQuery([QUERY_KEYS.UPLOAD_BULK_DATA_HISTORY, params], () => {
    return DataApi.getBulkDataHistory(params)
  })
  return { bulkDataHistory: data?.data, isLoading }
}

export { useGetBulkDataHistory }

import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { LinkLabel } from '../../features/common/components'

interface CustomNavLinkProps {
  option: string
  displayLabels: boolean
  icon: () => JSX.Element
  url: string | undefined
}

const CustomNavLink = ({ option, displayLabels, icon, url }: CustomNavLinkProps) => {
  const { t } = useTranslation()
  const title = t(`globals.sections.${option}.name`)

  return (
    <>
      {url ? (
        <NavLink className="menu-navlink" to={`${url}/${option}`}>
          <Tooltip placement="right" title={title}>
            {icon()}
          </Tooltip>
          {displayLabels && <LinkLabel>{title}</LinkLabel>}
        </NavLink>
      ) : (
        ''
      )}
    </>
  )
}

export { CustomNavLink }

import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { RegisteredOrderType } from 'src/api/data'
import { useGetOrder } from '@hooks'

import OrderForm from '../OrderForm'
import { useModifyOrder } from '../../hooks/useModifyOrder'

notification.config({
  placement: 'topRight',
  duration: 10,
})

interface ModifyOrderPropsType {
  orderId: number | undefined
  isVisible: boolean
  onClose: () => void
  editable: boolean
}

const SeeOrderModal = ({ orderId, isVisible, onClose, editable }: ModifyOrderPropsType) => {
  const { t } = useTranslation()
  const tPrefix = 'globals.sections.orders.'
  const { put, isLoading } = useModifyOrder()
  const { order } = useGetOrder(orderId)

  const update = (
    values: RegisteredOrderType,
    isEmployerRegistered: boolean | undefined,
    reset: () => void,
  ) => {
    let toUpdate = false
    const valuesKeys = Object.keys(values)
    valuesKeys.forEach((key: string) => {
      if (order) {
        if (values[key] !== order[key]) {
          toUpdate = true
        }
      }
    })
    if (toUpdate) {
      const payload = {
        ...values,
        id: orderId || 0,
        isEmployerRegistered,
      }
      put(payload, {
        onSuccess: () => {
          notification.success({
            message: t(`${tPrefix}edit_success`) + order?.id,
          })
          onClose()
          reset()
        },
        onError: () => {
          notification.error({
            message: t(`${tPrefix}edit_error`),
          })
        },
      })
    } else {
      notification.warning({
        message: t(`${tPrefix}edit_rejected`),
      })
    }
  }

  return (
    <div>
      {order && (
        <OrderForm
          order={order}
          modalTitle={
            !editable
              ? t('globals.view_registry.close_order.title')
              : t('globals.view_registry.see_order.title')
          }
          editable={editable}
          visible={isVisible}
          onSave={update}
          onCancel={onClose}
          isLoading={isLoading}
        />
      )}
    </div>
  )
}

export default SeeOrderModal

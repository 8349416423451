/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useContext, useCallback } from 'react'

import { LevelEnum, User } from '@types'
import { LOCAL_STORAGE_KEYS } from '@constants'

type ContextState = {
  state: User | undefined
  setState: (newstate: User | undefined) => void
  is: (level: LevelEnum) => boolean
}

const initialState = {
  state: undefined,
  setState: () => {},
  is: () => false,
}

const UserContext = React.createContext<ContextState>(initialState)

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [state, setUser] = useState<User>(() => {
    const localUser = window.localStorage.getItem(LOCAL_STORAGE_KEYS.USER)
    if (localUser) return JSON.parse(localUser)
    return null
  })
  const setState = useCallback(updatedUser => {
    setUser(updatedUser)
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(updatedUser))
  }, [])
  const is = (level: LevelEnum) => {
    return level === state?.level
  }
  return <UserContext.Provider value={{ state, setState, is }}>{children}</UserContext.Provider>
}

export function useUser(): ContextState {
  const context = useContext(UserContext)
  if (!context) throw Error('This hook can only be used under the UserProvider component')
  return context
}

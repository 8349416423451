import { notification, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { useGetHistory } from '@hooks'

import { StandardModal, GroupTitle } from '../../../components'

notification.config({
  placement: 'topRight',
  duration: 10,
})

interface HistoryOrderPropsType {
  orderId: number | undefined
  isVisible: boolean
  onClose: () => void
}

const HistoryOrderModal = ({ orderId, isVisible, onClose }: HistoryOrderPropsType) => {
  const { t } = useTranslation()
  const tPrefix = 'globals.sections.orders.'
  const { orderHistory } = useGetHistory(orderId)
  const { Text } = Typography

  return (
    <StandardModal
      visible={isVisible}
      title={<h1>{t(`${tPrefix}history.modal_title`) + orderId}</h1>}
      onCancel={() => {
        onClose()
      }}
      footer={false}
      centered
      width={850}
    >
      {orderHistory?.map(item => (
        <>
          <GroupTitle>{item.date}</GroupTitle>
          <Text strong>{t('globals.fields.status.title')}: </Text>
          <Text>{item.ioState}</Text>
          <br />
          <Text strong>{t('globals.fields.responsible')}: </Text>
          <Text>{`${item.responsibleName} (${item.role})`}</Text>
          {item.cause && (
            <>
              <br />
              <Text strong>{t('globals.fields.cause.title')}: </Text>
              <Text>{item.cause}</Text>
            </>
          )}
          {item.comment && (
            <>
              <br />
              <Text strong>{t('globals.fields.comment')}: </Text>
              <Text>{item.comment}</Text>
            </>
          )}
        </>
      ))}
    </StandardModal>
  )
}

export default HistoryOrderModal

import { useState } from 'react'
import { Form, Input, Row, Spin } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Recaptcha from 'react-google-recaptcha'

import { config } from '@config'
import { ROUTE_KEYS } from '@constants'

import { StyledWrapper, FormWrapper, StyledButton, StyledRow } from '../components'
import { useRecoverPass } from '../hooks'

const RecoverPassword = () => {
  const { t } = useTranslation()

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

  const { recoverPass, isLoading } = useRecoverPass({
    onSuccess: () => {
      form.resetFields()
    },
  })

  const [form] = Form.useForm()

  const onChangeCaptcha = () => {
    setIsSubmitDisabled(false)
  }

  const onExpiredCaptcha = () => {
    setIsSubmitDisabled(true)
  }

  const { LOGIN } = ROUTE_KEYS
  return (
    <StyledWrapper>
      <StyledRow align="middle">
        <FormWrapper span={24}>
          <Spin spinning={isLoading}>
            <Form
              form={form}
              name="recover_pass"
              initialValues={{ remember: true }}
              onFinish={recoverPass}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please enter your username' }]}
              >
                <Input prefix={<UserOutlined />} placeholder="Username" />
              </Form.Item>
              <Form.Item>
                <Row justify="center">
                  <Recaptcha
                    sitekey={config.recaptchaKey!}
                    onChange={onChangeCaptcha}
                    onExpired={onExpiredCaptcha}
                  />
                </Row>
              </Form.Item>
              <StyledButton
                type="primary"
                htmlType="submit"
                size="large"
                disabled={isSubmitDisabled}
              >
                {t('login.recover_password')}
              </StyledButton>
              <Form.Item>
                <Link to={LOGIN}>{t('login.login')}</Link>
              </Form.Item>
            </Form>
          </Spin>
        </FormWrapper>
      </StyledRow>
    </StyledWrapper>
  )
}

export { RecoverPassword }

import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'
import { FilterContextType, LevelEnum } from '@types'

const allowedRoles = [LevelEnum.ADMIN, LevelEnum.INSPECTOR]

const useGetWorkers = (filtersAux: FilterContextType, surveyId: number, role?: LevelEnum) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.WORKERS, filtersAux],
    () => {
      return DataApi.getWorkers(surveyId, filtersAux)
    },
    {
      enabled: surveyId > 0 && role && allowedRoles.includes(role),
    },
  )
  return { workers: data, workersIsLoading: isLoading }
}

export { useGetWorkers }

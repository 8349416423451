import { useTranslation } from 'react-i18next'
import { notification, Tooltip } from 'antd'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'
import { useQueryClient } from 'react-query'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useGetInspectors } from '@hooks'
import { useFilters } from '@contexts'
import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'
import { OtherUtils } from '@utils'
import { ColumnTitleRender } from '@components'

import {
  CardWrapper,
  CardWrapperHeader,
  PageName,
  Options,
  CardWrapperTable,
  OptionsWrapper,
} from '../components'
import InspectorsFilter from './components/InspectorsFilter'

const Inspectors = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { filters, setFilters } = useFilters()
  const { inspectors, isLoading } = useGetInspectors(filters)
  const queryClient = useQueryClient()
  const [isSync, setIsSync] = useState(false)

  OtherUtils.handleLastLocation(pathname)

  const updateInspectorsOnServer = async () => {
    setIsSync(true)
    notification.close('sync_inspectors')
    notification.info({
      message: t('globals.sections.inspectors.sync_inspectors'),
      key: 'sync_inspectors',
    })
    const response = await DataApi.syncInspectors()
    queryClient.resetQueries([QUERY_KEYS.INSPECTORS])
    notification.close('sync_inspectors')
    notification.success({ message: response.data, key: 'sync_inspectors' })
    setIsSync(false)
  }

  const columns = [
    {
      title: ColumnTitleRender({ tKey: 'id', key: 'id', sortable: true }),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: ColumnTitleRender({ tKey: 'file', key: 'file', sortable: true }),
      dataIndex: 'file',
      key: 'file',
    },
    {
      title: ColumnTitleRender({ tKey: 'name', key: 'name', sortable: true }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: ColumnTitleRender({ tKey: 'lastname', key: 'lastName', sortable: true }),
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: ColumnTitleRender({ tKey: 'email', key: 'email', sortable: true }),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: ColumnTitleRender({ tKey: 'delegation', key: 'delegation', sortable: true }),
      dataIndex: 'delegation',
      key: 'delegation',
    },
  ]

  return (
    <CardWrapper>
      <CardWrapperHeader>
        <PageName>
          <h1>{t('globals.sections.inspectors.name')}</h1>
        </PageName>
        <Options>
          <OptionsWrapper>
            <InspectorsFilter />
            <Tooltip
              title={
                isSync
                  ? t('globals.sections.inspectors.sync_inspectors')
                  : t('globals.tooltips.update')
              }
            >
              {isSync ? <LoadingOutlined /> : <ReloadOutlined onClick={updateInspectorsOnServer} />}
            </Tooltip>
          </OptionsWrapper>
        </Options>
      </CardWrapperHeader>
      <div className="principal-table">
        <CardWrapperTable
          className="inspectors-table"
          columns={columns}
          dataSource={inspectors?.data && !isSync ? inspectors.data : []}
          rowKey="id"
          loading={isLoading || isSync}
          pagination={{
            showSizeChanger: true,
            current: inspectors?.meta.current_page,
            pageSize: filters.filters?.limit,
            total: inspectors?.meta.total_records,
            onChange: (page, pageSize) => {
              setFilters({
                ...filters,
                filters: {
                  ...filters.filters,
                  limit: pageSize ?? 10,
                  page,
                },
              })
            },
          }}
        />
      </div>
    </CardWrapper>
  )
}

export default Inspectors

import { Button, Col, notification, Row, Tooltip, Typography } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { InfoCircleOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons'

import { DataUploadHistoryType, DataUploadStatusEnum } from '@types'
import { BulkDataUploadType } from 'src/api/data'
import { OtherUtils } from '@utils'
import { useFilters } from '@contexts'

import { CardWrapperTable, StandardModal } from '../../../components'
import { useUploadBulkData } from '../../hooks/useUploadBulkData'
import { useGetBulkDataHistory } from '../../hooks/useGetBulkDataHistory'

interface BulkDataUploadProps {
  isVisible: boolean
  onClose: () => void
}

const BulkDataUploadModal = ({ isVisible, onClose }: BulkDataUploadProps) => {
  const [fileBase64, setFileBase64] = useState<undefined | BulkDataUploadType>(undefined)
  const { upload, isUploading } = useUploadBulkData()
  const { t } = useTranslation()
  const tPrefix = 'globals.sections.orders.bulk_data_upload.'
  const { Text } = Typography
  const { bulkDataFilters, setBulkDataFilters } = useFilters()
  const emptyInput = t(`${tPrefix}modal_msg`)
  const [fileChargeStatusMsg, setFileChargeStatusMsg] = useState(emptyInput)
  const { bulkDataHistory, isLoading } = useGetBulkDataHistory(bulkDataFilters)

  const handleImport = () => {
    const ordersToImportInput = document.querySelector('#ordersToImport') as HTMLInputElement
    ordersToImportInput.click()
    ordersToImportInput.onchange = e => {
      const { files } = e.target as HTMLInputElement
      if (files) {
        setFileChargeStatusMsg(files[0].name)
        OtherUtils.convertBase64(files[0])
          .then(data => {
            const base64 = data as string
            setFileBase64({
              name: files[0].name,
              data: base64.replace('data:text/csv;base64,', ''),
            })
          })
          .catch(err => console.log(err))
      } else if (files === null) {
        setFileChargeStatusMsg(emptyInput)
      }
    }
  }

  const formReset = () => {
    const fileInput = document.querySelector('#ordersToImport') as HTMLInputElement
    fileInput.value = ''
    setFileChargeStatusMsg(emptyInput)
  }

  const { PROCESSED_WITH_ERRORS, NOT_PROCESSED } = DataUploadStatusEnum
  const dataUploadErrorStatus = [PROCESSED_WITH_ERRORS, NOT_PROCESSED]

  const columns = [
    {
      key: 'createdAt.date',
      title: t(`${tPrefix}history_table.date`),
      render: ({ createdAt }: DataUploadHistoryType) => moment(createdAt.date).format('lll'),
    },
    {
      key: 'name',
      title: t(`${tPrefix}history_table.file`),
      render: ({ name }: DataUploadHistoryType) => name,
    },
    {
      key: 'state',
      title: t(`${tPrefix}history_table.status.title`),
      render: ({ state }: DataUploadHistoryType) =>
        t(`${`${tPrefix}history_table.status.${state.toLowerCase()}`}.title`),
    },
    {
      key: 'observations',
      title: t(`${tPrefix}history_table.observations`),
      render: ({ observations, state }: DataUploadHistoryType) => {
        let observationMsg = t(
          `${`${tPrefix}history_table.status.${state.toLowerCase()}`}.observation`,
        )
        if (state === NOT_PROCESSED) {
          if (observations?.includes(',')) {
            const arrayAux = observations.split(',')
            observationMsg += ` ${t(
              `${tPrefix}history_table.status.not_processed.header_error_start`,
            )} '${arrayAux[1]}' ${t(
              `${tPrefix}history_table.status.not_processed.header_error_end`,
            )} '${arrayAux[0]}'.`
          }
        } else if (state === PROCESSED_WITH_ERRORS) {
          if (observations?.includes(',')) {
            let preparedString = observations.replace(',', ', ')
            preparedString = preparedString.replace(/,(?=[^,]*$)/, ' y')
            observationMsg += `s ${preparedString}`
          } else {
            observationMsg += ` ${observations}`
          }
        }

        return (
          <Tooltip placement="topRight" title={`${observationMsg}`}>
            {dataUploadErrorStatus.includes(state) ? (
              <WarningOutlined className="status-info error" />
            ) : (
              <InfoCircleOutlined className="status-info" />
            )}
          </Tooltip>
        )
      },
    },
  ]
  const inputReset = () => {
    formReset()
    setFileBase64(undefined)
  }
  const handleUploadBulkData = () => {
    if (fileBase64) {
      upload(fileBase64)
      inputReset()
      notification.info({
        message: 'El archivo ha sido enviado para la carga masiva',
        key: 'submited',
      })
    } else {
      notification.error({ message: 'Debe seleccionar un archivo' })
    }
  }
  return (
    <StandardModal
      visible={isVisible}
      title={<h1>{t(`${tPrefix}modal_title`)}</h1>}
      onCancel={() => {
        onClose()
        inputReset()
      }}
      okText={t('globals.confirm')}
      centered
      width={850}
      footer={false}
    >
      <Col>
        <Row justify="space-between">
          <div>
            <Button type="link" onClick={handleImport}>
              {t(`${tPrefix}browse`)}
            </Button>
            <Text>
              {fileChargeStatusMsg}
              <Tooltip title="Recuerde que debe respetarse el encolumnado y formato">
                <InfoCircleOutlined className="info-upload-orders-modal" />
              </Tooltip>
            </Text>
          </div>
          {fileBase64 && (
            <Button type="primary" onClick={handleUploadBulkData}>
              {t('globals.upload')}
            </Button>
          )}
          {isUploading && (
            <Button type="link">
              <LoadingOutlined />
            </Button>
          )}
        </Row>

        <form action="upload.php" method="post" id="bulkDataForm" encType="multipart/form-data">
          <input
            type="file"
            accept=".xls, .csv, .xlsx"
            id="ordersToImport"
            name="ordersToImport"
            hidden
          />
        </form>
      </Col>
      <CardWrapperTable
        className="bulk-data-table"
        columns={columns}
        rowKey="id"
        loading={isLoading}
        dataSource={isLoading ? undefined : bulkDataHistory?.data}
        pagination={{
          pageSizeOptions: ['5', '10', '20'],
          showSizeChanger: true,
          current: bulkDataHistory?.meta.current_page,
          pageSize: bulkDataFilters.filters?.limit,
          total: bulkDataHistory?.meta.total_records,
          onChange: (page, pageSize) => {
            setBulkDataFilters({
              ...bulkDataFilters,
              filters: {
                ...bulkDataFilters.filters,
                limit: pageSize ?? 5,
                page,
              },
            })
          },
        }}
      />
    </StandardModal>
  )
}

export default BulkDataUploadModal

import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useGetSurveyWorker = (surveyId: number, workerId: number) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.WORKER, surveyId],
    () => {
      return DataApi.getSurveyWorker(surveyId, workerId)
    },
    {
      enabled: !!surveyId && !!workerId && surveyId > 0 && workerId > 0,
      staleTime: Infinity,
    },
  )
  return { surveyWorker: data?.data, surveyWorkerIsLoading: isLoading }
}

export { useGetSurveyWorker }

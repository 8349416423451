import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider } from 'react-query'
import { notification } from 'antd'

import { Layout } from '@components'
import { UserProvider, useUser } from '@contexts'
import { theme, GlobalStyles } from '@styles'
import { removeAuthHeader } from '@api'
import { ROUTE_KEYS } from '@constants'
import { LevelEnum } from '@types'

import { LoginRouter } from './Login'
import { UsersRouter } from './UsersRouter'
import { UnregisteredLogin } from './Login/UnregisteredLogin'

notification.config({
  placement: 'topRight',
  duration: 10,
})

const queryClient = new QueryClient()

const App = () => {
  const { state } = useUser()
  const { LOGIN, UNREGISTERED_LOGIN } = ROUTE_KEYS
  if (state === null) {
    removeAuthHeader()
    window.localStorage.clear()
    window.location.href = LOGIN
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <QueryClientProvider client={queryClient}>
          <Layout>
            <UserProvider>
              <Switch>
                <Route path={LOGIN} component={LoginRouter} />
                {Object.values(LevelEnum).map(level => (
                  <Route key={level} path={`/${level.toLowerCase()}`} component={UsersRouter} />
                ))}
                <Route
                  key={LevelEnum.EMPLOYER_NOT_REGISTERED}
                  path={UNREGISTERED_LOGIN}
                  component={UnregisteredLogin}
                />
                <Redirect to={LOGIN} />
              </Switch>
            </UserProvider>
          </Layout>
        </QueryClientProvider>
      </Router>
    </ThemeProvider>
  )
}

export { App }

import { Empty, notification, Spin, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { InspectorNoteType } from '@types'
import { useGetSurveyNotes } from '@hooks'
import { useAuxiliars } from '@contexts'

import { StandardModal } from '../../../components'

notification.config({
  placement: 'topRight',
  duration: 10,
})

interface SurveyNotesPropsType {
  isVisible: boolean
  onClose: () => void
}

interface NotePropsType {
  inspectorNote: InspectorNoteType
}

const Note = ({ inspectorNote }: NotePropsType) => {
  const { t } = useTranslation()
  const { Text } = Typography

  return (
    <>
      <Text strong>{inspectorNote.fullname}</Text>
      <Text>
        {' '}
        ({`${t(`globals.fields.${inspectorNote.is_responsible ? 'responsible' : 'auxiliar'}`)}`})
      </Text>
      <br />
      <Text style={{ fontSize: '1.125rem', fontWeight: 'bold' }}>{inspectorNote.name}</Text>
      <br />
      <Text>{inspectorNote.note}</Text>
      <br />
      <br />
    </>
  )
}

const NotesSurveyModal = ({ isVisible, onClose }: SurveyNotesPropsType) => {
  const { t } = useTranslation()
  const { activeSurveyId } = useAuxiliars()
  const tPrefix = 'globals.sections.surveys.workers.notes.'
  const { surveyNotes, surveyNotesIsLoading } = useGetSurveyNotes(activeSurveyId)

  return (
    <StandardModal
      className="survey-notes-modal"
      visible={isVisible}
      title={<h1>{`${t(`${tPrefix}modal_title_preffix`) + activeSurveyId}`}</h1>}
      onCancel={() => {
        onClose()
      }}
      footer={false}
      centered
    >
      <Spin spinning={surveyNotesIsLoading}>
        {surveyNotes && surveyNotes.length > 0 ? (
          surveyNotes?.map(item => <Note key={item.date} inspectorNote={item} />)
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
    </StandardModal>
  )
}

export default NotesSurveyModal

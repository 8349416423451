import { api, removeAuthHeader } from '@api'
import { ROUTE_KEYS } from '@constants'
import { useUser } from '@contexts'
import { LevelEnum } from '@types'

const useLogout = () => {
  const { setState, state } = useUser()

  const logout = () => {
    api.post<Response>('/authentication/logout')
    setState(undefined)
    removeAuthHeader()
    window.localStorage.clear()
    window.location.href =
      window.location.origin +
      (state?.level === LevelEnum.EMPLOYER_NOT_REGISTERED
        ? ROUTE_KEYS.UNREGISTERED_LOGIN
        : ROUTE_KEYS.LOGIN)
  }

  return { logout }
}

export { useLogout }

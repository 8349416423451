import { useState } from 'react'
import { notification } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { OrdersType } from 'src/api/data'

import { AddButton } from '../../../components'
import { useAddOrder } from '../../hooks/useAddOrder'
import OrderForm from '../OrderForm'

notification.config({
  placement: 'topRight',
  duration: 10,
})

const AddOrderModal = () => {
  const { t } = useTranslation()
  const tPrefix = 'globals.sections.orders.'
  const [visible, setVisible] = useState(false)
  const { post, isLoading } = useAddOrder()

  const create = (
    values: OrdersType,
    isEmployerRegistered: boolean | undefined,
    reset: () => void,
    setPosting: (state: boolean) => void,
    isResponsibleSelected: boolean,
  ) => {
    const almostOneValidationArray = Object.values(values)
    let almostOneField = false
    almostOneValidationArray.forEach(item => {
      if (!almostOneField && item) {
        if (typeof item === 'object' && item.length === 0) {
          almostOneField = false
        } else {
          almostOneField = true
        }
      }
    })
    if (almostOneField) {
      const payload = {
        ...values,
        isEmployerRegistered,
      }
      post(payload, {
        onSuccess: data => {
          reset()
          setVisible(false)
          notification.success({
            message: `${
              t(`${tPrefix}add_success_prefix`) + data.data?.id + t(`${tPrefix}add_success_suffix`)
            }${
              !isResponsibleSelected
                ? " Quedará 'En elaboracion' hasta que se asigne un inspector."
                : ''
            }`,
          })
        },
        onError: () => {
          notification.error({
            message: t(`${tPrefix}add_error`),
          })
        },
      })
    } else {
      notification.error({
        message: t(`${tPrefix}almost_one_field`),
      })
      setPosting(false)
    }
  }

  return (
    <div>
      <AddButton
        type="primary"
        onClick={() => {
          setVisible(true)
        }}
      >
        <PlusCircleOutlined />
        {t(`${tPrefix}add`)}
      </AddButton>
      <OrderForm
        editable={false}
        order={undefined}
        modalTitle={t('globals.add_registry.new_order.title')}
        visible={visible}
        onSave={create}
        isLoading={isLoading}
        onCancel={() => {
          setVisible(false)
        }}
      />
    </div>
  )
}

export default AddOrderModal

import React, { useEffect, useState } from 'react'
import { Form, Input, Space, Select, Tooltip, Button, Row } from 'antd'
import { ClearOutlined, FilterFilled, FilterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { useFilters } from '@contexts'
import { ComboType, InspectorsFormFilterValues } from '@types'
import { useGetDelegationsCombo } from '@hooks'
import { OtherUtils } from '@utils'

import { FilterTag, InputsRow, FilterModal } from '../../../components'

interface FiltersFormProps {
  clear: boolean
  visible: boolean
  onSearch: (values: InspectorsFormFilterValues) => void
  onCancel: () => void
  setClear: (value: boolean) => void
}

const FiltersForm: React.FC<FiltersFormProps> = ({
  clear,
  visible,
  onSearch,
  onCancel,
  setClear,
}) => {
  const { t } = useTranslation()
  const tPrefix = 'globals.fields.'
  const [form] = Form.useForm()
  const { filters, setFilters } = useFilters()

  useEffect(() => {
    if (clear) {
      form.resetFields()
    }
  })

  useEffect(() => {
    form.setFieldsValue(filters)
  }, [filters, form])

  useEffect(() => {
    if (clear) {
      form.resetFields()
    }
  })

  const { Option } = Select

  const { delegations } = useGetDelegationsCombo()

  const renderTitle = () => {
    return (
      <Space>
        {t('globals.filters.title')}
        <Button
          type="link"
          onClick={() => {
            setFilters({
              filters: {
                page: 1,
                name: undefined,
                lastname: undefined,
                delegationId: undefined,
                file: undefined,
                limit: filters?.filters?.limit,
              },
              orderBy: {
                field: filters.orderBy?.field,
                order: filters.orderBy?.order,
              },
            })
            setClear(true)
          }}
        >
          {t('globals.tooltips.clear')}
          <ClearOutlined />
        </Button>
      </Space>
    )
  }
  const onOk = () => {
    form.validateFields().then(values => {
      onSearch(values)
      setClear(false)
    })
  }
  OtherUtils.onEnterOk(onOk)
  return (
    <FilterModal
      visible={visible}
      title={renderTitle()}
      okText={t('globals.search')}
      cancelText={t('globals.cancel')}
      onCancel={onCancel}
      onOk={onOk}
    >
      <Form form={form} className="ant-advanced-search-form" layout="vertical" name="form_in_modal">
        <InputsRow>
          <Space>
            <Form.Item name="name">
              <Input placeholder={t(`${tPrefix}name.title`)} allowClear />
            </Form.Item>
            <Form.Item name="lastname">
              <Input placeholder={t(`${tPrefix}lastname.title`)} allowClear />
            </Form.Item>
          </Space>
        </InputsRow>
        <InputsRow>
          <Space>
            <Form.Item name="file">
              <Input placeholder={t(`${tPrefix}file.title`)} allowClear />
            </Form.Item>
            <Form.Item name="delegationId">
              <Select
                getPopupContainer={(triggerNode: HTMLElement) =>
                  triggerNode.parentNode as HTMLElement
                }
                mode="multiple"
                maxTagCount="responsive"
                allowClear
                style={{ width: '100%' }}
                placeholder={t(`${tPrefix}delegation.title`)}
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {delegations?.map((delegation: ComboType) => (
                  <Option key={delegation.id} value={delegation.id}>
                    {delegation.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Space>
        </InputsRow>
      </Form>
    </FilterModal>
  )
}

const InspectorsFilters = () => {
  const [visible, setVisible] = useState(false)
  const { filters, setFilters } = useFilters()
  const { t } = useTranslation()
  const [clear, setClear] = useState(false)

  const onSearch = (values: InspectorsFormFilterValues) => {
    setFilters({
      filters: {
        name: values.name,
        lastname: values.lastname,
        file: values.file,
        delegationId: values.delegationId,
        limit: filters.filters?.limit,
      },
      orderBy: {
        field: filters.orderBy?.field,
        order: filters.orderBy?.order,
      },
    })
    setVisible(false)
  }

  const hasValue = (value: unknown) => {
    if (Array.isArray(value)) {
      return value.length > 0
    }
    return !!value
  }

  const hasFilterApplied = () => {
    return (
      filters.filters &&
      Object.entries(filters.filters).some(
        ([key, value]) => hasValue(value) && key !== 'limit' && key !== 'page',
      )
    )
  }

  const { delegations } = useGetDelegationsCombo()

  return (
    <div className="filter-btn-container">
      <FiltersForm
        visible={visible}
        onSearch={onSearch}
        onCancel={() => {
          setVisible(false)
        }}
        clear={clear}
        setClear={setClear}
      />
      <Row justify="end">
        {filters.filters &&
          Object.entries(filters.filters).map(
            ([key, value]) =>
              hasValue(value) &&
              key !== 'limit' &&
              key !== 'page' && (
                <FilterTag key={key}>
                  {t(`globals.filters.fields.${key}`)}
                  {key === 'name' && `: ${value}`}
                  {key === 'lastname' && `: ${value}`}
                  {key === 'file' && `: ${value}`}
                  {key === 'delegationId' &&
                    Array.isArray(value) &&
                    `: ${value.map(item => {
                      const selectedItemValue = delegations?.find(x => x.id === item)?.name
                      return ` ${selectedItemValue}`
                    })}`}
                </FilterTag>
              ),
          )}
      </Row>
      {hasFilterApplied() ? (
        <>
          <Tooltip title={t('globals.tooltips.clear')}>
            <ClearOutlined
              type="primary"
              onClick={() => {
                setFilters({
                  orderBy: {
                    field: filters.orderBy?.field,
                    order: filters.orderBy?.order,
                  },
                  filters: {
                    page: 1,
                    name: undefined,
                    lastname: undefined,
                    delegationId: undefined,
                    file: undefined,
                    limit: filters?.filters?.limit,
                  },
                })
                setClear(true)
              }}
            />
          </Tooltip>
          <Tooltip title={t('globals.tooltips.filter')}>
            <FilterFilled
              type="primary"
              onClick={() => {
                setVisible(true)
              }}
            />
          </Tooltip>
        </>
      ) : (
        <Tooltip title={t('globals.tooltips.filter')}>
          <FilterOutlined
            type="primary"
            onClick={() => {
              setVisible(true)
            }}
          />
        </Tooltip>
      )}
    </div>
  )
}

export default InspectorsFilters

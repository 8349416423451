const employerRoutes = (type: string) => ({
  BASE_URL: `/${type}/home`,
  HOME: '/surveys',
})

export const ROUTE_KEYS = {
  ADMIN: {
    BASE_URL: '/admin/home',
    HOME: '/orders',
    SURVEYS: '/surveys',
    RECORD: '/record',
  },
  INSPECTOR: {
    BASE_URL: '/inspector/home',
    HOME: '/surveys',
  },
  EMPLOYER: employerRoutes('employer'),
  EMPLOYER_NOT_REGISTERED: employerRoutes('employer_not_registered'),
  LOGIN: '/login',
  UNREGISTERED_LOGIN: '/login-unregistered-employer',
}

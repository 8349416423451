import { useState } from 'react'
import { Form, Input, Spin } from 'antd'
import { UserOutlined, LockOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line import/no-unresolved
import logo from '@images/logo.svg'

import { Logo, StyledWrapper, FormWrapper, StyledButton, StyledRow } from '../components'
import { useLogin } from '../hooks'

const Login = () => {
  const [errorLoginCount, setErrorLoginCount] = useState(0)

  const { login, loginIsLoading } = useLogin({
    onError: () => {
      const newCount = errorLoginCount + 1
      setErrorLoginCount(newCount)
    },
  })

  const { t } = useTranslation()

  return (
    <StyledWrapper>
      <StyledRow align="middle">
        <FormWrapper span={24}>
          <Spin spinning={loginIsLoading}>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={login}
            >
              <Logo>
                <img className="login-logo" src={logo} alt="logo" />
              </Logo>
              <Form.Item name="user" rules={[{ required: true, message: t('login.please_email') }]}>
                <Input prefix={<UserOutlined />} placeholder={t('login.placeholder_email')} />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: t('login.please_password') }]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder={t('login.placeholder_password')}
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
              <StyledButton type="primary" htmlType="submit" size="large">
                {t('login.login')}
              </StyledButton>
            </Form>
          </Spin>
        </FormWrapper>
      </StyledRow>
    </StyledWrapper>
  )
}

export { Login }

import { ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

const CollapseExpand = () => {
  const { t } = useTranslation()
  return (
    <div>
      <Tooltip title={t('globals.tooltips.expand')}>
        <ArrowsAltOutlined
          style={{ marginRight: '0.5rem' }}
          onClick={() => {
            const collapsableElements = document.querySelectorAll(
              '[aria-expanded="false"]',
            ) as unknown as HTMLElement[]
            collapsableElements.forEach((item: HTMLElement) => {
              setTimeout(() => {
                item.click()
              }, 1)
            })
          }}
        />
      </Tooltip>
      <Tooltip title={t('globals.tooltips.collapse')}>
        <ShrinkOutlined
          onClick={() => {
            const collapsableElements = document.querySelectorAll(
              '[aria-expanded="true"]',
            ) as unknown as HTMLElement[]
            collapsableElements.forEach((item: HTMLElement) => {
              setTimeout(() => {
                item.click()
              }, 1)
            })
          }}
        />
      </Tooltip>
    </div>
  )
}

export default CollapseExpand

import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'
import { FilterContextType } from '@types'

const useGetInspectors = (params?: FilterContextType) => {
  const { data, isLoading } = useQuery([QUERY_KEYS.INSPECTORS, params], () => {
    return DataApi.getInspectors(params)
  })
  return { inspectors: data, isLoading }
}

export { useGetInspectors }

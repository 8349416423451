import { Row, Col, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import { useFilters } from '@contexts'

import { ArrowAsc, ArrowDesc } from '../../features/common/components'

const ColumnTitleRender = ({
  tKey,
  key,
  sortable,
  tooltip,
}: {
  tKey: string
  key: string
  sortable?: boolean
  tooltip?: boolean
}) => {
  const { t } = useTranslation()
  const fieldsPrefix = 'globals.fields.'
  const SortableComponent = ({ keyAux }: { keyAux: string }) => {
    const { filters, setFilters } = useFilters()
    return (
      <Col className="column-sort-elements" span={2}>
        <Tooltip placement="top" title={t('globals.tooltips.asc')}>
          <ArrowAsc
            className={
              keyAux === filters.orderBy?.field && filters.orderBy?.order === 'asc'
                ? 'selected'
                : ''
            }
            onClick={() => {
              setFilters({
                filters: {
                  ...filters.filters,
                  page: 1,
                },
                orderBy: {
                  field: keyAux,
                  order: 'asc',
                },
              })
            }}
          />
        </Tooltip>
        <Tooltip placement="bottom" title={t('globals.tooltips.desc')}>
          <ArrowDesc
            className={
              keyAux === filters.orderBy?.field && filters.orderBy?.order === 'desc'
                ? 'selected'
                : ''
            }
            onClick={() => {
              setFilters({
                filters: {
                  ...filters.filters,
                  page: 1,
                },
                orderBy: {
                  field: keyAux,
                  order: 'desc',
                },
              })
            }}
          />
        </Tooltip>
      </Col>
    )
  }
  return (
    <Row className="column-title-row" align="middle" justify="space-between" wrap={false}>
      {sortable ? (
        <Col style={{ visibility: 'hidden' }} className="column-sort-elements" span={2}>
          <ArrowAsc />
        </Col>
      ) : (
        <div />
      )}
      {tooltip ? (
        <Tooltip title={t(`${fieldsPrefix}${tKey}.tooltip`)}>
          <Col style={{ cursor: 'help', userSelect: 'none' }}>
            {t(`${fieldsPrefix}${tKey}.title`)}
          </Col>
        </Tooltip>
      ) : (
        <Col style={{ cursor: 'unset', userSelect: 'none', flex: 1 }}>
          {t(`${fieldsPrefix}${tKey}.title`)}
        </Col>
      )}
      {sortable ? <SortableComponent keyAux={key} /> : <div />}
    </Row>
  )
}
ColumnTitleRender.defaultProps = {
  sortable: false,
  tooltip: false,
}

export { ColumnTitleRender }

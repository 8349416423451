import { SignOtherRequiredInfoEnum } from '@types'

interface RecordSurveyDateProps {
  multiple?: boolean
  other?: SignOtherRequiredInfoEnum
}

const SignContainer = ({ other, multiple }: RecordSurveyDateProps) => {
  return (
    <div className="sign-section__column__bottom">
      {multiple ? (
        <div className="sign">FIRMAS</div>
      ) : (
        <>
          <div className="sign">FIRMA</div>
          <div className="aclaration">ACLARACIÓN</div>
          <div className="other">{other}</div>
        </>
      )}
    </div>
  )
}
const defaultProps = {
  multiple: false,
  other: SignOtherRequiredInfoEnum.DNI,
}
SignContainer.defaultProps = defaultProps

export { SignContainer }

import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useGetCauses = () => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.CAUSES],
    () => {
      return DataApi.getCauses()
    },
    { staleTime: Infinity },
  )
  return { causes: data?.data, isLoading }
}

export { useGetCauses }

import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useGetOrder = (id?: number) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.ORDER, id],
    () => {
      return DataApi.getOrder(id)
    },
    {
      enabled: !!id,
    },
  )
  return { order: data?.data, isLoading }
}

export { useGetOrder }

import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useGetDepartments = (provinceId: number) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.DEPARTMENTS, provinceId],
    () => {
      return DataApi.getDepartments(provinceId)
    },
    { staleTime: Infinity },
  )
  return { departments: data?.data, isLoading }
}

export { useGetDepartments }

import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useGetLocality = (localityId: number) => {
  const { data, isLoading } = useQuery([QUERY_KEYS.LOCALITY, localityId], () => {
    return DataApi.getLocality(localityId)
  })
  return { locality: data?.data, isLoading }
}

export { useGetLocality }

/* eslint-disable no-param-reassign */
import { LOCAL_STORAGE_KEYS } from '@constants'

const onEnterOk = (onOk: () => void) => {
  window.onkeyup = (e: { key: string }) => {
    if (e.key === 'Enter') {
      onOk()
    }
  }
}

const boolTraduction = (bool: boolean) => {
  return bool ? 'SÍ' : 'NO'
}
const convertBase64 = (file: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

const binaryResponse = (value1: unknown, value2: unknown, ifTrue: unknown, ifFalse: unknown) => {
  const response = value1 === value2 ? ifTrue : ifFalse
  return response
}

const emptyInput = '-'

const renderWorkTime = (totalWorkTime: { hours: string | number; minutes: string | number }) => {
  if (totalWorkTime) {
    const { hours, minutes } = totalWorkTime
    const hoursAux = hours ? `${hours}hs ` : ''
    const minutesAux = minutes ? `${minutes}min` : ''
    return `${hoursAux}${minutesAux}`
  }
  return ''
}

export const handleLastLocation = (location: string) => {
  window.localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_LOCATION, location)
}

export const recordDateFormat = 'DD-MM-YYYY HH:mm:ss'

const inputToUppercase = (e: { currentTarget: { value: string } }) => {
  e.currentTarget.value = e.currentTarget.value.toUpperCase()
}

const inputCoordinatesSanitize = (e: { currentTarget: { value: string } }) => {
  if (e.currentTarget.value[0] !== '-') {
    e.currentTarget.value = `-${e.currentTarget.value}`
  }
  if (e.currentTarget.value.includes(',')) {
    e.currentTarget.value = e.currentTarget.value.replace(',', '.')
  }
}

const isEmptyReplaceWith = (string: string | null | undefined, object: string) => {
  if (
    (typeof string === 'string' && string.length === 0) ||
    string === undefined ||
    string === null
  ) {
    return object
  }

  return string
}

export const OtherUtils = {
  onEnterOk,
  boolTraduction,
  convertBase64,
  handleLastLocation,
  binaryResponse,
  emptyInput,
  renderWorkTime,
  inputToUppercase,
  inputCoordinatesSanitize,
  isEmptyReplaceWith,
}

import { BrowserRouter } from 'react-router-dom'
// eslint-disable-next-line import/no-unresolved
import logo from '@images/logo.svg'

import { AuxiliarsProvider } from '@contexts'

import { Header, Main, Logo, SiteWrapper } from '../../features/common/components'
import Logout from '../../features/common/components/Logout'

const RouterLayout: React.FC = ({ children }) => {
  return (
    <AuxiliarsProvider>
      <BrowserRouter>
        <SiteWrapper>
          <Header>
            <Logo>
              <img className="header-logo" src={logo} alt="logo" />
            </Logo>
            <Logout />
          </Header>
          <Main>{children}</Main>
        </SiteWrapper>
      </BrowserRouter>
    </AuxiliarsProvider>
  )
}

export { RouterLayout }

import { useState } from 'react'
import { Form, Input, Row, Spin } from 'antd'
import { UserOutlined, IdcardOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line import/no-unresolved
import logo from '@images/logo.svg'
import ReCAPTCHA from 'react-google-recaptcha'

import { config } from '@config'
import { LOCAL_STORAGE_KEYS } from '@constants'
import { UnregisteredPayload } from '@types'

import { Logo, StyledWrapper, FormWrapper, StyledButton, StyledRow } from '../components'
import { useUnregisteredEmployerLogin } from '../hooks/useUnregisteredEmployerLogin'

const UnregisteredLogin = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

  const [recaptchaToken, setRecaptchaToken] = useState<string>()

  const onChangeCaptcha = (value: string | null) => {
    setIsSubmitDisabled(false)

    if (value) {
      setRecaptchaToken(value)
    }
  }

  const onExpiredCaptcha = () => {
    setIsSubmitDisabled(true)
  }

  const token = window.localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN)!
  if (token) {
    window.location.href = window.location.origin
  }

  const [errorLoginCount, setErrorLoginCount] = useState(0)

  const { unregisteredEmployerLogin, unregisteredEmployerLoginisLoading } =
    useUnregisteredEmployerLogin({
      onError: () => {
        const newCount = errorLoginCount + 1
        setErrorLoginCount(newCount)
      },
    })

  const onSubmit = (data: UnregisteredPayload) => {
    if (recaptchaToken) {
      unregisteredEmployerLogin({ ...data, recaptchaToken })
    }
  }

  const { t } = useTranslation()

  return (
    <StyledWrapper>
      <StyledRow align="middle">
        <FormWrapper span={24}>
          <Spin spinning={unregisteredEmployerLoginisLoading}>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onSubmit}
            >
              <Logo>
                <img className="login-logo" src={logo} alt="logo" />
              </Logo>
              <Form.Item name="cuit" rules={[{ required: true, message: t('login.please_cuit') }]}>
                <Input prefix={<UserOutlined />} placeholder={t('login.placeholder_cuit')} />
              </Form.Item>
              <Form.Item
                name="provisionalId"
                rules={[{ required: true, message: t('login.please_provisory_number') }]}
              >
                <Input
                  prefix={<IdcardOutlined />}
                  placeholder={t('login.placeholder_provisory_number')}
                />
              </Form.Item>
              <Form.Item>
                <Row justify="center">
                  <ReCAPTCHA
                    sitekey={config.recaptchaKey!}
                    onChange={onChangeCaptcha}
                    onExpired={onExpiredCaptcha}
                  />
                </Row>
              </Form.Item>
              <Form.Item name="recaptchaToken" valuePropName="recaptchaToken" hidden>
                <Input type="hidden" value={recaptchaToken} />
              </Form.Item>
              <StyledButton
                type="primary"
                htmlType="submit"
                size="large"
                disabled={isSubmitDisabled}
              >
                {t('login.login')}
              </StyledButton>
            </Form>
          </Spin>
        </FormWrapper>
      </StyledRow>
    </StyledWrapper>
  )
}

export { UnregisteredLogin }

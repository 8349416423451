import { useMutation } from 'react-query'

import { DataApi } from '@api'

const useSearchItem = () => {
  const { mutate, isLoading } = useMutation(DataApi.searchItem)

  return { search: mutate, isLoading }
}

export { useSearchItem }

import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useGetStreetTypes = () => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.STREET_TYPES],
    () => {
      return DataApi.getStreetTypes()
    },
    { staleTime: Infinity },
  )
  return { streetTypes: data?.data, isLoading }
}

export { useGetStreetTypes }

import { useMutation, useQueryClient } from 'react-query'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useDeleteAttachment = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation(DataApi.deleteAttachment, {
    onSuccess: res => {
      queryClient.refetchQueries(QUERY_KEYS.SURVEY_ATTACHED_FILES)
      notification.close('deletingFile')
      notification.success({
        message: `'${res.file}' ${t('globals.delete_attachment_success')}`,
      })
    },
    onError: () => {
      notification.close('deletingFile')
      notification.error({
        message: t('globals.delete_attachment_error'),
      })
    },
  })

  return { deleteAttachment: mutate, isDeleting: isLoading }
}

export { useDeleteAttachment }

import { SnippetsOutlined, TeamOutlined, SolutionOutlined } from '@ant-design/icons'
import { BrowserRouter as Switch } from 'react-router-dom'

import { PrivateRoute } from '@components'
import { ROUTE_KEYS } from '@constants'
import { useUser } from '@contexts'
import { LevelEnum, ComponentList } from '@types'

import { MENU_OPTION_KEYS } from '../../constants/menuOptionKeys'
import Inspectors from '../common/Inspectors'
import Orders from '../common/Orders'
import Surveys from '../common/Surveys'
import { DinamicRouter } from '../DinamicRouter'
import RegisterModal from '../common/components/RegisterModal'

const UsersRouter = () => {
  const { state } = useUser()
  const { ORDERS, INSPECTORS, SURVEYS } = MENU_OPTION_KEYS
  const { ADMIN, INSPECTOR, EMPLOYER, EMPLOYER_NOT_REGISTERED } = LevelEnum

  const options: ComponentList = [
    // This array contains the sections to display on Router
    {
      name: ORDERS,
      component: Orders,
      icon: () => <SnippetsOutlined />,
      allowedRoles: [ADMIN],
    },
    {
      name: INSPECTORS,
      component: Inspectors,
      icon: () => <TeamOutlined />,
      allowedRoles: [ADMIN],
    },
    {
      name: SURVEYS,
      component: Surveys,
      icon: () => <SolutionOutlined />,
      allowedRoles: [ADMIN, INSPECTOR, EMPLOYER, EMPLOYER_NOT_REGISTERED],
    },
  ]

  const employerLevels = [EMPLOYER, EMPLOYER_NOT_REGISTERED]

  return (
    <Switch>
      {state && (
        <PrivateRoute
          path={ROUTE_KEYS[state.level].BASE_URL}
          component={() => (
            <>
              <DinamicRouter
                options={options.filter(option => option.allowedRoles.includes(state.level))}
              />
              {employerLevels.includes(state.level) && <RegisterModal />}
            </>
          )}
          allowedRoles={[state.level]}
        />
      )}
    </Switch>
  )
}

export { UsersRouter }

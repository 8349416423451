import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useGetProvinces = () => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.PROVINCES],
    () => {
      return DataApi.getProvinces()
    },
    { staleTime: Infinity },
  )
  return { provinces: data?.data, isLoading }
}

export { useGetProvinces }

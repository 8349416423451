import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { ConfirmationMsg, ConfirmModal } from '..'

notification.config({
  placement: 'topRight',
  duration: 10,
})

interface ConfirmActionModalType {
  title: string
  isConfirmActionVisible: boolean
  onClose: () => void
  action: () => void
}

const ConfirmActionModal = ({
  title,
  isConfirmActionVisible,
  onClose,
  action,
}: ConfirmActionModalType) => {
  const { t } = useTranslation()

  return (
    <ConfirmModal
      closable={false}
      centered
      title={<h1>{title}</h1>}
      visible={isConfirmActionVisible}
      okText={`${t('globals.yes')}, ${t('globals.continue').toLowerCase()}`}
      cancelText={`${t('globals.no')}, ${t('globals.cancel').toLowerCase()}`}
      onCancel={onClose}
      onOk={action}
    >
      <ConfirmationMsg>{t('globals.confirm_modal.title_preffix')}</ConfirmationMsg>
    </ConfirmModal>
  )
}

export default ConfirmActionModal

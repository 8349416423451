import { useMutation } from 'react-query'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { AuthApi, setAuthHeader } from '@api'
import { LOCAL_STORAGE_KEYS, ROUTE_KEYS } from '@constants'
import { useUser } from '@contexts'

interface UseLoginProps {
  onError?: () => void
}

const useLogin = (props?: UseLoginProps) => {
  const history = useHistory()

  const { LOGIN } = ROUTE_KEYS
  const { setState: setUser } = useUser()

  const { t } = useTranslation()
  const { mutate, isLoading } = useMutation(AuthApi.login, {
    onSuccess: ({ data }) => {
      setAuthHeader(data.token)
      setUser(data)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESHED, 'false')
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, data.token)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, data.refreshToken)
      if (history.location.pathname === LOGIN || history.location.pathname === '/') {
        history.push(`${ROUTE_KEYS[data.level].BASE_URL}`)
      }
    },
    onError: () => {
      if (props?.onError) {
        props.onError()
      }
      notification.error({
        message: t('globals.incorrect_user_or_password'),
      })
    },
  })

  return { login: mutate, loginIsLoading: isLoading }
}

export { useLogin }

import { Empty, notification, Spin, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAuxiliars } from '@contexts'
import { useGetSurveyInspectors } from '@hooks'

import { StandardModal } from '../../../components'

notification.config({
  placement: 'topRight',
  duration: 10,
})

interface SeeInspectorsPropsType {
  visible: boolean
  close: () => void
}

const SeeInspectorsModal = ({ visible, close }: SeeInspectorsPropsType) => {
  const { t } = useTranslation()
  const { activeSurveyId } = useAuxiliars()
  const tPrefix = 'globals.sections.surveys.'
  const { surveyInspectors, surveyInspectorsIsLoading } = useGetSurveyInspectors(activeSurveyId)
  const { Text } = Typography
  return (
    <StandardModal
      className="inspectors-modal"
      visible={visible}
      title={
        <h1>{`${t(`${tPrefix}inspectors_modal`)} - ${t(
          `${tPrefix}modal.title`,
        )}${activeSurveyId}`}</h1>
      }
      onCancel={() => {
        close()
      }}
      footer={false}
      centered
      width={850}
    >
      <Spin spinning={surveyInspectorsIsLoading}>
        {surveyInspectors ? (
          <>
            <div>
              <Text strong>{t('globals.fields.responsible_inspector.title')}: </Text>
              <Text>
                {surveyInspectors?.responsibleFullname} - {surveyInspectors?.responsibleFile}
              </Text>
            </div>
            {surveyInspectors?.inspectors.length > 0 ? (
              <>
                <Text strong>{t('globals.fields.auxiliar_inspectors.title')}</Text>
                <ul>
                  {surveyInspectors?.inspectors.map(({ file, fullname }) => (
                    <li key={file}>
                      <Text>
                        {fullname} - {file}
                      </Text>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              ''
            )}
          </>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
    </StandardModal>
  )
}

export default SeeInspectorsModal

type QueryKeysType = {
  [key: string]: string
}
export const QUERY_KEYS: QueryKeysType = {
  INSPECTORS: 'inspectorsKey',
  INSPECTORS_COMBO: 'inspectorsComboKey',
  ORDERS: 'ordersKey',
  ORDER: 'orderKey',
  WORKERS: 'workersKey',
  WORKER: 'workerKey',
  SURVEYS: 'surveysKey',
  SURVEY: 'surveyKey',
  PRIORITIES: 'prioritiesKey',
  ACTIVITIES: 'activitiesKey',
  PROVINCES: 'provincesKey',
  LOCALITIES: 'localitiesKey',
  LOCALITY: 'localityKey',
  MODIFY_ORDER: 'modifyOrderKey',
  DEPARTMENTS: 'departmentsKey',
  ORIGINS: 'originsKey',
  DELEGATIONS_COMBO: 'delegationsComboKey',
  CAUSES: 'causesKey',
  STREET_TYPES: 'streetTypesKey',
  HISTORY_ORDER: 'historyOrderKey',
  CLOSE_CAUSES: 'closeCausesKey',
  SURVEY_NOTES: 'surveyNotesKey',
  MEDIA: 'mediaKey',
  UPLOAD_BULK_DATA_HISTORY: 'uploadBulkDataHistoryKey',
  SURVEY_ATTACHED_FILES: 'surveyAttachedFilesKey',
  EMPLOYER: 'employerKey',
  SURVEY_INSPECTORS: 'surveyInspectorsKey',
  EXPEDIENTS: 'expedientsKey',
  EXPEDIENT: 'expedientKey',
  EMPLOYER_SURVEYS: 'employerSurveysKey',
  PDF_RECORD: 'pdfRecord',
  ELECTRONIC_ADDRESS: 'electronicAddress',
}

/* eslint-disable react-hooks/exhaustive-deps */
import { Empty, Space, Spin } from 'antd'
import { useEffect, useState } from 'react'

import { RecordTypeEnum, SurveyType, UrlSignParams } from '@types'

import DigitalSign from '../../../components/DigitalSign'
import { SurveyModal } from '../../../components'
import { useGetPdfRecord } from './hooks/useGetPdfRecord'

interface PdfRecordModalProps {
  surveyId: number
  modalTitle: string
  visible: boolean
  close: () => void
  recordTypeAux: RecordTypeEnum | undefined
  signParams: UrlSignParams | undefined
  surveys?: SurveyType[]
}

const SeePdfRecordModal: React.FC<PdfRecordModalProps> = ({
  surveyId,
  surveys,
  modalTitle,
  visible,
  close,
  recordTypeAux,
  signParams,
}) => {
  const [isResponsible, setIsResponsible] = useState<boolean>()
  const { pdf, pdfIsLoading } = useGetPdfRecord(surveyId, recordTypeAux!)

  const titleRender = () => {
    return (
      <Space
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          paddingRight: '4rem',
        }}
      >
        <h1>{`${modalTitle} ${surveyId}`}</h1>
      </Space>
    )
  }

  useEffect(() => {
    setIsResponsible(!!surveys?.find(({ id }) => surveyId === id)?.isResponsible)
  }, [])

  return (
    <SurveyModal
      visible={visible}
      title={titleRender()}
      onCancel={close}
      footer={false}
      centered
      className={pdf ? 'pdf-modal' : ''}
      width={850}
    >
      <Spin spinning={pdfIsLoading}>
        {pdf ? (
          <>
            {(isResponsible || signParams) && (
              <DigitalSign signParams={signParams} itemId={surveyId} itemType={recordTypeAux!} />
            )}
            <iframe
              title={modalTitle}
              src={pdf}
              // si se desea quitar la barra de herramientas para evitar descargar,
              // agregar #toolbar=0 al final del string src. En Mozilla Firefox no funciona
              width="100%"
              height="100%"
              style={{ border: 'none' }}
            />
          </>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
    </SurveyModal>
  )
}

export default SeePdfRecordModal

import styled from 'styled-components'
import { Col, Button, Row } from 'antd'

const StyledWrapper = styled(Col)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.secundaryColor};
`

const IconWrapper = styled(Col)`
  display: flex;
  justify-content: center;
  margin-bottom: 20%;
`
const FormWrapper = styled(Col)`
  & Form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: #fff;
    border-radius: 0.5rem;
  }
`
const StyledButton = styled(Button)`
  width: 100%;
  border: none;
`
const Logo = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  .login-logo {
    margin: 0 2rem 2rem 2rem;
    width: 100%;
    min-width: 6rem;
  }
  @media ${props => props.theme.xs} {
    .login-logo {
      margin: 0 0.5rem 2rem 0.5rem;
      width: 100%;
      min-width: 6rem;
    }
  }
`

const StyledRow = styled(Row)`
  @media ${props => props.theme.xs} {
    width: 80%;
  }

  @media ${props => props.theme.sm} {
    width: 60%;
  }

  @media ${props => props.theme.md} {
    width: 45%;
  }

  @media ${props => props.theme.lg} {
    width: 35%;
  }

  @media ${props => props.theme.xl} {
    width: 25%;
  }
`

export { StyledWrapper, IconWrapper, FormWrapper, StyledButton, StyledRow, Logo }

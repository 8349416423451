import { Menu, Dropdown, Row } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { useUser } from '@contexts'
import { useLogout } from '@hooks'

const Logout = () => {
  const { t } = useTranslation()
  const { state: user } = useUser()
  const { logout } = useLogout()

  const menu = (
    <Menu>
      <Menu.Item>
        <Row onClick={logout}>{t('login.log_out')}</Row>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu}>
      <span className="ant-dropdown-link">
        {user?.name ? `${user?.name.charAt(0).toUpperCase()}${user?.name.slice(1)}` : ''}{' '}
        <DownOutlined />
      </span>
    </Dropdown>
  )
}

export default Logout

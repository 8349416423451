import { useMutation, useQueryClient } from 'react-query'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useUploadBulkData = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation(DataApi.uploadBulkData, {
    onSuccess: res => {
      queryClient.invalidateQueries(QUERY_KEYS.UPLOAD_BULK_DATA_HISTORY)
      notification.close('submited')
      notification.success({
        message: `'${res.file}' ${t('globals.upload_success')}`,
      })
    },
    onError: () => {
      notification.close('submited')
      notification.error({
        message: t('globals.upload_error'),
      })
    },
  })

  return { upload: mutate, isUploading: isLoading }
}

export { useUploadBulkData }

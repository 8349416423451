import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useGetSurvey = (surveyId: number) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.SURVEY, surveyId],
    () => {
      return DataApi.getSurvey(surveyId)
    },
    {
      enabled: !!surveyId && surveyId > 0,
      staleTime: Infinity,
    },
  )
  return { survey: data?.data, surveyIsLoading: isLoading }
}

export { useGetSurvey }

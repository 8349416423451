import { useMutation } from 'react-query'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { DataApi } from '@api'

const useEstablishElectronicAddress = (close: () => void) => {
  const { t } = useTranslation()

  const { mutate: establishElectronicAddress, isLoading: isLoadingEstablishElectronicAddress } =
    useMutation(DataApi.postElectronicAddress, {
      onSuccess: () => {
        notification.success({ message: t('globals.register_modal.success') })
        close()
      },
      onError: () => {
        notification.error({ message: t('globals.register_modal.error') })
      },
    })

  return {
    establishElectronicAddress,
    isLoadingEstablishElectronicAddress,
  }
}

export { useEstablishElectronicAddress }

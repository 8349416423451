/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import {
  Form,
  Input,
  Space,
  Select,
  notification,
  DatePicker,
  Switch,
  Typography,
  Button,
  Row,
  Spin,
} from 'antd'
import { useTranslation } from 'react-i18next'
import moment, { Moment } from 'moment'
import {
  EditFilled,
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
import ReactToPrint, { useReactToPrint } from 'react-to-print'
// eslint-disable-next-line import/no-unresolved
import logo from '@images/logo.svg'
import { useQueryClient } from 'react-query'

import { OtherUtils } from '@utils'
import {
  useGetActivities,
  useGetInspectorsCombo,
  useGetLocalities,
  useGetPriorities,
  useGetProvinces,
  useGetDepartments,
  useGetOrigins,
  useGetDelegationsCombo,
  useGetCauses,
  useGetStreetTypes,
  useSearchItem,
  useGetLocality,
} from '@hooks'
import { ComboType, Employer, Establishment, Georeference } from '@types'
import { QUERY_KEYS, latitudRegex, longitudeRegex } from '@constants'

import { LocalityType, OrdersType, RegisteredOrderType } from '../../../../../api/data'
import {
  SectionTitle,
  GroupTitle,
  InputNum,
  InputsRow,
  OrderModal,
  StyledForm,
} from '../../../components'
import { useGetEmployer } from '../../hooks/useGetEmployer'

import './styles.css'

const tPrefix = 'globals.fields.'
const OTHER_ESTABLISHMENT = 'OTHER_ESTABLISHMENT'
const coordinatesInputLenghtLimit = 16

interface OrderCreateFormProps {
  visible: boolean
  onSave: (
    values: OrdersType & RegisteredOrderType,
    isEmployerRegistered: boolean | undefined,
    reset: () => void,
    setPosting: (state: boolean) => void,
    isResponsibleSelected: boolean,
  ) => void
  onCancel: () => void
  modalTitle: string
  order: RegisteredOrderType | undefined
  editable: boolean
  isLoading: boolean
}

const OrderForm: React.FC<OrderCreateFormProps> = ({
  visible,
  onSave,
  onCancel,
  modalTitle,
  order,
  editable,
  isLoading = false,
}) => {
  const { Text } = Typography
  const [form] = StyledForm.useForm()
  const { TextArea } = Input
  const { Option } = Select
  const { t } = useTranslation()
  const { search } = useSearchItem()
  const [isEmployerRegistered, setIsEmployerRegistered] = useState<boolean | undefined>(
    order ? order.isEmployerRegistered : undefined,
  )
  const requiredMsg = t(`${tPrefix}required`)
  const [posting, setPosting] = useState(false)
  const [otherCauseDisabled, setOtherCauseDisabled] = useState(true)
  const [otherOriginDisabled, setOtherOriginDisabled] = useState(true)
  const [toEdit, setToEdit] = useState(!order)
  const [inspectionDateValue, setInspectionDateValue] = useState(
    order?.inspectionDate ? moment(order.inspectionDate, 'DD/MM/YYYY') : '',
  )
  const componentRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  // eslint-disable-next-line consistent-return
  const handleSetInspectionDateValue = () => {
    if (order?.inspectionDate) {
      if (
        toEdit === false &&
        moment(order.inspectionDate, 'DD/MM/YYYY') < moment().startOf('day')
      ) {
        notification.warning({
          message: t(`${tPrefix}date_warning`),
        })
        form.setFieldsValue({ inspectionDate: '' })
      }
      setInspectionDateValue(moment(order.inspectionDate, 'DD/MM/YYYY'))
    }
  }

  const disabledDate = (current: Moment) => {
    return current && current < moment().startOf('day')
  }

  const [isResponsibleSelected, setIsResponsibleSelected] = useState(
    !!form.getFieldValue('responsibleInspectorId'),
  )

  const [ifCoordinates, setIfCoordinates] = useState(
    form.getFieldValue('latitude') && !!form.getFieldValue('longitude'),
  )

  const [ifPostalDirection, setIfPostalDirection] = useState(
    form.getFieldValue('streetName') &&
      form.getFieldValue('streetType') &&
      form.getFieldValue('streetNumber') &&
      form.getFieldValue('provinceId') &&
      form.getFieldValue('departmentId') &&
      form.getFieldValue('localityId'),
  )

  const [isCoordinatesRequired, setIsCoordinatesRequired] = useState(
    !!isResponsibleSelected && !ifPostalDirection,
  )
  const handleIsCoordinatesRequired = () => {
    setIfPostalDirection(
      form.getFieldValue('streetName') &&
        form.getFieldValue('streetType') &&
        form.getFieldValue('streetNumber') &&
        form.getFieldValue('provinceId') &&
        form.getFieldValue('departmentId') &&
        form.getFieldValue('localityId'),
    )
    setIsCoordinatesRequired(!!isResponsibleSelected && !ifPostalDirection)
  }

  const [isPostalDirectionRequired, setIsPostalDirectionRequired] = useState(
    !!isResponsibleSelected && !ifCoordinates,
  )
  const handleIsPostalDirectionRequired = () => {
    setIfCoordinates(!!form.getFieldValue('latitude') && !!form.getFieldValue('longitude'))
    setIsPostalDirectionRequired(!!isResponsibleSelected && !ifCoordinates)
  }

  const { priorities } = useGetPriorities()
  const { provinces } = useGetProvinces()
  const [provinceId, setProvinceId] = useState(order?.provinceId || 0)
  const [departmentId, setDepartmentId] = useState(order?.departmentId || 0)
  const [localityId, setLocalityId] = useState(order?.localityId || 0)
  const { departments } = useGetDepartments(provinceId)
  const [cuit, setCuit] = useState<string | undefined>(undefined)
  const { employer } = useGetEmployer(cuit)
  const { localitiesRequest } = useGetLocalities(departmentId)
  const { locality } = useGetLocality(localityId)
  const { delegations } = useGetDelegationsCombo()
  const { activities } = useGetActivities()
  const { inspectors } = useGetInspectorsCombo()
  const { streetTypes } = useGetStreetTypes()
  const { origins } = useGetOrigins()
  const { causes } = useGetCauses()
  const queryClient = useQueryClient()

  const [localities, setLocalities] = useState<LocalityType[] | ComboType[]>([])
  const [establishmentSavedData, setEstablishmentSavedData] = useState<Establishment | undefined>(
    undefined,
  )

  const [cuitPrevious] = useState<string | undefined>(order ? order.employerCuit : undefined)

  const saveNonRegisteredPreviusData = () => {
    setEstablishmentSavedData(
      form.getFieldsValue([
        'establishmentId',
        'establishmentName',
        'streetType',
        'streetName',
        'streetNumber',
        'provinceId',
        'departmentId',
        'localityId',
        'place',
        'delegationId',
        'activityId',
        'latitude',
        'longitude',
      ]),
    )
  }
  const [selectedEstablishment, setSelectedEstablishment] = useState<number | string | undefined>(
    undefined,
  )
  const handleSelectEstablishment = (value: string | number) => {
    if (selectedEstablishment === OTHER_ESTABLISHMENT && value !== OTHER_ESTABLISHMENT) {
      saveNonRegisteredPreviusData()
    }
    setSelectedEstablishment(value)
    if (value === OTHER_ESTABLISHMENT) {
      handleEstablishmentInfo()
    } else if (typeof value === 'number' && employer && employer.establishments) {
      const establishment = employer.establishments.find(
        ({ establishmentId }) => establishmentId === value,
      )
      if (establishment) {
        setEstablishmentInfo(establishment)
      } else {
        notification.info({
          message: 'Se produjo un error al intentar encontrar el establecimiento seleccionado',
        })
      }
    }
  }

  const resetEmployerInfo = () => {
    setIsEmployerRegistered(false)
    form.resetFields(['employeesQuantity', 'isEmployerRegistered'])
  }
  const resetEstablishmentInfo = () => {
    form.resetFields([
      'establishmentName',
      'streetType',
      'streetName',
      'streetNumber',
      'provinceId',
      'departmentId',
      'localityId',
      'place',
      'delegationId',
      'activityId',
      'latitude',
      'longitude',
    ])
  }
  const handleEstablishmentInfo = () => {
    if (establishmentSavedData !== undefined) {
      resetEstablishmentInfo()
      setEstablishmentInfo(establishmentSavedData)
    } else {
      resetEstablishmentInfo()
    }
  }

  const handleSetEmployerInfo = (existingEmployer: Employer) => {
    setIsEmployerRegistered(true)
    form.setFieldsValue({
      employeesQuantity: existingEmployer.employeesQuantity,
      bussinessName: existingEmployer.bussinessName,
      isEmployerRegistered: true,
    })
  }

  const setEstablishmentInfo = (establishment: Establishment) => {
    resetEstablishmentInfo()
    if (order && !toEdit) {
      const {
        establishmentName,
        streetType,
        streetName,
        streetNumber,
        place,
        delegationId,
        activityId,
        latitude,
        longitude,
      } = order
      form.setFieldsValue({
        establishmentName,
        streetType,
        streetName,
        streetNumber,
        place,
        delegationId,
        activityId,
        latitude,
        longitude,
      })
      if (order.localityId) {
        form.setFieldsValue({
          localityId: order.localityId,
        })
        setLocalityId(order.localityId)
      } else if (order.departmentId) {
        form.setFieldsValue({
          departmentId: order.departmentId,
        })
        setDepartmentId(order.departmentId)
      } else if (order.provinceId) {
        form.setFieldsValue({
          provinceId: order.provinceId,
        })
        setProvinceId(order.provinceId)
      }
    } else {
      const {
        establishmentName,
        streetType,
        streetName,
        streetNumber,
        place,
        delegationId,
        activityId,
        latitude,
        longitude,
      } = establishment
      form.setFieldsValue({
        establishmentName,
        streetType,
        streetName,
        streetNumber,
        place,
        delegationId,
        activityId,
        latitude: latitude?.toString(),
        longitude: longitude?.toString(),
      })
      if (establishment.localityId) {
        form.setFieldsValue({
          localityId: establishment.localityId,
        })
        setLocalityId(establishment.localityId)
      } else if (establishment.departmentId) {
        form.setFieldsValue({
          departmentId: establishment.departmentId,
        })
        setDepartmentId(establishment.departmentId)
      } else if (establishment.provinceId) {
        form.setFieldsValue({
          provinceId: establishment.provinceId,
        })
        setProvinceId(establishment.provinceId)
      }
    }
  }

  const handleOtherCause = () => {
    const requiresDetailCauses =
      causes &&
      causes
        // eslint-disable-next-line array-callback-return, consistent-return
        .map(cause => cause.requires_detail && cause.id)
        .filter(value => value)
    const selectedCauses = form.getFieldValue('causes') as number[]
    const isDetailRequired = selectedCauses?.find(causeId =>
      requiresDetailCauses?.includes(causeId),
    )
    if (selectedCauses && isDetailRequired) {
      setOtherCauseDisabled(false)
      if (order?.causesDetail && form.getFieldValue('otherCause') === null) {
        form.resetFields(['otherCause'])
      }
    } else {
      setOtherCauseDisabled(true)
      form.setFieldsValue({ otherCause: null })
    }
  }

  const handleOtherOrigin = () => {
    const selectedOriginId = form.getFieldValue('origin') as number
    const isDetailRequired = origins?.find(
      origin => origin.id === selectedOriginId && origin.requires_detail,
    )
    if (isDetailRequired) {
      setOtherOriginDisabled(false)
      if (order?.otherOrigin) {
        form.resetFields(['otherOrigin'])
      }
    } else {
      setOtherOriginDisabled(true)
      form.setFieldsValue({ otherOrigin: null })
    }
  }

  const reset = () => {
    setIsCoordinatesRequired(false)
    setIsPostalDirectionRequired(false)
    setIsResponsibleSelected(false)
    setProvinceId(0)
    setDepartmentId(0)
    setLocalityId(0)
    setEstablishmentSavedData(undefined)
    setSelectedEstablishment(undefined)
    setCuit(undefined)
    setPosting(false)
    form.resetFields()
  }

  const handleLocationValidation = () => {
    handleIsCoordinatesRequired()
    handleIsPostalDirectionRequired()
  }

  const handleSwitch = () => {
    setIsEmployerRegistered(!isEmployerRegistered)
  }
  const handleEstablishmentOtherSelected = () => {
    if (selectedEstablishment === OTHER_ESTABLISHMENT) {
      form.setFieldsValue({ establishmentId: null })
    }
  }

  const proceed = () => {
    setPosting(true)
    handleLocationValidation()
    handleEstablishmentOtherSelected()
    form
      .validateFields()
      .then(values => {
        onSave(
          {
            ...values,
            georeferences:
              values.georeferences?.map(({ latitude, longitude, description }: Georeference) => ({
                description,
                latitude: parseFloat(latitude as string),
                longitude: parseFloat(longitude as string),
              })) || [],
            inspectionDate: values?.inspectionDate && values?.inspectionDate.format('DD-MM-YYYY'),
          },
          isEmployerRegistered,
          reset,
          setPosting,
          isResponsibleSelected,
        )
      })
      .catch(e => {
        console.log(e)
        let localizationError = ''
        if (isResponsibleSelected && !ifCoordinates && !ifPostalDirection) {
          localizationError = t('globals.address_or_geolocalization')
        } else {
          localizationError = ''
        }
        notification.error({
          message: `
          ${t('globals.validation_error')}
          ${!form.getFieldValue('priority') ? `${t(`${tPrefix}priority.title`)}. ` : ''}
          ${
            !form.getFieldValue('inspectionDate') ? `${t(`${tPrefix}inspection_date.title`)}. ` : ''
          }
          ${localizationError}`,
        })
      })
      .finally(() => setPosting(false))
  }

  const handleToEdit = () => {
    setToEdit(!toEdit)
    handleSetInspectionDateValue()
  }

  const expired = () => {
    if (
      form.getFieldValue('responsibleInspectorId') &&
      moment(order?.inspectionDate, 'DD/MM/YYYY HH:mm') < moment().startOf('day').add(7, 'hours')
    ) {
      return true
    }
    return false
  }

  const isExpired = expired()

  const titleRender = () => {
    return (
      <Space
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          paddingRight: '4rem',
        }}
      >
        <h1>{`${modalTitle}${order ? order.id : ''} `}</h1>
        {order && (
          <ReactToPrint
            copyStyles
            documentTitle={`${t('globals.sections.orders.document_title')}_${order.id}${
              order.employerCuit ? `_${order.employerCuit}` : ''
            }`}
            trigger={() => (
              <Space style={{ cursor: 'pointer' }}>
                <Text style={{ fontSize: '1rem' }}>Imprimir</Text>
                <PrinterOutlined style={{ fontSize: '1.5rem' }} onClick={handlePrint} />
              </Space>
            )}
            content={() => componentRef.current}
          />
        )}
        {order && editable && !isExpired && !toEdit ? (
          <>
            <Space>
              <Text style={{ fontSize: '1rem' }}>Editar</Text>
              <EditOutlined style={{ fontSize: '1.5rem' }} onClick={handleToEdit} />
            </Space>
          </>
        ) : (
          order &&
          editable &&
          !isExpired &&
          toEdit && (
            <Space>
              <Text style={{ fontSize: '1rem' }}>Editando</Text>
              <EditFilled style={{ fontSize: '1.5rem' }} />
            </Space>
          )
        )}
      </Space>
    )
  }

  const handleEmployersCuitSearch = async (value: string | undefined) => {
    queryClient.cancelQueries(QUERY_KEYS.EMPLOYER)
    if (value || form.getFieldValue('establishmentId') === OTHER_ESTABLISHMENT) {
      saveNonRegisteredPreviusData()
    }
    setCuit(value)
  }

  const [georeferenceCount, setGeoreferenceCount] = useState(0)

  const notificationGeoreferenceAddError = () =>
    notification.warning({
      message: t(`${tPrefix}disabled_georeference`),
    })

  useEffect(() => {
    if (toEdit) {
      if (cuit !== cuitPrevious) {
        if (employer) {
          resetEmployerInfo()
          if (employer.establishments) {
            if (employer.establishments.length === 1) {
              resetEstablishmentInfo()
              setSelectedEstablishment(0)
              form.setFieldsValue({ establishmentId: employer.establishments[0].establishmentId })
            } else if (establishmentSavedData !== undefined) {
              // eslint-disable-next-line no-lonely-if
              const arrayAux = Object.values(establishmentSavedData)
              const isAllUndefined =
                arrayAux.length === 0 ? true : arrayAux.every(item => item === undefined)
              if (!isAllUndefined) {
                setSelectedEstablishment(OTHER_ESTABLISHMENT)
                form.setFieldsValue({ establishmentId: OTHER_ESTABLISHMENT })
              } else {
                setSelectedEstablishment(undefined)
                form.setFieldsValue({ establishmentId: undefined })
              }
            }
          } else {
            setSelectedEstablishment(OTHER_ESTABLISHMENT)
            form.setFieldsValue({ establishmentId: OTHER_ESTABLISHMENT })
          }
          handleSetEmployerInfo(employer)
          const selectedEmployerEstablishment = form.getFieldValue('establishmentId')
          if (typeof selectedEmployerEstablishment === 'number' && employer.establishments) {
            const establishment = employer.establishments.find(
              ({ establishmentId }) => establishmentId === selectedEmployerEstablishment,
            )
            if (establishment) {
              setEstablishmentInfo(establishment)
            } else {
              notification.info({
                message:
                  'Se produjo un error al intentar encontrar el establecimiento seleccionado',
              })
            }
          }
        } else {
          setSelectedEstablishment(undefined)
          resetEmployerInfo()
          saveNonRegisteredPreviusData()
          handleEstablishmentInfo()
        }
      } else {
        setSelectedEstablishment(undefined)
        resetEmployerInfo()
        handleEstablishmentInfo()
      }
    }
  }, [cuit, employer])

  useEffect(() => {
    if (localitiesRequest) {
      setLocalities(localitiesRequest)
    }
  }, [localitiesRequest])

  useEffect(() => {
    if (locality) {
      setProvinceId(locality?.provinceId)
      setDepartmentId(locality?.departmentId)
      setTimeout(() => {
        form.setFieldsValue({
          provinceId: locality?.provinceId,
          departmentId: locality?.departmentId,
        })
      }, 500)
    }
  }, [locality, localityId, cuit, employer])

  useEffect(() => {
    if (order?.employerCuit) {
      setCuit(order.employerCuit)
    }
    if (localitiesRequest) {
      setLocalities(localitiesRequest)
    }
    setIsResponsibleSelected(!!form.getFieldValue('responsibleInspectorId'))
    handleLocationValidation()
  }, [])

  useEffect(() => {
    handleOtherOrigin()
    handleOtherCause()
  }, [visible])

  useEffect(() => {
    if (order?.establishmentName && editable && !isExpired) {
      if (employer?.establishments?.length) {
        const prevEstablishment = employer.establishments.find(
          ({ establishmentName }) => establishmentName === order.establishmentName,
        )
        if (prevEstablishment) {
          form.setFieldsValue({ establishmentId: prevEstablishment.establishmentId })
          handleSelectEstablishment(prevEstablishment.establishmentId)
        } else {
          setSelectedEstablishment(OTHER_ESTABLISHMENT)
          handleSelectEstablishment(OTHER_ESTABLISHMENT)
        }
      }
    }
  }, [employer])

  return (
    <OrderModal
      visible={visible}
      title={titleRender()}
      footer={!toEdit ? false : undefined}
      onCancel={() => {
        onCancel()
        reset()
      }}
      cancelText={t('globals.cancel')}
      okText={t('globals.form_save')}
      onOk={proceed}
      okButtonProps={{
        disabled: posting || isLoading,
      }}
      maskClosable={!toEdit}
      centered
      width={850}
    >
      <Spin spinning={posting}>
        <div ref={componentRef}>
          <StyledForm
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
              ...order,
              inspectionDate: inspectionDateValue,
            }}
          >
            <SectionTitle className="section-title-header">
              <img src={logo} alt="logo" />
              <div>
                {t(`${tPrefix}order_information`)}
                {order ? ` #${order.id}` : ''}
              </div>
            </SectionTitle>

            <GroupTitle>{t(`${tPrefix}general_information`)}</GroupTitle>
            <InputsRow>
              <Space>
                <Form.Item
                  label={t(`${tPrefix}priority.title`)}
                  rules={[
                    {
                      required: isResponsibleSelected,
                      message: requiredMsg,
                    },
                  ]}
                  name="priority"
                >
                  <Select
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentNode as HTMLElement
                    }
                    optionFilterProp="children"
                    showSearch
                    disabled={!toEdit}
                    placeholder={t(`${tPrefix}generic_placeholders.select`)}
                    allowClear
                  >
                    {priorities?.map(priority => (
                      <Option key={priority.id} value={priority.id}>
                        {priority.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t(`${tPrefix}inspection_date.title`)}
                  rules={[
                    {
                      required: isResponsibleSelected,
                      message: requiredMsg,
                    },
                  ]}
                  name="inspectionDate"
                >
                  <DatePicker
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentNode as HTMLElement
                    }
                    disabled={!toEdit}
                    disabledDate={disabledDate}
                    format="DD-MM-YYYY"
                    placeholder={t(`${tPrefix}generic_placeholders.select`)}
                  />
                </Form.Item>
              </Space>
            </InputsRow>

            <GroupTitle>{t(`${tPrefix}employer_information`)}</GroupTitle>
            <InputsRow>
              <Space>
                <Form.Item
                  label={t(`${tPrefix}cuit.title`)}
                  name="employerCuit"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          handleEmployersCuitSearch(undefined)
                          return Promise.resolve()
                        }
                        if (/^(20|23|24|27|30|33|34)\d{8}\d$/.test(value)) {
                          const lastDigit = parseInt(value[value.length - 1], 10)
                          const factors = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2]
                          let result = 0
                          factors.forEach((item, index) => {
                            result += item * value[index]
                          })
                          if (result % 11 === 0 && lastDigit === 0) {
                            handleEmployersCuitSearch(value)
                            return Promise.resolve()
                          }
                          if (lastDigit === 11 - (result % 11)) {
                            handleEmployersCuitSearch(value)
                            return Promise.resolve()
                          }
                        }
                        handleEmployersCuitSearch(undefined)
                        return Promise.reject(new Error(t(`${tPrefix}cuit.message_invalid`)))
                      },
                    }),
                  ]}
                >
                  <InputNum
                    stringMode
                    className="numeration"
                    maxLength={11}
                    disabled={!toEdit}
                    placeholder={t(`${tPrefix}generic_placeholders.numbers`)}
                  />
                </Form.Item>
                <Form.Item label={t(`${tPrefix}bussiness.title`)} name="bussinessName">
                  <Input
                    disabled={!toEdit}
                    placeholder={t(`${tPrefix}generic_placeholders.text`)}
                    allowClear
                    className="uppercase"
                    onInput={OtherUtils.inputToUppercase}
                  />
                </Form.Item>
              </Space>
            </InputsRow>

            <InputsRow>
              <Space>
                <GroupTitle>
                  {t(`${tPrefix}establishment_information`)}
                  <Button
                    type="link"
                    onClick={() => {
                      resetEstablishmentInfo()
                      if (selectedEstablishment === OTHER_ESTABLISHMENT) {
                        setEstablishmentSavedData(undefined)
                        form.setFieldsValue({ establishmentId: undefined })
                        setSelectedEstablishment(undefined)
                      } else {
                        form.setFieldsValue({ establishmentId: undefined })
                        setSelectedEstablishment(undefined)
                      }
                    }}
                  >
                    Limpiar
                  </Button>
                </GroupTitle>
              </Space>
            </InputsRow>

            <InputsRow>
              <Space id="establishmentSpace">
                {cuit &&
                employer &&
                employer.establishments &&
                employer.establishments.length > 0 ? (
                  <Form.Item label={t(`${tPrefix}establishment.title`)} name="establishmentId">
                    <Select
                      getPopupContainer={(triggerNode: HTMLElement) =>
                        triggerNode.parentNode as HTMLElement
                      }
                      optionFilterProp="children"
                      showSearch
                      disabled={!toEdit}
                      placeholder={t(`${tPrefix}generic_placeholders.select`)}
                      onChange={handleSelectEstablishment}
                    >
                      {employer.establishments?.map((establishment: Establishment) => (
                        <Option
                          key={establishment.establishmentId}
                          value={establishment.establishmentId || 0}
                        >
                          {establishment.establishmentName}
                        </Option>
                      ))}
                      <Option key="nonResgisteredEstablishment" value={OTHER_ESTABLISHMENT}>
                        {t(`${tPrefix}other_establishment`)}
                      </Option>
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item label={t(`${tPrefix}establishment.title`)} name="establishmentName">
                    <Input
                      disabled={!toEdit}
                      maxLength={100}
                      placeholder={t(`${tPrefix}generic_placeholders.text`)}
                      allowClear
                      className="uppercase"
                      onInput={OtherUtils.inputToUppercase}
                    />
                  </Form.Item>
                )}
                {selectedEstablishment === OTHER_ESTABLISHMENT && (
                  <Form.Item name="establishmentName">
                    <Input
                      disabled={!toEdit}
                      maxLength={100}
                      placeholder={t(`${tPrefix}other_establishment_placeholder`)}
                      allowClear
                      className="uppercase"
                      onInput={OtherUtils.inputToUppercase}
                    />
                  </Form.Item>
                )}
              </Space>
              {selectedEstablishment !== OTHER_ESTABLISHMENT && (
                <Form.Item hidden name="establishmentName">
                  <Input
                    hidden
                    disabled={!toEdit}
                    maxLength={100}
                    placeholder={t(`${tPrefix}generic_placeholders.text`)}
                    allowClear
                    className="uppercase"
                    onInput={OtherUtils.inputToUppercase}
                  />
                </Form.Item>
              )}
            </InputsRow>
            <InputsRow>
              <Space className="address-row">
                <Form.Item
                  label={t(`${tPrefix}street_type.title`)}
                  rules={[
                    {
                      required: isPostalDirectionRequired,
                      message: requiredMsg,
                    },
                  ]}
                  name="streetType"
                >
                  <Select
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentNode as HTMLElement
                    }
                    optionFilterProp="children"
                    showSearch
                    disabled={!toEdit}
                    onChange={handleLocationValidation}
                    placeholder={t(`${tPrefix}generic_placeholders.select`)}
                    allowClear
                  >
                    {streetTypes?.map(streetType => (
                      <Option key={streetType.id} value={streetType.id}>
                        {streetType.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t(`${tPrefix}street.title`)}
                  rules={[
                    {
                      required: isPostalDirectionRequired,
                      message: requiredMsg,
                    },
                  ]}
                  name="streetName"
                >
                  <Input
                    disabled={!toEdit}
                    onChange={handleLocationValidation}
                    maxLength={256}
                    placeholder={t(`${tPrefix}generic_placeholders.text`)}
                    allowClear
                    className="uppercase"
                    onInput={OtherUtils.inputToUppercase}
                  />
                </Form.Item>
                <Form.Item
                  label={t(`${tPrefix}street_number.title`)}
                  name="streetNumber"
                  rules={[
                    {
                      required: isPostalDirectionRequired,
                      message: requiredMsg,
                    },
                  ]}
                >
                  <InputNum
                    className="numeration"
                    disabled={!toEdit}
                    onChange={handleLocationValidation}
                    maxLength={30}
                    placeholder={t(`${tPrefix}generic_placeholders.numbers`)}
                  />
                </Form.Item>
              </Space>
            </InputsRow>
            <InputsRow>
              <Space>
                <Form.Item
                  label={t(`${tPrefix}province.title`)}
                  rules={[
                    {
                      required: isPostalDirectionRequired,
                      message: requiredMsg,
                    },
                  ]}
                  name="provinceId"
                >
                  <Select
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentNode as HTMLElement
                    }
                    optionFilterProp="children"
                    showSearch
                    disabled={!toEdit}
                    onChange={() => {
                      form.setFieldsValue({ departmentId: null, localityId: null })
                      setLocalities([])
                      setProvinceId(form.getFieldValue('provinceId'))
                      handleLocationValidation()
                    }}
                    placeholder={t(`${tPrefix}generic_placeholders.select`)}
                    allowClear
                  >
                    {provinces?.map(province => (
                      <Option key={province.provinceId} value={province.provinceId}>
                        {province.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t(`${tPrefix}department.title`)}
                  rules={[
                    {
                      required: isPostalDirectionRequired,
                      message: requiredMsg,
                    },
                  ]}
                  name="departmentId"
                >
                  <Select
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentNode as HTMLElement
                    }
                    optionFilterProp="children"
                    showSearch
                    disabled={!toEdit}
                    onChange={() => {
                      form.setFieldsValue({ localityId: null })
                      setDepartmentId(form.getFieldValue('departmentId'))
                      handleLocationValidation()
                    }}
                    placeholder={t(`${tPrefix}generic_placeholders.select`)}
                    allowClear
                  >
                    {departments?.map(department => (
                      <Option key={department.id} value={department.id}>
                        {department.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Space>
            </InputsRow>
            <InputsRow>
              <Space>
                <Form.Item
                  label={t(`${tPrefix}locality.title`)}
                  rules={[
                    {
                      required: isPostalDirectionRequired,
                      message: requiredMsg,
                    },
                  ]}
                  name="localityId"
                >
                  <Select
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentNode as HTMLElement
                    }
                    showSearch
                    disabled={!toEdit}
                    onChange={(value: number) => {
                      setLocalityId(value)
                      handleLocationValidation()
                    }}
                    placeholder={t(`${tPrefix}generic_placeholders.select`)}
                    allowClear
                    filterOption={false}
                    onSearch={(value: string) => {
                      if (value.length > 2) {
                        search(
                          {
                            value,
                            endpointCategory: 'localities',
                          },
                          {
                            onSuccess: data => {
                              setLocalities(data.data)
                            },
                            onError: () => {
                              notification.error({
                                message: t('globals.sections.orders.locality_error'),
                              })
                            },
                          },
                        )
                      }
                    }}
                    notFoundContent={null}
                  >
                    {localities?.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t(`${tPrefix}place.title`)}
                  name="place"
                  rules={[
                    {
                      type: 'string',
                      message: t(`${tPrefix}place.message_invalid`),
                    },
                  ]}
                >
                  <Input
                    disabled={!toEdit}
                    placeholder={t(`${tPrefix}generic_placeholders.text`)}
                    allowClear
                    className="uppercase"
                    onInput={OtherUtils.inputToUppercase}
                  />
                </Form.Item>
              </Space>
            </InputsRow>
            <InputsRow>
              <Space>
                <Form.Item label={t(`${tPrefix}delegation.title`)} name="delegationId">
                  <Select
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentNode as HTMLElement
                    }
                    optionFilterProp="children"
                    showSearch
                    disabled={!toEdit}
                    placeholder={t(`${tPrefix}generic_placeholders.select`)}
                    allowClear
                  >
                    {delegations?.map((delegation: ComboType) => (
                      <Option key={delegation.id} value={delegation.id}>
                        {delegation.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Space>
            </InputsRow>
            <InputsRow>
              <Space>
                <Form.Item label={t(`${tPrefix}activity.title`)} name="activityId">
                  <Select
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentNode as HTMLElement
                    }
                    optionFilterProp="children"
                    showSearch
                    disabled={!toEdit}
                    placeholder={t(`${tPrefix}generic_placeholders.select`)}
                    allowClear
                  >
                    {activities?.map(activity => {
                      return (
                        <Option key={activity.id} value={activity.id}>
                          {activity.name}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Space>
            </InputsRow>
            <InputsRow>
              <Space>
                <Form.Item
                  label={t(`${tPrefix}latitude.title`)}
                  tooltip={t(`${tPrefix}latitude.example`)}
                  rules={[
                    {
                      required: isCoordinatesRequired,
                      message: requiredMsg,
                    },
                    {
                      pattern: latitudRegex,
                      message: t(`${tPrefix}latitude.invalid_entry`),
                    },
                  ]}
                  name="latitude"
                >
                  <Input
                    disabled={!toEdit}
                    onInput={OtherUtils.inputCoordinatesSanitize}
                    maxLength={coordinatesInputLenghtLimit}
                    onChange={handleLocationValidation}
                    placeholder={t(`${tPrefix}generic_placeholders.coordinates`)}
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  label={t(`${tPrefix}longitude.title`)}
                  tooltip={t(`${tPrefix}longitude.example`)}
                  rules={[
                    {
                      required: isCoordinatesRequired,
                      message: requiredMsg,
                    },
                    {
                      pattern: longitudeRegex,
                      message: t(`${tPrefix}longitude.invalid_entry`),
                    },
                  ]}
                  name="longitude"
                >
                  <Input
                    disabled={!toEdit}
                    onInput={OtherUtils.inputCoordinatesSanitize}
                    maxLength={coordinatesInputLenghtLimit}
                    onChange={handleLocationValidation}
                    placeholder={t(`${tPrefix}generic_placeholders.coordinates`)}
                    allowClear
                  />
                </Form.Item>
              </Space>
            </InputsRow>
            <Form.List name="georeferences">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map(({ key, name }) => {
                      return (
                        <InputsRow key={key + name}>
                          <Space className="georeference-row">
                            <Form.Item
                              name={[name, 'latitude']}
                              tooltip={t(`${tPrefix}latitude.example`)}
                              initialValue={
                                order && order.georeferences && order.georeferences[key]
                                  ? order?.georeferences[key].latitude
                                  : undefined
                              }
                              rules={[
                                {
                                  pattern: latitudRegex,
                                  message: t(`${tPrefix}latitude.invalid_entry`),
                                },
                              ]}
                            >
                              <Input
                                disabled={!toEdit}
                                onInput={OtherUtils.inputCoordinatesSanitize}
                                maxLength={coordinatesInputLenghtLimit}
                                placeholder={t(`${tPrefix}latitude.title`)}
                              />
                            </Form.Item>
                            <Form.Item
                              name={[name, 'longitude']}
                              tooltip={t(`${tPrefix}longitude.example`)}
                              initialValue={
                                order && order.georeferences && order.georeferences[key]
                                  ? order?.georeferences[key].longitude
                                  : undefined
                              }
                              rules={[
                                {
                                  pattern: longitudeRegex,
                                  message: t(`${tPrefix}longitude.invalid_entry`),
                                },
                              ]}
                            >
                              <Input
                                disabled={!toEdit}
                                onInput={OtherUtils.inputCoordinatesSanitize}
                                maxLength={coordinatesInputLenghtLimit}
                                placeholder={t(`${tPrefix}longitude.title`)}
                              />
                            </Form.Item>
                            <Form.Item
                              name={[name, 'description']}
                              rules={[
                                {
                                  required: true,
                                  message: requiredMsg,
                                },
                                {
                                  type: 'string',
                                  message: t(`${tPrefix}place.message_invalid`),
                                },
                              ]}
                            >
                              <Row className="georeference-last-form-item">
                                <TextArea
                                  disabled={!toEdit}
                                  placeholder={t(`${tPrefix}detail.title`)}
                                  className="uppercase"
                                  onInput={OtherUtils.inputToUppercase}
                                  defaultValue={
                                    order &&
                                    order?.georeferences &&
                                    order.georeferences[key].description
                                      ? order?.georeferences[key].description
                                      : undefined
                                  }
                                  maxLength={100}
                                />
                                {toEdit && (
                                  <MinusCircleOutlined
                                    onClick={() => {
                                      remove(name)
                                      setGeoreferenceCount(state => state - 1)
                                    }}
                                  />
                                )}
                              </Row>
                            </Form.Item>
                          </Space>
                        </InputsRow>
                      )
                    })}
                    {georeferenceCount < 3 && toEdit && (
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            if (form.getFieldValue('latitude') && form.getFieldValue('longitude')) {
                              const lat = !!form.getFieldValue('latitude').replace('-', '').length
                              const long = !!form.getFieldValue('longitude').replace('-', '').length
                              if (lat && long) {
                                if (
                                  form.getFieldValue('latitude') !== '0.0' &&
                                  form.getFieldValue('longitude') !== '0.0'
                                ) {
                                  add()
                                  setGeoreferenceCount(state => state + 1)
                                } else {
                                  notificationGeoreferenceAddError()
                                }
                              } else {
                                notificationGeoreferenceAddError()
                              }
                            } else {
                              notificationGeoreferenceAddError()
                            }
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          {t(`${tPrefix}add_georeference`)}
                        </Button>
                      </Form.Item>
                    )}
                  </>
                )
              }}
            </Form.List>

            <GroupTitle>{t(`${tPrefix}additionals`)}</GroupTitle>
            <InputsRow>
              <Space>
                <Form.Item
                  label={t(`${tPrefix}additional_information.title`)}
                  name="additionalInformation"
                >
                  <TextArea
                    disabled={!toEdit}
                    maxLength={2500}
                    rows={4}
                    placeholder={t(`${tPrefix}generic_placeholders.text`)}
                    allowClear
                    className="uppercase"
                    onInput={OtherUtils.inputToUppercase}
                  />
                </Form.Item>
              </Space>
            </InputsRow>

            <GroupTitle>{t(`${tPrefix}complementary_information`)}</GroupTitle>
            <InputsRow>
              <Space>
                <Form.Item label={t(`${tPrefix}cause.title`)} name="causes">
                  <Select
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentNode as HTMLElement
                    }
                    optionFilterProp="children"
                    showSearch
                    disabled={!toEdit}
                    onChange={handleOtherCause}
                    allowClear
                    showArrow
                    mode="multiple"
                    placeholder={t(`${tPrefix}generic_placeholders.select`)}
                  >
                    {causes?.map(cause => (
                      <Option key={cause.id} value={cause.id}>
                        {cause.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label={t(`${tPrefix}origin.title`)} name="origin">
                  <Select
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentNode as HTMLElement
                    }
                    optionFilterProp="children"
                    showSearch
                    disabled={!toEdit}
                    allowClear
                    onChange={handleOtherOrigin}
                    placeholder={t(`${tPrefix}generic_placeholders.select`)}
                  >
                    {origins?.map(origin => (
                      <Option key={origin.id ?? origin.name} value={origin.id}>
                        {origin.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Space>
            </InputsRow>
            <InputsRow>
              <Space>
                <Form.Item
                  label={t(`${tPrefix}other_cause.title`)}
                  rules={[
                    {
                      required: !otherCauseDisabled,
                      message: requiredMsg,
                    },
                  ]}
                  name="otherCause"
                >
                  <TextArea
                    disabled={!toEdit || otherCauseDisabled}
                    placeholder={t(`${tPrefix}generic_placeholders.text`)}
                    allowClear
                    maxLength={100}
                    className="uppercase"
                    onInput={OtherUtils.inputToUppercase}
                  />
                </Form.Item>
                <Form.Item
                  label={t(`${tPrefix}other_origin.title`)}
                  rules={[
                    {
                      required: !otherOriginDisabled,
                      message: requiredMsg,
                    },
                  ]}
                  name="otherOrigin"
                >
                  <TextArea
                    disabled={!toEdit || otherOriginDisabled}
                    placeholder={t(`${tPrefix}generic_placeholders.text`)}
                    allowClear
                    maxLength={100}
                    className="uppercase"
                    onInput={OtherUtils.inputToUppercase}
                  />
                </Form.Item>
              </Space>
            </InputsRow>
            <InputsRow>
              <Space>
                <Form.Item
                  label={t(`${tPrefix}employer_registered.label`)}
                  name="isEmployerRegistered"
                  valuePropName="checked"
                >
                  <Switch disabled onChange={handleSwitch} />
                </Form.Item>
                <Form.Item label={t(`${tPrefix}employees.title`)} name="employeesQuantity">
                  <InputNum
                    disabled
                    placeholder={t(`${tPrefix}generic_placeholders.numbers`)}
                    min={0}
                    max={999}
                  />
                </Form.Item>
              </Space>
            </InputsRow>

            <SectionTitle>{t(`${tPrefix}asignation_information`)}</SectionTitle>

            <GroupTitle className="inspectors">{t(`${tPrefix}responsible`)}</GroupTitle>
            <InputsRow>
              <Space>
                <Form.Item name="responsibleInspectorId">
                  <Select
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentNode as HTMLElement
                    }
                    disabled={!toEdit}
                    showSearch
                    style={{ width: '100%' }}
                    placeholder={t(`${tPrefix}responsible_inspector.label`)}
                    optionFilterProp="inspectors"
                    allowClear
                    onChange={value => {
                      if (!value) {
                        setIsResponsibleSelected(false)
                      } else {
                        setIsResponsibleSelected(true)
                        const selectedAuxiliars = form.getFieldValue('auxiliarInspectorsIds')
                        if (selectedAuxiliars) {
                          form.setFieldsValue({
                            auxiliarInspectorsIds: selectedAuxiliars.filter(
                              (auxiliar?: number) => auxiliar !== value,
                            ),
                          })
                        }
                      }
                      handleLocationValidation()
                    }}
                    filterOption={(input, option) =>
                      option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {inspectors?.map(inspector => (
                      <Option key={inspector.id} value={inspector.id}>
                        {inspector.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Space>
            </InputsRow>

            <GroupTitle className="inspectors">{t(`${tPrefix}auxiliars`)}</GroupTitle>
            <InputsRow>
              <Space>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.responsibleInspectorId !== currentValues.responsibleInspectorId
                  }
                >
                  {({ getFieldValue }) => (
                    <Form.Item name="auxiliarInspectorsIds">
                      <Select
                        getPopupContainer={(triggerNode: HTMLElement) =>
                          triggerNode.parentNode as HTMLElement
                        }
                        disabled={!toEdit}
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder={t(`${tPrefix}auxiliar_inspectors.title`)}
                        filterOption={(input, option) =>
                          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {inspectors
                          ?.filter(
                            inspector => inspector.id !== getFieldValue('responsibleInspectorId'),
                          )
                          .map(inspector => (
                            <Option key={inspector.id} value={inspector.id}>
                              {inspector.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                </Form.Item>
              </Space>
            </InputsRow>
          </StyledForm>
        </div>
      </Spin>
    </OrderModal>
  )
}

export default OrderForm

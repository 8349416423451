import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useGetHistory = (id?: number) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.HISTORY_ORDER, id],
    () => {
      return DataApi.getHistory(id)
    },
    {
      staleTime: Infinity,
      enabled: !!id,
    },
  )
  return { orderHistory: data?.data, isLoading }
}

export { useGetHistory }

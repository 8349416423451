import { Empty, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'
import { setChonkyDefaults, ChonkyActions, FileActionHandler, FileArray } from 'chonky'
import { ChonkyIconFA } from 'chonky-icon-fontawesome'
import { PaperClipOutlined, TeamOutlined } from '@ant-design/icons'
import React, { useCallback, useEffect, useState } from 'react'

import { OtherUtils } from '@utils'
import { ActionsEnum, AttachmentType, FileAttachType, MediaTypes } from '@types'
import { useAuxiliars } from '@contexts'
import { useGetMedia } from '@hooks'

import { StandardModal, StyledTabs, StyledFullFileBrowser, StyledSpin } from '../../../components'
import './styles.css'
import { useAttachFile } from '../../hooks/useAttachFile'
import { useGetAttachments } from '../../hooks/useGetAttachments'
import { useDownloadAttachment } from '../../hooks/useDownloadAttachment'
import { useDeleteAttachment } from '../../hooks/useDeleteAttachment'
import ConfirmActionModal from '../../../components/ConfirmActionModal'

notification.config({
  placement: 'topRight',
  duration: 10,
})

interface MediaPropsType {
  orderId: string | undefined
  isVisible: boolean
  onClose: () => void
}

const MediaSurveyModal = ({ orderId, isVisible, onClose }: MediaPropsType) => {
  const { activeSurveyId } = useAuxiliars()
  const { t } = useTranslation()
  const { TabPane } = StyledTabs
  const { attach, isUploading } = useAttachFile()
  const { media, mediaIsLoading } = useGetMedia(activeSurveyId)
  const { download } = useDownloadAttachment()
  const { deleteAttachment } = useDeleteAttachment()
  const { attachments, attachmentsIsLoading } = useGetAttachments(activeSurveyId)
  const tPrefix = 'globals.sections.surveys.media.'
  const [isConfirmActionVisible, setIsConfirmActionVisible] = useState<boolean>(false)
  const [actionFile, setActionFile] = useState<AttachmentType | undefined>(undefined)
  const [actionToDo, setActionToDo] = useState<ActionsEnum | undefined>(undefined)
  const { DELETE, DOWNLOAD } = ActionsEnum

  const renderVideo = (item: ReactImageGalleryItem) => {
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video controls>
        <source src={item.original} type="video/mp4" />
      </video>
    )
  }

  const [mediaAux, setMediaAux] = useState<ReactImageGalleryItem[]>([])

  useEffect(() => {
    if (media) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setMediaAux(
        media.map(item => {
          if (item.format === MediaTypes.VIDEO) {
            return { ...item, renderItem: () => renderVideo(item) }
          }
          return item
        }),
      )
    }
  }, [media])

  const locale = {
    locale: 'es',
    messages: {
      'chonky.toolbar.searchPlaceholder': 'Buscar',
      'chonky.toolbar.visibleFileCount': `{fileCount, plural,
          one {# archivo}
          few {# Archivo}
          many {# Archivos}
      }`,
      'chonky.toolbar.selectedFileCount': `{fileCount, plural,
          =0 {}
          one {# seleccionado}
          other {# seleccionados}
      }`,
      'chonky.toolbar.hiddenFileCount': `{fileCount, plural,
          =0 {}
          one {# oculto}
          other {# ocultos}
      }`,
      'chonky.fileList.nothingToShow': 'Nada que mostrar',
      'chonky.contextMenu.browserMenuShortcut': 'Atajo de menú: {shortcut}',

      'chonky.actionGroups.Actions': 'Acciones',
      'chonky.actionGroups.Options': 'Opciones',
      [`chonky.actions.${ChonkyActions.OpenParentFolder.id}.button.name`]: 'Carpeta padre',
      [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.name`]: 'Crear carpeta',
      [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.tooltip`]: 'Crear carpeta',
      [`chonky.actions.${ChonkyActions.DeleteFiles.id}.button.name`]: 'Eliminar',
      [`chonky.actions.${ChonkyActions.OpenSelection.id}.button.name`]: 'Abrir',
      [`chonky.actions.${ChonkyActions.SelectAllFiles.id}.button.name`]: 'Seleccionar todos',
      [`chonky.actions.${ChonkyActions.ClearSelection.id}.button.name`]: 'Deseleccionar',
      [`chonky.actions.${ChonkyActions.EnableListView.id}.button.name`]: 'Vista de lista',
      [`chonky.actions.${ChonkyActions.EnableGridView.id}.button.name`]: 'Vista de grilla',
      [`chonky.actions.${ChonkyActions.SortFilesByName.id}.button.name`]: 'Ordenar por nombre',
      [`chonky.actions.${ChonkyActions.SortFilesBySize.id}.button.name`]: 'Ordenar por tamaño',
      [`chonky.actions.${ChonkyActions.SortFilesByDate.id}.button.name`]: 'Ordenar por fecha',
      [`chonky.actions.${ChonkyActions.ToggleHiddenFiles.id}.button.name`]:
        'Mostrar archivos ocultos',
      [`chonky.actions.${ChonkyActions.ToggleShowFoldersFirst.id}.button.name`]:
        'Mostrar carpetas primero',
      [`chonky.actions.${ChonkyActions.UploadFiles.id}.button.name`]: 'Subir',
      [`chonky.actions.${ChonkyActions.UploadFiles.id}.button.tooltip`]: 'Subir archivo/s',
      [`chonky.actions.${ChonkyActions.DownloadFiles.id}.button.name`]: 'Descargar',
    },
  }

  setChonkyDefaults({ iconComponent: ChonkyIconFA })
  const folderChain = null // or [{ id: `${surveyId}`, name: `${surveyId}`, isDir: true }]
  const fileActions = [
    ChonkyActions.UploadFiles,
    ChonkyActions.DownloadFiles,
    ChonkyActions.DeleteFiles,
    ChonkyActions.EnableListView,
    ChonkyActions.EnableGridView,
    ChonkyActions.SortFilesByName,
    ChonkyActions.SortFilesBySize,
    ChonkyActions.SortFilesByDate,
    // ChonkyActions.SelectAllFiles,
    // ChonkyActions.ClearSelection,
  ]

  const inputReset = () => {
    const fileInput = document.querySelector('#filesToUpload') as HTMLInputElement
    fileInput.value = ''
  }
  const handleAttachFile = (data: FileAttachType) => {
    attach({ surveyId: activeSurveyId, data })
    inputReset()
    notification.info({
      message: data.name + t(`${tPrefix}uploading_msg`),
      key: 'uploadingFile',
    })
  }
  const handleDownloadAttachment = () => {
    download(actionFile!)
    notification.info({
      message: actionFile!.filename + t(`${tPrefix}downloading_msg`),
      key: 'downloadingFile',
    })
  }
  const handleDeleteAttachment = () => {
    deleteAttachment(actionFile!)
    notification.info({
      message: actionFile!.filename + t(`${tPrefix}deleting_msg`),
      key: 'deletingFile',
    })
  }
  const handleAction = () => {
    if (actionToDo === DELETE) {
      handleDeleteAttachment()
    } else if (actionToDo === DOWNLOAD) {
      handleDownloadAttachment()
    }
    setIsConfirmActionVisible(false)
    setActionToDo(undefined)
    setActionFile(undefined)
  }
  const handleFileAction: FileActionHandler = useCallback(data => {
    if (data.id === ChonkyActions.OpenFiles.id) {
      // do openfiles
    }
    if (data.id === ChonkyActions.UploadFiles.id) {
      const input = document.querySelector('#filesToUpload') as HTMLInputElement
      if (isUploading) {
        notification.info({ message: `${tPrefix}uploading_in_progress` })
      } else if (input) {
        input.click()
        input.onchange = e => {
          const { files } = e.target as HTMLInputElement
          if (files) {
            OtherUtils.convertBase64(files[0])
              .then(convertedFile => {
                const base64 = convertedFile as string
                handleAttachFile({
                  name: files[0].name,
                  size: files[0].size,
                  file: base64,
                })
              })
              .catch(err => console.log(err))
          }
        }
      }
    }
    if (data.id === ChonkyActions.DeleteFiles.id) {
      const item = data.state.selectedFiles[0]
      procced(
        {
          id: item.id,
          filename: item.name,
        },
        DELETE,
      )
    }
    if (data.id === ChonkyActions.DownloadFiles.id) {
      const item = data.state.selectedFiles[0]
      procced(
        {
          id: item.id,
          filename: item.name,
        },
        DOWNLOAD,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const procced = (data: AttachmentType, action: ActionsEnum) => {
    setActionFile(data)
    setActionToDo(action)
    setIsConfirmActionVisible(true)
  }
  const nodeRef = React.useRef(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <StandardModal
      className="media-modal"
      visible={isVisible}
      title={
        <h1>{`${t(`${tPrefix}modal_title`) + activeSurveyId} ${
          orderId ? ` | ${t('globals.item_types.order')} #${orderId}` : ''
        }`}</h1>
      }
      onCancel={() => {
        onClose()
      }}
      footer={false}
      centered
    >
      <StyledTabs>
        <TabPane
          className="media-tab"
          tab={
            <span>
              <TeamOutlined />
              Adjuntos en relevamiento
            </span>
          }
          key="1"
        >
          {mediaIsLoading && <StyledSpin spinning />}
          {media && media.length > 0 ? (
            <ImageGallery showPlayButton={false} items={mediaAux} />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </TabPane>
        <TabPane
          tab={
            <span>
              <PaperClipOutlined />
              Anexos de administrador
            </span>
          }
          key="2"
        >
          {attachmentsIsLoading && <StyledSpin spinning />}
          <div ref={nodeRef} style={{ height: 300 }}>
            <StyledFullFileBrowser
              onFileAction={handleFileAction}
              files={(attachments as FileArray) || []}
              folderChain={folderChain}
              fileActions={fileActions}
              i18n={locale}
              disableDefaultFileActions
            />
          </div>
          <input
            type="file"
            accept=".pdf, .mp4, .jpg, .png, .mpg, .mp3, .ogg, .opus"
            id="filesToUpload"
            hidden
          />
        </TabPane>
      </StyledTabs>
      <ConfirmActionModal
        isConfirmActionVisible={isConfirmActionVisible}
        title={`${t(`globals.${actionToDo?.toLowerCase()}`)} "${actionFile?.filename}"`}
        onClose={() => {
          setIsConfirmActionVisible(false)
        }}
        action={handleAction}
      />
    </StandardModal>
  )
}

export default MediaSurveyModal

import { createGlobalStyle } from 'styled-components'

import { theme } from './theme'

const GlobalStyles = createGlobalStyle`
  body {
    overflow: hidden
  }
  .submit-sign-button {
    text-decoration: none;
    border: none;
    box-shadow: none;
    padding: none;
    margin: none;
    background: none;
    font-weight: 500;
  }
  .anticon svg{
    color: ${theme.primaryColor};
  }

  .anticon.black svg{
    color: ${theme.lightBlack};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: white;
    border-color: black;
    &:after {
      border-color:black
    }
  }
  .ant-table-thead > tr > th {
    border-bottom: 1px solid black;
    &:before {
      width: 0px
    }
  }
  .ant-tooltip-inner {
    background-color: rgba(0, 0, 0, .90);
  }
  .d-none {
    display: none;
  }
  .certificate-selection-space {
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  .certificate-selection-space .sign-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
    cursor: pointer;
  }
  .certificate-selection-space .sign-btn.disabled {
    cursor: not-allowed;
    filter: saturate(0);
    color: gray;
  }
  .unregistered-btn {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    padding: 0px 0.62rem;
    align-items: center;
    gap: 0.5rem;
    color: ${theme.primaryColor};
    background-color: transparent !important;
  }
  .register-modal .ant-form-item-control-input-content {
    font-weight: 600;
    color: ${theme.lightBlack} !important;
  }
  .register-modal  .ant-form-item-explain, .register-modal .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
  .register-modal .ant-modal-header {
    background-color: ${theme.greenHeaderModal};
    h1 {
      color: white;
      text-align: center
    }
  }
`
export { GlobalStyles }

import React from 'react'

import { removeAuthHeader } from '@api'
import { useUser } from '@contexts'
import { ROUTE_KEYS } from '@constants'

import { StyledLayout } from './components'

const Layout: React.FC = ({ children }) => {
  const { state } = useUser()
  const { LOGIN } = ROUTE_KEYS
  if (state === null) {
    removeAuthHeader()
    window.localStorage.clear()
    window.location.href = LOGIN
  }

  return <StyledLayout>{children}</StyledLayout>
}

export { Layout }

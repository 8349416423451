import { Select, Space, Typography } from 'antd'
import { FileDoneOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'

import { Certificate, RecordTypeEnum, UrlSignParams } from '@types'
import { LOCAL_STORAGE_KEYS } from '@constants'
import { config } from '@config'

interface DigitalSignProps {
  itemId: number
  itemType: RecordTypeEnum | string
  signParams: UrlSignParams | undefined
}

const DigitalSign = ({ itemId, itemType, signParams }: DigitalSignProps) => {
  const { Option } = Select
  const { Text } = Typography
  const [selectedCertificate, setSelectedCertificate] = useState<Certificate | undefined>(undefined)

  useEffect(() => {
    if (signParams) {
      if (signParams.certificates) {
        setSelectedCertificate(signParams.certificates[signParams?.certificates.length - 1])
      }
    } else if (selectedCertificate) {
      setSelectedCertificate(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signParams, signParams?.certificates])

  return (
    <div className="certificate-selection-space">
      <Select
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
        maxTagCount="responsive"
        style={{ width: '100%', flex: 1 }}
        placeholder="Seleccione un certificado"
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={selectedCertificate}
        onChange={(value: Certificate) => setSelectedCertificate(value)}
      >
        {signParams?.certificates?.length &&
          signParams.certificates.map((cert: Certificate) => (
            <Option key={cert} value={cert}>
              {cert}
            </Option>
          ))}
      </Select>
      {signParams?.certificates ? (
        <form name="enviarForm" method="GET" action={`${config.baseURL}/digital-sign/get-sign`}>
          <input name="itemType" type="hidden" value={itemType} />
          <input name="itemId" type="hidden" value={itemId} />
          <input
            name="re_token"
            type="hidden"
            value={localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN)!}
          />
          <input name="certificate" type="hidden" value={selectedCertificate} />
          <input
            name="callback"
            type="hidden"
            value={`${window.location.href}?itemType=${itemType}~itemId=${itemId}`}
          />
          <button className="submit-sign-button" type="submit" disabled={!selectedCertificate}>
            <div className={`sign-btn${!selectedCertificate ? ' disabled' : ''}`}>
              <Text style={{ fontSize: '1rem' }}>Firmar</Text>
              <FileDoneOutlined style={{ fontSize: '1.5rem' }} />
            </div>
          </button>
        </form>
      ) : (
        <form
          name="certificados"
          method="GET"
          action={`${config.baseURL}/digital-sign/get-certificates`}
        >
          <input
            name="re_token"
            type="hidden"
            value={localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN)!}
          />
          <input
            name="callback"
            type="hidden"
            value={`${window.location.href}?itemType=${itemType}~itemId=${itemId}`}
            // en la uel del callback hay que agregar ~certificates=<Certificate[]>
          />
          <button className="submit-sign-button" type="submit">
            <Space style={{ cursor: 'pointer' }}>
              <Text style={{ fontSize: '1rem' }}>Obtener certificados</Text>
              <FileDoneOutlined style={{ fontSize: '1.5rem' }} />
            </Space>
          </button>
        </form>
      )}
    </div>
  )
}

export default DigitalSign

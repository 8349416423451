import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useGetLocalities = (departmentId: number) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.LOCALITIES, departmentId],
    () => {
      return DataApi.getLocalities(departmentId)
    },
    { staleTime: Infinity },
  )
  return { localitiesRequest: data?.data, isLoading }
}

export { useGetLocalities }

import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'
import { FilterContextType } from '@types'

const useGetSurveys = (filters?: FilterContextType) => {
  const { data, isLoading } = useQuery([QUERY_KEYS.SURVEYS, filters], () => {
    return DataApi.getSurveys(filters)
  })
  return { surveys: data, surveysIsLoading: isLoading }
}

export { useGetSurveys }

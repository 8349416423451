import {
  CloseCircleOutlined,
  DiffOutlined,
  EyeOutlined,
  /*  EditOutlined, EyeOutlined, */ HistoryOutlined,
} from '@ant-design/icons'
import { Space, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useGetOrders } from '@hooks'
import { useFilters } from '@contexts'
import { RegisteredOrderType } from 'src/api/data'
import { OtherUtils } from '@utils'
import { ColumnTitleRender } from '@components'
import { InspectionOrder } from '@types'

import {
  CardWrapper,
  CardWrapperHeader,
  PageName,
  Options,
  CardWrapperTable,
  OptionsWrapper,
  StyledOrderActivityColumn,
} from '../components'
import OrdersFilter from './components/OrdersFilters'
import AddOrderModal from './components/AddOrderModal'
import SeeOrderModal from './components/SeeOrderModal'
import HistoryOrderModal from './components/HistoryOrderModal'
import OrderCloserModal from './components/OrderCloserModal'
import BulkDataUploadModal from './components/BulkDataUpload'

const Orders = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const { filters, setFilters } = useFilters()
  const { orders, isLoading } = useGetOrders(filters)
  const fieldsPrefix = 'globals.fields.'
  const tPrefix = 'globals.sections.orders.'
  const [seeIsVisible, setSeeIsVisible] = useState(false)
  const [historyIsVisible, setHistoryIsVisible] = useState(false)
  const [orderId, setOrderId] = useState<number>()
  const [auxOrderId, setAuxOrderId] = useState<number>()
  const [editable, setEditable] = useState(true)
  const [isOrderCloserVisible, setIsOrderCloserVisible] = useState(false)
  const [bulkDataUploadModalIsVisible, setBulkDataUploadModalIsVisible] = useState(false)
  const [bulkDataUploadModalIsMount, setBulkDataUploadModalIsMount] = useState(false)
  OtherUtils.handleLastLocation(pathname)

  const handleSeeOrder = (item: RegisteredOrderType) => {
    setSeeIsVisible(true)
    setOrderId(item.id)
    setEditable(item.toInspect)
  }

  const columns = [
    {
      title: ColumnTitleRender({ tKey: 'number', key: 'id', sortable: true }),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: ColumnTitleRender({ tKey: 'creation_date', key: 'createdAt', sortable: true }),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: ColumnTitleRender({
        tKey: 'date_fiscalization',
        key: 'fiscalizationDate',
        sortable: true,
      }),
      dataIndex: 'fiscalizationDate',
      key: 'fiscalizationDate',
    },
    {
      title: ColumnTitleRender({ tKey: 'status', key: 'status', sortable: true }),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: ColumnTitleRender({ tKey: 'priority', key: 'priority', sortable: true }),
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: ColumnTitleRender({
        tKey: 'responsible_inspector',
        key: 'responsibleInspector',
        sortable: true,
      }),
      dataIndex: 'responsibleInspector',
      key: 'responsibleInspector',
    },
    {
      title: ColumnTitleRender({ tKey: 'cuit', key: 'cuit', sortable: true }),
      dataIndex: 'cuit',
      key: 'cuit',
    },
    {
      title: ColumnTitleRender({ tKey: 'employer', key: 'employer', sortable: true }),
      dataIndex: 'employer',
      key: 'employer',
    },
    {
      title: ColumnTitleRender({ tKey: 'activity', key: 'activity', sortable: true }),
      key: 'activity',
      render: (inspectionOrder: InspectionOrder) => (
        <StyledOrderActivityColumn>{inspectionOrder.activity}</StyledOrderActivityColumn>
      ),
    },
    {
      title: ColumnTitleRender({ tKey: 'state', key: 'province', sortable: true }),
      dataIndex: 'province',
      key: 'province',
    },
    {
      title: ColumnTitleRender({ tKey: 'city', key: 'city', sortable: true }),
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: t(`${fieldsPrefix}actions.title`),
      render: (item: RegisteredOrderType) => (
        <Space size="middle">
          <Tooltip title={t('globals.tooltips.see')}>
            <EyeOutlined
              type="primary"
              onClick={() => {
                handleSeeOrder(item)
              }}
            />
          </Tooltip>
          <Tooltip title={t('globals.tooltips.history')}>
            <HistoryOutlined
              type="secundary"
              onClick={() => {
                setHistoryIsVisible(true)
                setAuxOrderId(item.id)
              }}
            />
          </Tooltip>
          {item.toInspect && (
            <Tooltip title={t('globals.tooltips.close')}>
              <CloseCircleOutlined
                type="primary"
                onClick={() => {
                  setIsOrderCloserVisible(true)
                  setAuxOrderId(item.id)
                }}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ]

  return (
    <CardWrapper>
      <CardWrapperHeader>
        <PageName>
          <h1>{t('globals.sections.orders.name')}</h1>
        </PageName>
        <Options>
          <OptionsWrapper>
            <Tooltip title={t(`${tPrefix}bulk_data_upload.modal_title`)}>
              <DiffOutlined
                onClick={() => {
                  setBulkDataUploadModalIsMount(true)
                  setBulkDataUploadModalIsVisible(true)
                }}
              />
            </Tooltip>
            <OrdersFilter />
          </OptionsWrapper>
          <AddOrderModal />
        </Options>
      </CardWrapperHeader>
      {bulkDataUploadModalIsMount ? (
        <BulkDataUploadModal
          isVisible={bulkDataUploadModalIsVisible}
          onClose={() => {
            setBulkDataUploadModalIsVisible(false)
            setTimeout(() => setBulkDataUploadModalIsMount(false), 300)
          }}
        />
      ) : (
        ''
      )}
      <SeeOrderModal
        orderId={orderId}
        isVisible={seeIsVisible}
        editable={editable}
        onClose={() => {
          setSeeIsVisible(false)
          setOrderId(undefined)
        }}
      />
      <HistoryOrderModal
        orderId={auxOrderId}
        isVisible={historyIsVisible}
        onClose={() => {
          setHistoryIsVisible(false)
          setOrderId(undefined)
        }}
      />
      <OrderCloserModal
        orderId={auxOrderId}
        isOrderCloserVisible={isOrderCloserVisible}
        onClose={() => {
          setIsOrderCloserVisible(false)
        }}
      />
      <div className="principal-table">
        <CardWrapperTable
          columns={columns}
          rowKey="id"
          onRow={record => {
            const item = record as RegisteredOrderType
            return {
              onClick: e => {
                const target = e.target as Element
                if (target.classList.contains('ant-table-cell')) {
                  handleSeeOrder(item)
                }
              },
            }
          }}
          dataSource={orders?.data ?? []}
          loading={isLoading}
          pagination={{
            showSizeChanger: true,
            current: orders?.meta.current_page,
            pageSize: filters.filters?.limit,
            total: orders?.meta.total_records,
            onChange: (page, pageSize) => {
              setFilters({
                ...filters,
                filters: {
                  ...filters.filters,
                  limit: pageSize ?? 10,
                  page,
                },
              })
            },
          }}
        />
      </div>
    </CardWrapper>
  )
}

export default Orders

import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useGetSurveyNotes = (surveyId: number) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.SURVEY_NOTES, surveyId],
    () => {
      return DataApi.getSurveyNotes(surveyId)
    },
    {
      enabled: !!surveyId && surveyId > 0,
      staleTime: Infinity,
    },
  )
  const sortedData = data?.data.sort((a, b) => {
    if (a.is_responsible && !b.is_responsible) {
      return -1 // Si a es responsable y b no lo es, a va antes que b
    }
    if (!a.is_responsible && b.is_responsible) {
      return 1 // Si b es responsable y a no lo es, b va antes que a
    }
    const dateA = new Date(a.date).getTime()
    const dateB = new Date(b.date).getTime()

    if (dateA === dateB) {
      return a.fullname.localeCompare(b.fullname)
    }
    return dateA - dateB
  })
  return { surveyNotes: sortedData, surveyNotesIsLoading: isLoading }
}

export { useGetSurveyNotes }

import { InfoCircleOutlined } from '@ant-design/icons'
import { Form, Input, notification, Radio, Space, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useEstablishElectronicAddress, useGetElectronicAddress } from '@hooks'
import { useUser } from '@contexts'

import { InputsRow, StandardModal } from '..'

notification.config({
  placement: 'topRight',
  duration: 10,
})

const RegisterModal = () => {
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false)
  const { t } = useTranslation()
  const tPrefix = 'globals.register_modal.'
  const [form] = Form.useForm()
  const { state } = useUser()

  const onClose = () => {
    setIsRegisterModalOpen(false)
  }

  const { establishElectronicAddress } = useEstablishElectronicAddress(onClose)

  const { electronicAddressData, isLoadingElectronicAddressData } = useGetElectronicAddress()

  const addressRequestWillRetry = () => {
    onClose()
    form.resetFields()
  }

  const handleOk = () => {
    if (state) {
      form
        .validateFields()
        .then(data => {
          if (data.enableNotifications) {
            establishElectronicAddress({ ...data, cuit: state.cuit })
          } else {
            notification.warning({
              message: t(`${tPrefix}need_register`),
            })
            setTimeout(addressRequestWillRetry, 1000)
          }
        })
        .catch(() => {
          notification.error({
            message: t(`${tPrefix}validation_error`),
          })
        })
    }
  }

  const [enableNotifications, setEnableNotifications] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    form.setFieldsValue({ enableNotifications })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableNotifications])

  useEffect(() => {
    if (!isRegisterModalOpen && !isLoadingElectronicAddressData) {
      if (!electronicAddressData || !electronicAddressData.enableNotifications) {
        setIsRegisterModalOpen(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [electronicAddressData])

  return (
    <StandardModal
      visible={isRegisterModalOpen}
      title={<h1>{t(`${tPrefix}title`)}</h1>}
      okText={t('globals.confirm')}
      cancelText={t('globals.cancel')}
      onCancel={onClose}
      onOk={handleOk}
      className="register-modal"
      maskClosable={false}
      closable={false}
    >
      <Form
        form={form}
        layout="vertical"
        name="register_form"
        initialValues={{
          modifier: 'public',
          email: state?.email ? state?.email.trim() : undefined,
        }}
      >
        <p>Se le solicita que constituya un domicilio electronico</p>
        <InputsRow style={{ margin: '1rem 0' }}>
          <Space>
            <Form.Item
              name="email"
              label={
                <div>
                  Ingresar domicilio electrónico
                  <Tooltip title="ejemplo@correo.electronico">
                    <InfoCircleOutlined
                      className="black"
                      style={{ cursor: 'help', marginLeft: '0.25rem' }}
                    />
                  </Tooltip>
                </div>
              }
              rules={[
                { type: 'email', message: 'Ingrese un email válido' },
                { required: true, message: t('globals.fields.required') },
              ]}
            >
              <Input placeholder="Ingrese su email" />
            </Form.Item>
          </Space>
        </InputsRow>
        <InputsRow>
          <Space>
            <Form.Item
              name="enableNotifications"
              label="¿Permite al RENATRE enviar información y notificaciones a este domicilio electrónico?"
              rules={[{ required: true, message: t('globals.fields.required') }]}
            >
              <Radio.Group
                options={[
                  { label: 'No', value: false },
                  { label: 'Sí', value: true },
                ]}
                value={enableNotifications}
                onChange={({ target }) => setEnableNotifications(target.value)}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </Space>
        </InputsRow>
      </Form>
    </StandardModal>
  )
}

export default RegisterModal

import { RefreshToken, UnregisteredPayload, User } from '@types'

import { api, setAuthHeader } from './axios'

interface Payload {
  email: string
  password: string
}

interface Response {
  data: User
}
interface RecoverPassResponse {
  username: User
}

interface RecoverPasswordPayload {
  username: string
}
interface ChangePasswordPayload {
  password: string
  token: string
}

interface GetUserResponse {
  user: User
}

const login = async (payload: Payload) => {
  const body = { ...payload }
  const response = await api.post<Response>('/authentication', body)

  return response.data
}

const refreshToken = async (payload: RefreshToken) => {
  const response = await api.post('/authentication/refresh', payload)

  return response.data
}

const recoverPass = async (payload: RecoverPasswordPayload) => {
  const response = await api.post<RecoverPassResponse>('/auth/password', payload)

  return response.data
}

const changePassword = ({ password, token }: ChangePasswordPayload) => {
  setAuthHeader(token)
  return api.put('/auth/password', { password })
}

const saveFirebaseToken = async (id: number, token: string) => {
  const response = await api.put<GetUserResponse>(`/users/${id}/firebaseToken`, { token })

  return response.data
}

const unregisteredEmployerLogin = async (payload: UnregisteredPayload) => {
  const body = { ...payload }
  const response = await api.post<Response>('/login-unregistered-employer', body)

  return response.data
}

export const AuthApi = {
  login,
  refreshToken,
  recoverPass,
  changePassword,
  saveFirebaseToken,
  unregisteredEmployerLogin,
}

/* eslint-disable camelcase */
import { Moment } from 'moment'

import {
  Attachment,
  AttachmentType,
  AuxiliarInspector,
  ComboType,
  ComboWithRequiresDetailType,
  CompleteWorkerDataInterface,
  DataUploadHistoryType,
  ElectronicAddress,
  Employer,
  EmployerSurveys,
  ExpedientType,
  FileAttachPayloadType,
  FileAttachType,
  FilterBulkUploadType,
  FilterContextType,
  Georeference,
  InspectorNoteType,
  InspectorsType,
  MediaTypes,
  RecordTypeEnum,
  SearchParams,
  SurveyType,
} from '@types'

import { api } from './axios'

// INSPECTORS ------------------------------------

interface GetInspectorsResponse {
  data: InspectorsType[]
  meta: {
    total_records: number
    total_pages: number
    current_page: number
  }
}

const getInspectors = async (filters?: FilterContextType) => {
  const params = {
    limit: filters?.filters?.limit,
    page: filters?.filters?.page,
    name: filters?.filters?.name,
    lastname: filters?.filters?.lastname,
    file: filters?.filters?.file,
    delegationId: filters?.filters?.delegationId,
    orderBy: filters?.orderBy?.field,
    order: filters?.orderBy?.order,
  }
  const response = await api.get<GetInspectorsResponse>('/inspectors?', { params })

  return response.data
}

const syncInspectors = async () => {
  const response = await api.post('/sync-inspectors')
  return response.data
}

const addInspector = async (payload: InspectorsType) => {
  const body = { ...payload }

  const response = await api.post<Response>('/inspector', body)

  return response.data
}

interface GetInspectorsComboResponse {
  data: ComboType[]
}

const getInspectorsCombo = async () => {
  const response = await api.get<GetInspectorsComboResponse>('/inspectors-combo')

  return response.data
}

// ORDERS ------------------------------------

interface GetOrdersResponse {
  data: RegisteredOrderType[]
  meta: {
    total_records: number
    total_pages: number
    current_page: number
  }
}

interface GetOrderResponse {
  data: RegisteredOrderType
}

export interface OrdersType {
  priority?: string
  inspectionDate?: Moment

  employerCuit?: string
  bussinessName?: string

  georeferences?: Georeference[]
  establishmentName?: string
  streetType?: string
  streetName?: string
  streetNumber?: string
  provinceId?: number
  departmentId?: number
  localityId?: number
  place?: string
  delegationId?: number
  activityId?: number
  latitude?: number
  longitude?: number

  additionalInformation?: string

  causes?: number[]
  otherCause?: string
  origin?: number
  otherOrigin?: string
  isEmployerRegistered: boolean | undefined
  employeesQuantity?: number

  inspectorsIds?: number[]
}

export interface BulkDataUploadType {
  name: string
  data: unknown
}

export interface RegisteredOrderType extends OrdersType {
  id: number
  department?: string
  locality?: string
  province?: string
  responsibleInspectorId?: number
  auxiliarInspectorsIds?: number[]
  toInspect: boolean
  editable: boolean
  createdAt: Moment
  [key: string]: unknown
}

export interface CloseOrderType {
  id?: number
  closeCauses?: number
  details?: string
}

const getOrders = async (filters?: FilterContextType) => {
  let dates = {}
  if (filters?.filters?.dateRange) {
    dates = {
      dateFrom: filters?.filters?.dateRange[0].format('DD-MM-YYYY'),
      dateTo: filters?.filters?.dateRange[1].format('DD-MM-YYYY'),
    }
  }
  const params = {
    cuit: filters?.filters?.cuit,
    priority: filters?.filters?.priority,
    delegationId: filters?.filters?.delegationId,
    limit: filters?.filters?.limit,
    orderBy: filters?.orderBy?.field,
    order: filters?.orderBy?.order,
    ...(dates || {}),
    page: filters?.filters?.page,
  }
  const response = await api.get<GetOrdersResponse>('/inspection-order', { params })

  return response.data
}

const getOrder = async (id?: number) => {
  const response = await api.get<GetOrderResponse>(`/inspection-order/${id}`)

  return response.data
}

export interface AddOrderResponse {
  data?: {
    id: number
  }
  code?: string
  message?: string
}
export interface BulkDataUploadResponse {
  data?: {
    id: number
    status: string
  }
  code?: string
  message?: string
}

export interface GetBulkDataHistoryResponse {
  data: {
    data: DataUploadHistoryType[]
    meta: {
      current_page: number
      total_pages: number
      total_records: number
    }
  }
  status: number
  page: number
  total: number
}

const addOrder = async (payload: OrdersType) => {
  const body = { ...payload }

  const response = await api.post<AddOrderResponse>('/inspection-order', body)

  return response.data
}

const closeOrder = async (payload: CloseOrderType) => {
  const body = { ...payload }

  const response = await api.put<AddOrderResponse>(`/inspection-order/${body.id}/close`, body)

  return response.data
}

const updateOrder = async (payload: RegisteredOrderType) => {
  const body = { ...payload }

  const response = await api.put<Response>(`/inspection-order/${body.id}`, body)

  return response.data
}

const uploadBulkData = async (payload: BulkDataUploadType) => {
  const body = { ...payload }

  const response = await api.post<BulkDataUploadResponse>('/inspection-order-bulk-load', body)

  return { data: response.data, file: payload.name }
}

const getBulkDataHistory = async (filters?: FilterBulkUploadType) => {
  const params = {
    name: filters?.filters?.name,
    id: filters?.filters?.id,
    createdAt: filters?.filters?.createdAt,
    state: filters?.filters?.state,
    limit: filters?.filters?.limit,
    orderBy: filters?.orderBy?.field,
    order: filters?.orderBy?.order,
    page: filters?.filters?.page,
  }
  const response = await api.get<GetBulkDataHistoryResponse>('/inspection-order-bulk-load-search', {
    params,
  })

  return response.data
}

// HISTORY ------------------------------

interface OrderHistory {
  cause: string
  date: string
  ioState: string
  responsibleName: string
  role: string
  comment: string
}
type GetHistoryResponse = {
  data: OrderHistory[]
}

const getHistory = async (id?: number) => {
  const response = await api.get<GetHistoryResponse>(`/inspection-order/${id}/status-changes`)

  return response.data
}

// PRIORITIES ------------------------------

interface GetPrioritiesResponse {
  data: {
    values: PriorityType[]
  }
}

export interface PriorityType {
  id: string
  name: string
}

const getPriorities = async () => {
  const response = await api.get<GetPrioritiesResponse>('/priorities')

  return response.data
}

// DEPARTMENTS ------------------------------

interface GetDepartmentsResponse {
  data: DepartmentType[]
}

export interface DepartmentType {
  id: number
  name: string
  provinceId: number
}

const getDepartments = async (provinceId: number) => {
  if (provinceId) {
    const response = await api.get<GetDepartmentsResponse>(`/provinces/${provinceId}/departments`)
    return response.data
  }
  return { data: [] }
}

// DELEGATIONS ------------------------------

interface GetDelegationsComboResponse {
  data: ComboType[]
}

const getDelegationsCombo = async () => {
  const response = await api.get<GetDelegationsComboResponse>('/delegations-combo')

  return response.data
}

// STREET_TYPES ------------------------------

interface GetStreetTypesResponse {
  data: ComboType[]
}

const getStreetTypes = async () => {
  const response = await api.get<GetStreetTypesResponse>('/street-types')

  return response.data
}

// CAUSES ------------------------------

interface GetCausesResponse {
  data: ComboWithRequiresDetailType[]
}

const getCauses = async () => {
  const response = await api.get<GetCausesResponse>('/reasons')

  return response.data
}

// CLOSE CAUSES ------------------------------

interface GetCloseCausesResponse {
  data: ComboType[]
}

const getCloseCauses = async () => {
  const response = await api.get<GetCloseCausesResponse>('/close-reasons')

  return response.data
}
// ACTIVITIES ------------------------------

interface GetActivitiesResponse {
  data: ActivityType[]
}

export interface ActivityType {
  id: number
  name: string
}

const getActivities = async () => {
  const response = await api.get<GetActivitiesResponse>('/activities')

  return response.data
}

// PROVINCES ------------------------------

interface GetProvincesResponse {
  data: ProvinceType[]
}

export interface ProvinceType {
  provinceId: number
  name: string
}

const getProvinces = async () => {
  const response = await api.get<GetProvincesResponse>('/provinces')

  return response.data
}

// LOCALITIES ------------------------------

interface GetLocalitiesResponse {
  data: LocalityType[]
}

interface GetLocalityResponse {
  data: LocalityType
}

export interface LocalityType {
  id: number
  name: string
  provinceId: number
  departmentId: number
}

// eslint-disable-next-line consistent-return
const getLocalities = async (departmentId: number) => {
  if (departmentId) {
    const response = await api.get<GetLocalitiesResponse>(`/departments/${departmentId}/localities`)

    return response.data
  }
}
// eslint-disable-next-line consistent-return
const getLocality = async (localityId: number) => {
  if (localityId) {
    const response = await api.get<GetLocalityResponse>(`/locality/${localityId}`)
    return response.data
  }
}

// ORIGINS ------------------------------

type GetOriginsResponse = {
  data: ComboWithRequiresDetailType[]
}

const getOrigins = async () => {
  const response = await api.get<GetOriginsResponse>('/origins')

  return response.data
}

// SEARCH ITEM ------------------------------

type SearchItemComboResponse = {
  data: LocalityType[] | ComboType[]
}

const searchItem = async (params: SearchParams) => {
  const response = await api.get<SearchItemComboResponse>(
    `/${params.endpointCategory}/${params.value}`,
  )

  return response.data
}

// SURVEYS ------------------------------

interface GetSurveyResponse {
  data: SurveyType
}
interface GetSurveyWorkerResponse {
  data: CompleteWorkerDataInterface
}
interface GetSurveysResponse {
  data: SurveyType[] | []
  meta: {
    total_records: number
    total_pages: number
    current_page: number
  }
}

type GetSurveyNotesResponse = {
  data: InspectorNoteType[]
}

type GetSurveyInspectorsResponse = {
  data: {
    responsibleFullname: string
    responsibleFile: string
    inspectors: AuxiliarInspector[]
  }
}

const getSurvey = async (id?: number) => {
  const response = await api.get<GetSurveyResponse>(`/surveys/${id}/employer`)

  return response.data
}

const getSurveys = async (filters?: FilterContextType) => {
  let dates = {}
  if (filters?.filters?.dateRange) {
    dates = {
      dateFrom: filters?.filters?.dateRange[0].format('DD-MM-YYYY'),
      dateTo: filters?.filters?.dateRange[1].format('DD-MM-YYYY'),
    }
  }
  const params = {
    surveyId: filters?.filters?.surveyId,
    provisionalId: filters?.filters?.provisionalId,
    orderId: filters?.filters?.orderId,
    cuit: filters?.filters?.cuit,
    responsibleInspector: filters?.filters?.responsibleInspector,
    limit: filters?.filters?.limit,
    orderBy: filters?.orderBy?.field,
    order: filters?.orderBy?.order,
    ...(dates || {}),
    page: filters?.filters?.page,
  }
  const response = await api.get<GetSurveysResponse>('/surveys', { params })

  return response.data
}

const getSurveyNotes = async (surveyId: number) => {
  const response = await api.get<GetSurveyNotesResponse>(`/notes/${surveyId}`)

  return response.data
}

const getSurveyInspectors = async (surveyId: number) => {
  const response = await api.get<GetSurveyInspectorsResponse>(`/surveys/${surveyId}/inspectors`)

  return response.data
}

const getSurveyWorker = async (surveyId: number, workerId: number) => {
  const response = await api.get<GetSurveyWorkerResponse>(
    `/surveys/${surveyId}/workers/${workerId}`,
  )

  return response.data
}

// WORKER ------------------------------

export interface WorkersType {
  id: number
  documentNumber: number
  lastname: string
  name: string
  age: number
  gender: string
  birthLocation: string
  [key: string]: unknown
}

interface GetWorkerResponse {
  data: WorkersType
}

interface GetWorkersResponse {
  data: WorkersType[]
  meta: {
    total_records: number
    total_pages: number
    current_page: number
  }
}

const getWorker = async (id?: number) => {
  const response = await api.get<GetWorkerResponse>(`/worker/${id}`)

  return response.data
}

const getWorkers = async (surveyId: number, filtersAux?: FilterContextType) => {
  const params = {
    lastname: filtersAux?.filters?.lastname,
    document: filtersAux?.filters?.document,
    ageFrom: filtersAux?.filters?.ageFrom,
    ageTo: filtersAux?.filters?.ageTo,
    limit: filtersAux?.filters?.limit,
    orderBy: filtersAux?.orderBy?.field,
    order: filtersAux?.orderBy?.order,
    page: filtersAux?.filters?.page,
  }
  const response = await api.get<GetWorkersResponse>(`/surveys/${surveyId}/workers`, { params })

  return response.data
}

// MEDIA ------------------------------

interface GetMediaResponse {
  data: Attachment[]
}

const getMedia = async (id?: number) => {
  const response = await api.get<GetMediaResponse>(`/attachments-photos/${id}`)

  const videoThumbnail = '/images/videoThumbnail.png'

  const videoFormats = ['mp4', 'avi', 'mov', 'mkv', 'wmv', 'flv', 'webm']

  const mediaFiles = await Promise.all(
    response.data.data.map(async (item: Attachment) => {
      const downloadedFile = await downloadAttachment(item)
      const url = window.URL.createObjectURL(downloadedFile.data)
      if (videoFormats.includes(item.ext || '')) {
        return {
          format: MediaTypes.VIDEO,
          original: url,
          thumbnail: videoThumbnail,
        }
      }
      return {
        format: MediaTypes.IMAGE,
        original: url,
        thumbnail: url,
      }
    }),
  )

  return mediaFiles
}

// ATTACHMENTS ------------------------------

export interface FileActionResponse {
  data?: string | FileAttachType
  code?: string
  message?: string
}

interface GetAttachmentsResponse {
  data: Attachment[]
}

const getAttachments = async (surveyId?: number) => {
  const response = await api.get<GetAttachmentsResponse>(`/attachments/${surveyId}`)

  const allowedImageExtensions = ['jpg', 'png']
  const attachmentsFiles = await Promise.all(
    response.data.data.map(async (item: Attachment) => {
      if (item.ext && allowedImageExtensions.includes(item.ext)) {
        const downloadedFile = await downloadAttachment(item)
        const url = window.URL.createObjectURL(downloadedFile.data)
        const result = {
          id: item.id,
          name: item.original_filename,
          size: item.size,
          thumbnailUrl: url,
        }
        return result
      }
      const result = {
        id: item.id,
        name: item.original_filename,
        size: item.size,
      }
      return result
    }),
  )

  return attachmentsFiles
}

const attachFileData = async (payload: FileAttachPayloadType) => {
  const body = { ...payload.data }

  const response = await api.post<FileActionResponse>(
    `/attachments/${payload.surveyId}/upload`,
    body,
  )

  return { data: response.data, file: payload.data.name }
}

const deleteAttachment = async (attachment: AttachmentType) => {
  const response = await api.delete<FileActionResponse>(`/attachments/${attachment.id}`)

  return { data: response.data, file: attachment.filename }
}

const downloadAttachment = async (attachment: AttachmentType | Attachment) => {
  const response = await api.get(`/attachments/${attachment.id}/download`, {
    responseType: 'blob',
  })

  return { data: response.data, filename: attachment.filename }
}

// ------------------------------

// EMPLOYER CUIT ------------------------------

interface GetEmployerResponse {
  data: {
    employer: Employer
  }
}

interface GetEmployerSurveysResponse {
  data: {
    employer: EmployerSurveys
  }
}

const getEmployer = async (cuit?: string) => {
  const response = await api.get<GetEmployerResponse>(`/employer/${cuit}`)

  return { data: response.data }
}

const getEmployerSurveys = async (cuit?: string) => {
  const response = await api.get<GetEmployerSurveysResponse>(`/employer-surveys/${cuit}`)

  return { data: response.data }
}

// EXPEDIENTS ------------------------------------

interface GetExpedientsResponse {
  data: ExpedientType[]
  meta: {
    total_records: number
    total_pages: number
    current_page: number
  }
}

interface GetExpedientResponse {
  data: ExpedientType
}

export interface AddExpedientResponse {
  data?: {
    id: number
  }
  code?: string
  message?: string
}

const getExpedients = async (filters?: FilterContextType) => {
  let dates = {}
  if (filters?.filters?.dateRange) {
    dates = {
      dateFrom: filters?.filters?.dateRange[0].format('DD-MM-YYYY'),
      dateTo: filters?.filters?.dateRange[1].format('DD-MM-YYYY'),
    }
  }
  const params = {
    cuit: filters?.filters?.cuit,
    priority: filters?.filters?.priority,
    delegationId: filters?.filters?.delegationId,
    limit: filters?.filters?.limit,
    orderBy: filters?.orderBy?.field,
    order: filters?.orderBy?.order,
    ...(dates || {}),
    page: filters?.filters?.page,
  }
  const response = await api.get<GetExpedientsResponse>('/expedient', { params })

  return response.data
}

const getExpedient = async (id?: number) => {
  const response = await api.get<GetExpedientResponse>(`/expedient/${id}`)

  return response.data
}

const addExpedient = async (payload: ExpedientType) => {
  const body = { ...payload }

  const response = await api.post<AddExpedientResponse>('/expedient', body)

  return response.data
}

const updateExpedient = async (payload: ExpedientType) => {
  const body = { ...payload }

  const response = await api.put<Response>(`/expedient/${body.id}`, body)

  return response.data
}

// ELECTRONIC ADDRESS ------------------------------------

interface GetElectronicAddressResponse {
  data: ElectronicAddress
}

const postElectronicAddress = async (payload: ElectronicAddress) => {
  await api.post('/employer-electronic-address', payload)
}

const getElectronicAddress = async () => {
  const response = await api.get<GetElectronicAddressResponse>('/employer-electronic-address')

  return response.data.data
}

// PDF RECORDS ------------------------------

const getPdfRecord = async (surveyId: number, recordTypeAux: RecordTypeEnum) => {
  const response = await api.get(`digital-sign/download/${recordTypeAux}/surveyId/${surveyId}`, {
    responseType: 'blob',
  })

  const objectUrl = window.URL.createObjectURL(response.data)

  return objectUrl
}

// ------------------------------------

export const DataApi = {
  getInspectors,
  addInspector,
  getOrders,
  getOrder,
  addOrder,
  closeOrder,
  updateOrder,
  getPriorities,
  getActivities,
  getProvinces,
  getLocalities,
  getLocality,
  getDepartments,
  getDelegationsCombo,
  getOrigins,
  getCauses,
  getStreetTypes,
  getInspectorsCombo,
  getHistory,
  searchItem,
  getCloseCauses,
  getSurvey,
  getSurveyNotes,
  getWorker,
  getSurveys,
  getWorkers,
  getMedia,
  syncInspectors,
  uploadBulkData,
  getBulkDataHistory,
  attachFileData,
  getAttachments,
  deleteAttachment,
  downloadAttachment,
  getEmployer,
  getSurveyInspectors,
  getSurveyWorker,
  getExpedients,
  getExpedient,
  updateExpedient,
  addExpedient,
  getEmployerSurveys,
  postElectronicAddress,
  getPdfRecord,
  getElectronicAddress,
}

import { useQuery } from 'react-query'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useGetEmployer = (cuit?: string) => {
  const { t } = useTranslation()
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.EMPLOYER, cuit],
    () => {
      return DataApi.getEmployer(cuit)
    },
    {
      enabled: !!cuit,
      staleTime: Infinity,
      onError: () => {
        notification.error({
          message: t('error.employer_not_registered'),
          key: 'employer_not_registered_error',
        })
      },
    },
  )
  return { employer: data?.data.data.employer, employerIsLoading: isLoading }
}

export { useGetEmployer }

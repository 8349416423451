/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useContext } from 'react'

type ContextState = {
  activeSurveyId: number
  setActiveSurveyId: (newstate: number) => void
}

const initialState = {
  activeSurveyId: 0,
  setActiveSurveyId: () => {},
}

const AuxiliarsContext = React.createContext<ContextState>(initialState)

export const AuxiliarsProvider: React.FC = ({ children }) => {
  const [activeSurveyId, setActiveSurveyId] = useState<number>(initialState.activeSurveyId)
  return (
    <AuxiliarsContext.Provider value={{ activeSurveyId, setActiveSurveyId }}>
      {children}
    </AuxiliarsContext.Provider>
  )
}

export function useAuxiliars(): ContextState {
  const context = useContext(AuxiliarsContext)
  if (!context) throw Error('This hook can only be used under the FilterProvider component')
  return context
}

import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useGetInspectorsCombo = () => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.INSPECTORS_COMBO],
    () => {
      return DataApi.getInspectorsCombo()
    },
    { staleTime: Infinity },
  )
  return { inspectors: data?.data, isLoading }
}

export { useGetInspectorsCombo }

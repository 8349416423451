import {
  CommentOutlined,
  CopyFilled,
  EyeOutlined,
  PaperClipOutlined,
  RollbackOutlined,
  SolutionOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import { Space, Tooltip, Menu, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { useAuxiliars, useFilters, useUser } from '@contexts'
import { LevelEnum, RecordTypeEnum, SurveyType, UrlSignParams } from '@types'
import { useGetSurveys, useGetWorkers } from '@hooks'
import { QUERY_KEYS } from '@constants'
import { OtherUtils } from '@utils'
import { ColumnTitleRender } from '@components'

import { WorkersType } from '../../../api/data'
import {
  CardWrapper,
  CardWrapperHeader,
  PageName,
  Options,
  CardWrapperTable,
  OptionsWrapper,
  BackButton,
  StyledDropdown,
  StatusLed,
} from '../components'
import SeeSurveyModal from './components/SeeSurveyModal'
import SurveysFilters from './components/SurveysFilters'
import SeeWorkerModal from './components/SeeWorkerModal'
import SeeInspectorsModal from './components/SeeInspectorsModal'
import NotesSurveyModal from './components/NotesSurveyModal'
import MediaSurveyModal from './components/MediaSurveyModal'
import SeePdfRecordModal from './components/SeePdfRecordModal'

const Surveys = () => {
  const { state } = useUser()
  const { pathname, search } = useLocation()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { filters, setFilters, filtersAux, setFiltersAux } = useFilters()
  const [surveyModalIsVisible, setSurveyModalIsVisible] = useState(false)
  const [workerModalIsVisible, setWorkerModalIsVisible] = useState(false)
  const [recordModalIsVisible, setRecordModalIsVisible] = useState(false)
  const [inspectorsModalIsVisible, setInspectorsModalIsVisible] = useState(false)
  const [auxOrderId, setAuxOrderId] = useState<string>()
  const [auxDate, setAuxDate] = useState<string>()
  const fieldsPrefix = 'globals.fields.'
  const [surveyId, setSurveyId] = useState<number>(0)
  const [workerId, setWorkerId] = useState<number>(0)
  const { surveys, surveysIsLoading } = useGetSurveys(filters)
  const { workers, workersIsLoading } = useGetWorkers(filtersAux, surveyId, state?.level)
  const [workerObject, setWorkerObject] = useState<WorkersType>()
  const [notesIsVisible, setNotesIsVisible] = useState(false)
  const [mediaIsVisible, setMediaIsVisible] = useState(false)
  const { activeSurveyId, setActiveSurveyId } = useAuxiliars()
  const [recordTypeAux, setRecordTypeAux] = useState<RecordTypeEnum | undefined>(undefined)
  const [signParams, setSignParams] = useState<UrlSignParams | undefined>(undefined)

  const allTableActions = (item: SurveyType) => (
    <Space size="middle">
      <Tooltip title={t('globals.tooltips.see')}>
        <EyeOutlined
          onClick={() => {
            handleSeeSurvey(item)
          }}
        />
      </Tooltip>
      <StyledDropdown overlay={recordOptionMenu(item)}>
        <CopyFilled />
      </StyledDropdown>
      <Tooltip title={t('globals.tooltips.workers_list')}>
        <SolutionOutlined
          onClick={() => {
            setSurveyId(item.id)
            setAuxOrderId(item.inspectionOrderId)
            handleShowedList()
            setAuxDate(item.startDate)
          }}
        />
      </Tooltip>
      <Tooltip title={t('globals.sections.inspectors.name')}>
        <TeamOutlined
          onClick={() => {
            queryClient.invalidateQueries(QUERY_KEYS.SURVEY_INSPECTORS)
            setActiveSurveyId(item.id)
            setInspectorsModalIsVisible(true)
          }}
        />
      </Tooltip>
      <Tooltip title={t('globals.tooltips.notes')}>
        <CommentOutlined
          onClick={() => {
            queryClient.invalidateQueries(QUERY_KEYS.SURVEY_NOTES)
            setAuxDate(item.startDate)
            setActiveSurveyId(item.id)
            setNotesIsVisible(true)
          }}
        />
      </Tooltip>
      <Tooltip title={t('globals.tooltips.attached')}>
        <PaperClipOutlined
          onClick={() => {
            setActiveSurveyId(item.id)
            setAuxOrderId(item.inspectionOrderId)
            setAuxDate(item.startDate)
            setMediaIsVisible(true)
          }}
        />
      </Tooltip>
    </Space>
  )

  const employerActions = (item: SurveyType) => (
    <Space size="middle">
      <StyledDropdown overlay={recordOptionMenu(item)}>
        <CopyFilled />
      </StyledDropdown>
    </Space>
  )

  const actionsByRole = {
    ADMIN: allTableActions,
    INSPECTOR: allTableActions,
    EMPLOYER: employerActions,
    EMPLOYER_NOT_REGISTERED: employerActions,
  }

  const notAllowedRolesToSurveyAccess = [LevelEnum.EMPLOYER, LevelEnum.EMPLOYER_NOT_REGISTERED]

  OtherUtils.handleLastLocation(pathname)

  useEffect(() => {
    if (surveyId > 0 && !listingSurveys) {
      queryClient.refetchQueries(QUERY_KEYS.WORKERS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId])

  useEffect(() => {
    // Para abrir la ventana en donde se encontraba el
    // inspector al momento de obtener certificados o firmar,
    // cuando se inicializa el componente =>
    if (search /* "?parametrosGet" */) {
      // Si llegan los parametros GET =>
      // Se inicializa un objeto con las propiedades que deben guardarse en el estado signParams.
      const params: UrlSignParams = {
        certificates: undefined,
        itemType: undefined,
        itemId: undefined,
        signed: undefined,
      }
      // A continuacion se fragmentan los parametros GET.
      // Por motivos de programacion backend en vez de separar por '&' se separan los parametros por '~'.
      search
        .slice(1) // Quita el '?' con el que comienza el string de search.
        .split('~') // Separa parametros y convierte search en un array.
        .map(param => param.split('=')) // Convierte los strings en array con la primer posicion [0] como key y la segunda posicion [1] como value.
        .forEach(([key, value]) => {
          // Por cada par key=value se reemplaza en el objeto creado anteriormente el valor correspondiente.
          params[key] =
            key === 'certificates' // Si la iteración del momento es con la key de los certificados,
              ? value
                  .split(',') // el contenido se convierte en array con .split(',') .
                  .map(v => decodeURI(v))
                  .filter(cert => cert)
              : value
        })

      // Finalmente se setea signParams con los valores que llegan de url.
      setSignParams(params)
      // Cuando finaliza el seteo del estado signParams,
      // se ejecuta el useEffect con dependencia [signParams] =>
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (signParams) {
      // Si al cambiar el estado efectivamente tiene contenido =>
      const { certificates, itemType, itemId, signed } = signParams
      if (itemType && itemId) {
        if (Object.values(RecordTypeEnum).includes(itemType as RecordTypeEnum)) {
          handleSeeRecord(parseInt(itemId, 10), itemType)
        }
        if (certificates && certificates.length > 0) {
          notification.success({
            message: t('globals.sections.records.survey.pdf.certificates.success'),
          })
        } else {
          notification.error({
            message: t('globals.sections.records.survey.pdf.certificates.error'),
          })
        }
        if (signed !== undefined) {
          if (signed) {
            notification.success({
              message: t('globals.sections.records.survey.pdf.signed.success'),
            })
          } else {
            notification.error({
              message: t('globals.sections.records.survey.pdf.signed.error'),
            })
          }
        }
      } else {
        setSignParams(undefined)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signParams])

  const handleSeeSurvey = (item: SurveyType) => {
    setSurveyModalIsVisible(true)
    setSurveyId(item.id)
  }

  const handleSeeRecord = (itemId: number, itemType: string) => {
    setRecordTypeAux(itemType as RecordTypeEnum)
    setRecordModalIsVisible(true)
    setSurveyId(itemId)
  }

  const handleSeeWorker = (item: WorkersType) => {
    setWorkerModalIsVisible(true)
    setWorkerId(item.id)
    setWorkerObject(item)
  }

  // Creating specific record type options for survey item
  const createRecordOptions = (recordTypes: string[]) => {
    const recordOptions = recordTypes.map((recordType: string, index: number) => {
      const toReturnRecordType = {
        key: index + 1,
        label: t(`globals.sections.records.survey.modal.${recordType.toLowerCase()}.option`),
        action: (itemSurveyId: number) => {
          queryClient.removeQueries(QUERY_KEYS.PDF_RECORD)
          queryClient.refetchQueries(QUERY_KEYS.PDF_RECORD)
          handleSeeRecord(itemSurveyId, recordType)
        },
      }
      return toReturnRecordType
    })
    return recordOptions
  }

  const recordOptionMenu = (item: SurveyType) => {
    const options = createRecordOptions(item.recordTypes)
    return (
      <Menu key={item.id}>
        {options.map(option => {
          return (
            <Menu.Item
              key={option.key}
              onClick={() => {
                option.action(item.id)
              }}
            >
              {option.label}
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }

  const surveysColumns = [
    {
      title: ColumnTitleRender({ tKey: 'survey_number', key: 'id', sortable: true }),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: ColumnTitleRender({
        tKey: 'provisory_survey_number',
        key: 'provisionalId',
        sortable: true,
      }),
      dataIndex: 'provisionalId',
      key: 'provisionalId',
    },
    {
      title: ColumnTitleRender({
        tKey: 'inspection_order',
        key: 'inspectionOrderId',
        sortable: true,
        tooltip: true,
      }),
      dataIndex: 'inspectionOrderId',
      key: 'inspectionOrderId',
    },
    {
      title: ColumnTitleRender({ tKey: 'start_date', key: 'startDate', sortable: true }),
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: ColumnTitleRender({ tKey: 'workers_quantity', key: 'workersQuantity', tooltip: true }),
      dataIndex: 'workersQuantity',
      key: 'workersQuantity',
    },
    {
      title: ColumnTitleRender({ tKey: 'cuit', key: 'cuit', sortable: true }),
      dataIndex: 'cuit',
      key: 'cuit',
    },
    {
      title: ColumnTitleRender({ tKey: 'establishment', key: 'establishment', sortable: true }),
      dataIndex: 'establishment',
      key: 'establishment',
    },
    {
      title: ColumnTitleRender({ tKey: 'exp_contribucion', key: 'expContribucion', tooltip: true }),
      dataIndex: 'expContribution',
      key: 'expContribution',
    },
    {
      title: ColumnTitleRender({ tKey: 'exp_infraction', key: 'expInfraction', tooltip: true }),
      dataIndex: 'expInfraction',
      key: 'expInfraction',
    },
    {
      title: ColumnTitleRender({ tKey: 'synchronization_status', key: 'status', tooltip: true }),
      key: 'status',
      render: (item: SurveyType) => (
        <Space size="middle">
          <Tooltip
            title={t(`globals.tooltips.status.${item.status.toLowerCase()}`)}
            placement="left"
          >
            <StatusLed className={item.status} />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: ColumnTitleRender({ tKey: 'presents', key: 'presents' }),
      dataIndex: 'presents',
      key: 'presents',
    },
    {
      title: t(`${fieldsPrefix}actions.title`),
      render: state?.level ? actionsByRole[state?.level] : undefined,
    },
  ]

  const workersColumns = [
    {
      title: ColumnTitleRender({ tKey: 'national_document', key: 'document' }),
      dataIndex: 'document',
      key: 'document',
    },
    {
      title: ColumnTitleRender({ tKey: 'lastname', key: 'lastname' }),
      dataIndex: 'lastname',
      key: 'lastname',
    },
    {
      title: ColumnTitleRender({ tKey: 'name', key: 'firstname' }),
      dataIndex: 'firstname',
      key: 'firstname',
    },
    {
      title: ColumnTitleRender({ tKey: 'age', key: 'age' }),
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: ColumnTitleRender({ tKey: 'gender', key: 'gender' }),
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: ColumnTitleRender({ tKey: 'nationality', key: 'nationality' }),
      dataIndex: 'nationality',
      key: 'nationality',
    },
    {
      title: ColumnTitleRender({ tKey: 'inspector', key: 'inspector' }),
      dataIndex: 'inspector',
      key: 'inspector',
    },
    {
      title: ColumnTitleRender({ tKey: 'presents', key: 'presents' }),
      dataIndex: 'presents',
      key: 'presents',
    },
    {
      title: t(`${fieldsPrefix}actions.title`),
      render: (item: WorkersType) => (
        <Tooltip title={t('globals.tooltips.see')}>
          <EyeOutlined
            onClick={() => {
              handleSeeWorker(item)
            }}
          />
        </Tooltip>
      ),
      key: 'id',
    },
  ]

  const handleShowedList = () => {
    setListingSurveys(!listingSurveys)
  }

  const [listingSurveys, setListingSurveys] = useState(true)

  useEffect(() => {
    if (listingSurveys) {
      setSurveyId(0)
    }
  }, [listingSurveys])

  const handleCloseSeeRecordModal = () => {
    queryClient.removeQueries(QUERY_KEYS.PDF_RECORD)
    setSignParams(undefined)
    setRecordModalIsVisible(false)
    setAuxOrderId(undefined)
    setTimeout(() => {
      setSurveyId(0)
    }, 300)
  }

  return (
    <CardWrapper>
      <CardWrapperHeader>
        <PageName>
          <h1>
            {t('globals.sections.surveys.name')} -{' '}
            {listingSurveys ? (
              `${t('globals.general_list')}`
            ) : (
              <>
                <SolutionOutlined /> {t('globals.sections.surveys.list_of_workers')} {surveyId}
              </>
            )}
          </h1>
          {!listingSurveys && (
            <BackButton
              type="link"
              size="small"
              onClick={() => {
                queryClient.removeQueries(QUERY_KEYS.WORKERS)
                handleShowedList()
                setAuxOrderId(undefined)
                setSurveyId(0)
                setAuxDate(undefined)
              }}
            >
              <RollbackOutlined />
              Volver al listado general
            </BackButton>
          )}
        </PageName>
        {listingSurveys && (
          <Options>
            <OptionsWrapper>
              <SurveysFilters />
            </OptionsWrapper>
          </Options>
        )}
      </CardWrapperHeader>
      {listingSurveys && surveyModalIsVisible && surveyId > 0 && (
        <SeeSurveyModal
          surveyId={surveyId}
          modalTitle={t('globals.sections.surveys.modal.title')}
          visible={surveyModalIsVisible}
          close={() => {
            setSurveyModalIsVisible(false)
            setAuxOrderId(undefined)
            setTimeout(() => {
              setSurveyId(0)
            }, 300)
          }}
        />
      )}
      {listingSurveys && recordTypeAux && recordModalIsVisible && surveyId > 0 ? (
        <SeePdfRecordModal
          surveys={surveys?.data}
          surveyId={surveyId}
          modalTitle={t('globals.sections.records.survey.modal.title')}
          visible={recordModalIsVisible}
          recordTypeAux={recordTypeAux}
          signParams={signParams}
          close={handleCloseSeeRecordModal}
        />
      ) : (
        ''
      )}
      <NotesSurveyModal
        isVisible={notesIsVisible}
        onClose={() => {
          setTimeout(() => setActiveSurveyId(0), 300)
          setNotesIsVisible(false)
          setAuxOrderId(undefined)
          setAuxDate(undefined)
        }}
      />
      {listingSurveys && mediaIsVisible && activeSurveyId > 0 ? (
        <MediaSurveyModal
          orderId={auxOrderId}
          isVisible={mediaIsVisible}
          onClose={() => {
            queryClient.removeQueries([QUERY_KEYS.SURVEY_ATTACHED_FILES, QUERY_KEYS.MEDIA])
            setTimeout(() => setActiveSurveyId(0), 300)
            setMediaIsVisible(false)
            setAuxOrderId(undefined)
            setAuxDate(undefined)
          }}
        />
      ) : (
        ''
      )}
      {listingSurveys && inspectorsModalIsVisible && activeSurveyId > 0 && (
        <SeeInspectorsModal
          visible={inspectorsModalIsVisible}
          close={() => {
            setTimeout(() => setActiveSurveyId(0), 300)
            setInspectorsModalIsVisible(false)
          }}
        />
      )}
      {!listingSurveys && auxDate && workerModalIsVisible && surveyId > 0 && workerId > 0 && (
        <SeeWorkerModal
          workerId={workerId}
          workerObject={workerObject}
          visible={workerModalIsVisible}
          close={() => {
            queryClient.removeQueries(QUERY_KEYS.WORKER)
            setWorkerModalIsVisible(false)
            setWorkerId(0)
          }}
          survey={{ id: surveyId, date: auxDate }}
        />
      )}
      <div className="principal-table">
        {listingSurveys ? (
          <CardWrapperTable
            className={
              notAllowedRolesToSurveyAccess.includes(state?.level as LevelEnum)
                ? 'no-action-rows'
                : undefined
            }
            columns={surveysColumns}
            rowKey="id"
            onRow={
              notAllowedRolesToSurveyAccess.includes(state?.level as LevelEnum)
                ? undefined
                : record => {
                    const item = record as SurveyType
                    return {
                      onClick: e => {
                        const target = e.target as Element
                        if (target.classList.contains('ant-table-cell')) {
                          handleSeeSurvey(item)
                        }
                      },
                    }
                  }
            }
            loading={surveysIsLoading}
            // eslint-disable-next-line no-nested-ternary
            dataSource={surveys?.data}
            pagination={{
              showSizeChanger: true,
              current: surveys?.meta.current_page,
              pageSize: filters.filters?.limit,
              total: surveys?.meta.total_records,
              onChange: (page, pageSize) => {
                setFilters({
                  ...filters,
                  filters: {
                    ...filters.filters,
                    limit: pageSize ?? 10,
                    page,
                  },
                })
              },
            }}
          />
        ) : (
          <CardWrapperTable
            columns={workersColumns}
            rowKey="id"
            onRow={record => {
              const item = record as WorkersType
              return {
                onClick: e => {
                  const target = e.target as Element
                  if (target.classList.contains('ant-table-cell')) {
                    handleSeeWorker(item)
                  }
                },
              }
            }}
            loading={workersIsLoading}
            // eslint-disable-next-line no-nested-ternary
            dataSource={workers?.data}
            pagination={{
              showSizeChanger: true,
              current: workers?.meta.current_page,
              pageSize: filtersAux.filters?.limit,
              total: workers?.meta.total_records,
              onChange: (page, pageSize) => {
                setFiltersAux({
                  ...filtersAux,
                  filters: {
                    ...filtersAux.filters,
                    limit: pageSize ?? 10,
                    page,
                  },
                })
              },
            }}
          />
        )}
      </div>
    </CardWrapper>
  )
}

export default Surveys

import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useGetAttachments = (surveyId: number) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.SURVEY_ATTACHED_FILES, surveyId],
    () => {
      return DataApi.getAttachments(surveyId)
    },
    {
      enabled: surveyId > 0,
      staleTime: Infinity,
    },
  )

  return { attachments: data, attachmentsIsLoading: isLoading }
}

export { useGetAttachments }

import React, { useMemo } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { useUser } from '@contexts'
import { LevelEnum } from '@types'
import { ROUTE_KEYS } from '@constants'

type PrivateRouteProps = RouteProps & {
  allowedRoles: LevelEnum[]
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  allowedRoles,
  ...routeProps
}: PrivateRouteProps) => {
  const { state: user } = useUser()

  const { LOGIN } = ROUTE_KEYS
  const isRoleAllowed = useMemo(() => {
    return allowedRoles.some(role => user?.level === role)
  }, [user, allowedRoles])

  return user && isRoleAllowed ? <Route {...routeProps} /> : <Redirect to={{ pathname: LOGIN }} />
}

export { PrivateRoute }

import { RuralContractIndicatorsTypeEnum } from '@types'

const { COOPERATIVE, SHARECROPPING, FAMILIAR_ECONOMY, NONE } = RuralContractIndicatorsTypeEnum

export const RURAL_CONTRACT_INDICATORS_KEYS = {
  [NONE]: 'NINGUNA',
  [COOPERATIVE]: 'COOPERATIVA',
  [SHARECROPPING]: 'ARRENDAMIENTO - APARCERÍA - MEDIERÍA Ó ASOCIATIVISMO TAMBERO',
  [FAMILIAR_ECONOMY]: 'ECONOMÍA FAMILIAR',
}

export const latitudRegex = /^-([2][1-9]|[3-8][0-9]|90)(?:\.\d{1,12})?$/
export const longitudeRegex = /^-([5][3-9]|[6][0-9]|[7][0-3])(?:\.\d{1,12})?$/

export { LOCAL_STORAGE_KEYS } from './localStorageKeys'
export { QUERY_KEYS } from './queryKeys'
export { ROUTE_KEYS } from './routeKeys'
export { ENCODE_ACTION_KEYS } from './encodeActionKeys'

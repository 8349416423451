import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'
import { ElectronicAddress, User } from '@types'
import { useUser } from '@contexts'

const useGetElectronicAddress = () => {
  const { setState, state } = useUser()
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.ELECTRONIC_ADDRESS],
    () => {
      return DataApi.getElectronicAddress()
    },
    {
      onSuccess: (electronicAddressData: User) => {
        setState({ ...state, ...electronicAddressData })
      },
      staleTime: Infinity,
    },
  )
  return {
    electronicAddressData: data as ElectronicAddress,
    isLoadingElectronicAddressData: isLoading,
  }
}

export { useGetElectronicAddress }

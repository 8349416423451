import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'
import { FilterContextType } from '@types'

const useGetOrders = (params?: FilterContextType) => {
  const { data, isLoading } = useQuery([QUERY_KEYS.ORDERS, params], () => {
    return DataApi.getOrders(params)
  })
  return { orders: data, isLoading }
}

export { useGetOrders }

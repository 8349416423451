import { PrinterOutlined } from '@ant-design/icons'
import { Empty, Form, Input, Space, Spin } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import Text from 'antd/lib/typography/Text'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import ReactToPrint, { useReactToPrint } from 'react-to-print'
// eslint-disable-next-line import/no-unresolved
import logo from '@images/logo.svg'

import { WorkersType } from 'src/api/data'
import { useGetSurveyWorker } from '@hooks'
import { OtherUtils } from '@utils'
import { RURAL_CONTRACT_INDICATORS_KEYS } from '@constants'

import CollapseExpand from '../../../components/CollapseExpand'
import {
  GroupTitle,
  InputsRow,
  PanelSectionTitle,
  SurveyModal,
  CollapseForm,
  PanelSubSectionTitle,
  ScheduleTable,
  StyledForm,
  SectionTitle,
} from '../../../components'

interface SurveyModalProps {
  workerId: number
  workerObject?: WorkersType
  visible: boolean
  close: () => void
  survey: {
    id: number
    date: string
  }
}
const { NONE, COOPERATIVE, SHARECROPPING, FAMILIAR_ECONOMY } = RURAL_CONTRACT_INDICATORS_KEYS

const { boolTraduction, binaryResponse, emptyInput, isEmptyReplaceWith } = OtherUtils

const SeeWorkerModal: React.FC<SurveyModalProps> = ({
  workerId,
  workerObject,
  visible,
  close,
  survey,
}) => {
  const { t } = useTranslation()
  const { surveyWorker, surveyWorkerIsLoading } = useGetSurveyWorker(survey.id, workerId)
  const componentRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  const { Panel } = CollapseForm
  const tWorkers = 'globals.sections.surveys.workers.modal.'
  const tFields = 'globals.fields.'

  const workingDaysColumns = [
    {
      title: 'Día',
      dataIndex: 'dayOfWeek',
      key: 'dayOfWeek',
    },
    {
      title: 'Desde',
      dataIndex: 'startsAt',
      key: 'startsAt',
      width: '25%',
    },
    {
      title: 'Hasta',
      dataIndex: 'endsAt',
      key: 'endsAt',
      width: '25%',
    },
  ]
  const localCountry = 'Argentina'

  const titleRender = () => {
    return (
      <Space
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          paddingRight: '4rem',
        }}
      >
        <h1>{t('globals.sections.surveys.workers.modal.title') + workerId}</h1>
        <CollapseExpand />
        <ReactToPrint
          copyStyles
          documentTitle={`relevamiento-${survey?.id}_${survey?.date}_DNI-${workerObject?.documentNumber}`}
          trigger={() => (
            <Space style={{ cursor: 'pointer' }}>
              <Text style={{ fontSize: '1rem' }}>Imprimir</Text>
              <PrinterOutlined style={{ fontSize: '1.5rem' }} onClick={handlePrint} />
            </Space>
          )}
          content={() => componentRef.current}
        />
      </Space>
    )
  }

  const birthdateValue = () => {
    if (surveyWorker) {
      if (surveyWorker.birthdateNSNC) {
        return t(`${tFields}birthdate.unknown`)
      }
      if (surveyWorker.birthdate) {
        return surveyWorker.birthdate
      }
      return ''
    }
    return ''
  }

  const getAgeCategory = () => {
    if (surveyWorker) {
      if (surveyWorker.age < 16) {
        return 'INFANT'
      }
      if (surveyWorker.age < 18) {
        return 'ADOLESCENT'
      }
      return 'ADULT'
    }
    return 'UNKNOWN'
  }

  const getLiveWithDetail = () => {
    if (surveyWorker?.liveWith === 'Otro') {
      if (surveyWorker?.liveWithDetail) {
        return `Otros (${surveyWorker.liveWithDetail})`
      }
      return surveyWorker.liveWith
    }
    return surveyWorker?.liveWith
  }

  const getRelationshipLinkDetail = () => {
    if (surveyWorker?.relationshipLink === 'Otro') {
      if (surveyWorker?.relationshipLink) {
        return `Otro (${surveyWorker.relationshipLinkDetail})`
      }
      return surveyWorker.relationshipLink
    }
    return surveyWorker?.relationshipLink
  }

  const ageCategory = getAgeCategory()

  const disablePanelClass = 'disabled-panel'
  const noApplyMsg = ` - ${t('globals.no_apply')}`

  const ruralContractBinaryResponse = (value: string, ifFalse: string) => {
    return binaryResponse(
      surveyWorker?.generalAgriculturalWorkCondition,
      value,
      '',
      ifFalse,
    ) as string
  }

  return (
    <SurveyModal
      visible={visible}
      title={titleRender()}
      onCancel={close}
      footer={false}
      centered
      width={850}
    >
      <Spin spinning={surveyWorkerIsLoading}>
        {!surveyWorkerIsLoading && surveyWorker ? (
          <div ref={componentRef}>
            <StyledForm
              className="survey-form"
              layout="vertical"
              name="form_in_modal"
              initialValues={surveyWorker}
            >
              <SectionTitle className="see-worker-header">
                <img src={logo} alt="logo" />
                <GroupTitle>
                  {`${t('globals.sections.surveys.modal.title') + survey?.id} - ${`${t(
                    'globals.fields.inspection_date.title',
                  )} ${survey?.date}`}`}
                </GroupTitle>
              </SectionTitle>
              <CollapseForm bordered={false}>
                <Panel
                  header={
                    <PanelSectionTitle>1 - {t(`${tWorkers}personal_info`)}</PanelSectionTitle>
                  }
                  key={1}
                >
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}lastname.title`)} name="lastname">
                        <Input disabled />
                      </Form.Item>
                      <Form.Item label={t(`${tFields}name.title`)} name="firstname">
                        <Input disabled />
                      </Form.Item>
                      <Form.Item label={t(`${tFields}gender.title`)} name="gender">
                        <Input disabled />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}birthdate.title`)}>
                        <Input disabled value={birthdateValue()} />
                      </Form.Item>
                      <Form.Item label={t(`${tFields}age.title`)} name="age">
                        <Input disabled />
                      </Form.Item>
                      <Form.Item label={t(`${tFields}age_category.title`)}>
                        <Input
                          disabled
                          value={t(`globals.${ageCategory.toLowerCase()}`).toUpperCase() as string}
                        />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}document_type.title`)} name="documentType">
                        <Input disabled />
                      </Form.Item>
                      <Form.Item label={t(`${tFields}document_number.title`)} name="documentNumber">
                        <Input disabled />
                      </Form.Item>
                      <Form.Item label={t(`${tFields}shows_document.title`)}>
                        <Input disabled value={boolTraduction(surveyWorker.showDocument)} />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}birthplace.title`)} name="birthplace">
                        <Input disabled />
                      </Form.Item>
                      {surveyWorker?.birthplace !== localCountry && (
                        <Form.Item
                          label={t(`${tFields}country_entry.title`)}
                          name="countryEntryDate"
                        >
                          <Input disabled />
                        </Form.Item>
                      )}
                    </Space>
                  </InputsRow>
                  <InputsRow>
                    <Space>
                      {surveyWorker?.birthplace !== localCountry && (
                        <Form.Item label={t(`${tFields}work_documentation.title`)}>
                          <Input
                            disabled
                            value={boolTraduction(surveyWorker.hasValidDocumentationToWork)}
                          />
                        </Form.Item>
                      )}
                      <Form.Item label={t(`${tFields}lives_in_establishment.title`)}>
                        <Input disabled value={boolTraduction(surveyWorker.livesInEstablishment)} />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                  <InputsRow>
                    <Space className="address-row">
                      <Form.Item label={t(`${tFields}street_type.title`)} name="streetType">
                        <Input disabled />
                      </Form.Item>
                      <Form.Item label={t(`${tFields}street.title`)} name="streetName">
                        <Input disabled />
                      </Form.Item>
                      <Form.Item label={t(`${tFields}street_number.title`)}>
                        <Input
                          disabled
                          value={
                            surveyWorker.streetNumber !== null
                              ? surveyWorker.streetNumber
                              : emptyInput
                          }
                        />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}floor_department.title`)} name="streetFloor">
                        <Input disabled />
                      </Form.Item>
                      <Form.Item label={t(`${tFields}province.title`)} name="province">
                        <Input disabled />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}department.title`)} name="department">
                        <Input disabled />
                      </Form.Item>
                      <Form.Item label={t(`${tFields}locality.title`)} name="city">
                        <Input disabled />
                      </Form.Item>
                    </Space>
                  </InputsRow>

                  <GroupTitle>{t(`${tWorkers}education`)}</GroupTitle>
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}reads_writes.title`)}>
                        <Input disabled value={boolTraduction(surveyWorker.canReadAndWrite)} />
                      </Form.Item>
                      {ageCategory !== 'ADULT' && (
                        <Form.Item label={t(`${tFields}attends_to_school.title`)}>
                          <Input disabled value={boolTraduction(surveyWorker.attendsToSchool)} />
                        </Form.Item>
                      )}
                      <Form.Item label={t(`${tFields}education_level.title`)}>
                        <Input disabled value={surveyWorker.educationLevel} />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                </Panel>

                <Panel
                  header={
                    <PanelSectionTitle>2 - {t(`${tWorkers}work_situation`)}</PanelSectionTitle>
                  }
                  key={2}
                >
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}work_entry_date.title`)}>
                        <Input
                          disabled
                          value={
                            (surveyWorker?.workEntryDate ||
                              t('unknown_answer').toUpperCase()) as string
                          }
                        />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                  <InputsRow>
                    <Space>
                      <Form.Item
                        label={t(`${tFields}current_activity.title`)}
                        name="currentActivity"
                      >
                        <Input disabled />
                      </Form.Item>
                    </Space>
                  </InputsRow>

                  <GroupTitle>{t(`${tWorkers}payment`)}</GroupTitle>
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}payment_method.title`)} name="paymentMethod">
                        <Input disabled />
                      </Form.Item>
                      {surveyWorker.paymentMethodName.length > 0 && (
                        <Form.Item
                          label={t(`${tFields}payment_method_name.title`)}
                          name="paymentMethodName"
                        >
                          <Input disabled />
                        </Form.Item>
                      )}
                      {surveyWorker.netRemuneration && surveyWorker.netRemuneration > 0 ? (
                        <Form.Item
                          label={t(`${tFields}net_remuneration.title`)}
                          name="netRemuneration"
                        >
                          <Input disabled />
                        </Form.Item>
                      ) : (
                        ''
                      )}
                    </Space>
                  </InputsRow>
                  <InputsRow>
                    {surveyWorker.paymentCollectMethod && (
                      <>
                        <Space>
                          <Form.Item
                            label={t(`${tFields}payment_collect_method.title`)}
                            name="paymentCollectMethod"
                          >
                            <Input disabled />
                          </Form.Item>
                        </Space>
                      </>
                    )}
                    {surveyWorker.paymentCollectMethodDetail.length > 0 && (
                      <>
                        <Space>
                          <Form.Item
                            label={t(`${tFields}detail.title`)}
                            name="paymentCollectMethodDetail"
                          >
                            <Input disabled />
                          </Form.Item>
                        </Space>
                      </>
                    )}
                  </InputsRow>
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}work_method.title`)} name="workMethod">
                        <Input disabled />
                      </Form.Item>
                    </Space>
                    {surveyWorker.workMethodDetail.length > 0 && (
                      <>
                        <Space>
                          <Form.Item label={t(`${tFields}detail.title`)} name="workMethodDetail">
                            <Input disabled />
                          </Form.Item>
                        </Space>
                      </>
                    )}
                  </InputsRow>
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}extra_hours.title`)}>
                        <Input disabled value={boolTraduction(surveyWorker.hasExtraHours)} />
                      </Form.Item>
                      {surveyWorker.hasExtraHours && (
                        <>
                          <Form.Item label={t(`${tFields}extra_hours_payment.title`)}>
                            <Input
                              disabled
                              value={boolTraduction(surveyWorker.hasExtraHoursPayment)}
                            />
                          </Form.Item>
                        </>
                      )}
                      <Form.Item label={t(`${tFields}employment_exchange_worker.title`)}>
                        <Input
                          disabled
                          value={boolTraduction(surveyWorker.workerFromEmploymentOffice)}
                        />
                      </Form.Item>
                    </Space>
                  </InputsRow>

                  <GroupTitle>{t(`${tWorkers}schedule`)}</GroupTitle>
                  <InputsRow>
                    <Space>
                      <ScheduleTable
                        pagination={false}
                        dataSource={surveyWorker?.workingDays}
                        columns={workingDaysColumns}
                      />
                    </Space>
                    <Space />
                  </InputsRow>
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}total_week_work_time.title`)}>
                        <Input
                          disabled
                          value={OtherUtils.renderWorkTime(surveyWorker.totalWorkTime)}
                        />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                  <InputsRow>
                    <Space>
                      <Form.Item
                        label={t(`${tFields}observations.title`)}
                        name="paymentModalityObservations"
                      >
                        <TextArea disabled autoSize={{ minRows: 1 }} />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                </Panel>

                <Panel
                  collapsible={ageCategory !== 'ADULT' ? undefined : 'disabled'}
                  header={
                    <PanelSectionTitle className={ageCategory !== 'ADULT' ? '' : 'disabled-panel'}>
                      3 -{' '}
                      {`${t(`${tWorkers}adolescent_child_work_certificate`)}${
                        ageCategory === 'ADULT' ? ` - ${t(`${tWorkers}adult`)}` : ''
                      }`}
                    </PanelSectionTitle>
                  }
                  key={3}
                >
                  {ageCategory !== 'ADULT' ? (
                    <>
                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tFields}perform_dangerous_tasks.title`)}>
                            <Input
                              disabled
                              value={boolTraduction(surveyWorker.performDangerousTasks)}
                            />
                          </Form.Item>
                        </Space>
                        {surveyWorker.performDangerousTasks === true ? (
                          <Space>
                            <Form.Item
                              label={t(`${tFields}perform_dangerous_tasks_detail.title`)}
                              name="performDangerousTasksDetail"
                            >
                              <Input disabled />
                            </Form.Item>
                          </Space>
                        ) : (
                          <Space />
                        )}
                      </InputsRow>
                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tFields}sleep_on_spot.title`)}>
                            <Input disabled value={boolTraduction(surveyWorker.sleepOnSpot)} />
                          </Form.Item>
                          <Form.Item label={t(`${tFields}live_with.title`)}>
                            <Input value={getLiveWithDetail()} disabled />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      <InputsRow>
                        <Space>
                          <Form.Item
                            label={t(`${tFields}observations.title`)}
                            name="childrenExploitationObservations"
                          >
                            <TextArea disabled autoSize={{ minRows: 1 }} />
                          </Form.Item>
                        </Space>
                      </InputsRow>

                      {ageCategory === 'ADOLESCENT' ? (
                        <>
                          <GroupTitle>{t(`${tWorkers}teen_range.title`)}</GroupTitle>
                          <InputsRow>
                            <Space>
                              <Form.Item
                                label={t(`${tFields}has_time_extension_authorization.title`)}
                              >
                                <Input
                                  disabled
                                  value={boolTraduction(surveyWorker.hasTimeExtensionAuthorization)}
                                />
                              </Form.Item>
                              <Form.Item label={t(`${tFields}has_parental_authorization.title`)}>
                                <Input
                                  disabled
                                  value={boolTraduction(surveyWorker.hasParentalAuthorization)}
                                />
                              </Form.Item>
                            </Space>
                          </InputsRow>
                          <InputsRow>
                            <Space>
                              <Form.Item
                                label={t(`${tFields}has_physical_fitness_certificate.title`)}
                              >
                                <Input
                                  disabled
                                  value={boolTraduction(surveyWorker.hasPhysicalFitnessCertificate)}
                                />
                              </Form.Item>
                              <Form.Item label={t(`${tFields}has_school_certificate.title`)}>
                                <Input
                                  disabled
                                  value={boolTraduction(surveyWorker.hasSchoolCertificate)}
                                />
                              </Form.Item>
                            </Space>
                          </InputsRow>
                          <InputsRow>
                            <Space>
                              <Form.Item
                                label={t(`${tFields}has_educational_internship_certificate.title`)}
                              >
                                <Input
                                  disabled
                                  value={boolTraduction(
                                    surveyWorker.hasEducationalInternshipCertificate,
                                  )}
                                />
                              </Form.Item>
                              {surveyWorker?.hasChildren ? (
                                <Form.Item
                                  label={t(`${tFields}children_number.title`)}
                                  name="howManyChildren"
                                >
                                  <Input disabled />
                                </Form.Item>
                              ) : (
                                <Form.Item label={t(`${tFields}has_children.title`)}>
                                  <Input
                                    disabled
                                    value={boolTraduction(surveyWorker.hasChildren)}
                                  />
                                </Form.Item>
                              )}
                            </Space>
                          </InputsRow>
                          <InputsRow>
                            <Space>
                              <Form.Item
                                label={t(`${tFields}observations.title`)}
                                name="childrenExploitationChildDetailObservations"
                              >
                                <TextArea disabled autoSize={{ minRows: 1 }} />
                              </Form.Item>
                            </Space>
                          </InputsRow>
                          <GroupTitle>{t(`${tWorkers}child_range.title`)}</GroupTitle>
                          <Text>{t(`${tWorkers}child_range.msg`)}</Text>
                        </>
                      ) : (
                        <>
                          <GroupTitle>{t(`${tWorkers}teen_range.title`)}</GroupTitle>
                          <Text>{t(`${tWorkers}teen_range.msg`)}</Text>
                          <GroupTitle>{t(`${tWorkers}child_range.title`)}</GroupTitle>
                          <InputsRow>
                            <Space>
                              <Form.Item label={t(`${tFields}works_in_family_business.title`)}>
                                <Input
                                  disabled
                                  value={boolTraduction(surveyWorker.worksInFamilyBusiness)}
                                />
                              </Form.Item>
                            </Space>
                          </InputsRow>
                          <InputsRow>
                            <Space>
                              <Form.Item label={t(`${tFields}has_younger_sinblings_working.title`)}>
                                <Input
                                  disabled
                                  value={boolTraduction(surveyWorker.hasYoungerSinblingsWorking)}
                                />
                              </Form.Item>
                              <Form.Item
                                label={t(
                                  `${tFields}has_younger_sinblings_working_in_this_establishment.title`,
                                )}
                              >
                                <Input
                                  disabled
                                  value={boolTraduction(
                                    surveyWorker.hasYoungerSinblingsWorkingInThisEstablishment,
                                  )}
                                />
                              </Form.Item>
                            </Space>
                          </InputsRow>

                          <GroupTitle>{t(`${tWorkers}child_responsible`)}</GroupTitle>
                          <InputsRow>
                            <Space>
                              <Form.Item
                                label={t(`${tFields}lastname.title`)}
                                name="childrenExploitationLastname"
                              >
                                <Input disabled />
                              </Form.Item>
                              <Form.Item
                                label={t(`${tFields}name.title`)}
                                name="childrenExploitationFirstname"
                              >
                                <Input disabled />
                              </Form.Item>
                            </Space>
                          </InputsRow>
                          <InputsRow>
                            <Space>
                              <Form.Item
                                label={t(`${tFields}document_type.title`)}
                                name="childrenExploitationDocumentType"
                              >
                                <Input disabled />
                              </Form.Item>
                              <Form.Item
                                label={t(`${tFields}document_number.title`)}
                                name="childrenExploitationDocumentNumber"
                              >
                                <Input disabled />
                              </Form.Item>
                              <Form.Item label={t(`${tFields}relationship_link.title`)}>
                                <Input value={getRelationshipLinkDetail()} disabled />
                              </Form.Item>
                            </Space>
                          </InputsRow>
                          <InputsRow>
                            <Space>
                              <Form.Item
                                label={t(`${tFields}observations.title`)}
                                name="childrenExploitationChildDetailObservations"
                              >
                                <TextArea disabled autoSize={{ minRows: 1 }} />
                              </Form.Item>
                            </Space>
                          </InputsRow>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Text>{t(`${tWorkers}over_eighteen_range.msg`)}</Text>
                    </>
                  )}
                </Panel>

                <Panel
                  header={
                    <PanelSectionTitle
                      className={
                        surveyWorker?.generalAgriculturalWorkCondition === NONE
                          ? 'disabled-panel'
                          : ''
                      }
                    >
                      4 - {t(`${tWorkers}worksheet_indicators_employment`)}
                    </PanelSectionTitle>
                  }
                  key={4}
                  collapsible={
                    surveyWorker?.generalAgriculturalWorkCondition === NONE ? 'disabled' : undefined
                  }
                >
                  <PanelSubSectionTitle>
                    4.1 - {t(`${tWorkers}general_conditions`)}
                  </PanelSubSectionTitle>
                  <InputsRow>
                    <Space>
                      <Form.Item
                        label={t(`${tFields}general_agricultural_work_condition.title`)}
                        name="generalAgriculturalWorkCondition"
                      >
                        <Input disabled />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}supervisor.title`)} name="supervisor">
                        <TextArea disabled autoSize={{ minRows: 1 }} />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                  <InputsRow>
                    <Space>
                      <Form.Item
                        label={t(`${tFields}supervisor_works_for.title`)}
                        name="supervisorWorksFor"
                      >
                        <TextArea disabled autoSize={{ minRows: 1 }} />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}who_pays_salary.title`)} name="whoPaysSalary">
                        <TextArea disabled autoSize={{ minRows: 1 }} />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}who_pays_tools.title`)} name="whoPaysTools">
                        <Input disabled />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}worker_is_supplied_of.title`)}>
                        <Input
                          disabled
                          value={
                            surveyWorker.workerIsSuppliedOf &&
                            surveyWorker.workerIsSuppliedOf.length > 0
                              ? (surveyWorker.workerIsSuppliedOf.map((item, key) => {
                                  if (key === 0) {
                                    return item.name.toUpperCase()
                                  }
                                  return ` ${item.name.toUpperCase()}`
                                }) as unknown as string)
                              : (t('globals.no_apply').toUpperCase() as string)
                          }
                        />
                      </Form.Item>
                    </Space>
                    <Space>
                      <Form.Item label={t(`${tFields}has_own_workers.title`)}>
                        <Input disabled value={boolTraduction(surveyWorker.hasOwnWorkers)} />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}has_other_activity.title`)}>
                        <Input disabled value={boolTraduction(surveyWorker.hasOtherActivity)} />
                      </Form.Item>
                    </Space>
                    <Space>
                      <Form.Item label={t(`${tFields}working_for.title`)}>
                        <Input
                          disabled
                          value={
                            surveyWorker.hasOtherActivity ? surveyWorker.workingFor : emptyInput
                          }
                        />
                      </Form.Item>
                    </Space>
                  </InputsRow>

                  <PanelSubSectionTitle
                    className={ruralContractBinaryResponse(COOPERATIVE, disablePanelClass)}
                  >
                    4.2 - {t(`${tWorkers}cooperatives`)}
                    {ruralContractBinaryResponse(COOPERATIVE, noApplyMsg)}
                  </PanelSubSectionTitle>
                  {surveyWorker.generalAgriculturalWorkCondition === COOPERATIVE && (
                    <>
                      <InputsRow>
                        <Space>
                          <Form.Item
                            label={t(`${tFields}cooperative_name.title`)}
                            name="cooperativeName"
                          >
                            <Input disabled />
                          </Form.Item>
                          <Form.Item
                            label={t(`${tFields}work_since_date.title`)}
                            name="workSinceDate"
                          >
                            <Input disabled />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tFields}signed_application.title`)}>
                            <Input
                              disabled
                              value={boolTraduction(surveyWorker.signedApplication)}
                            />
                          </Form.Item>
                          <Form.Item label={t(`${tFields}admission_free.title`)}>
                            <Input disabled value={boolTraduction(surveyWorker.admissionFree)} />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tFields}has_regulation_copy.title`)}>
                            <Input
                              disabled
                              value={boolTraduction(surveyWorker.hasRegulationCopy)}
                            />
                          </Form.Item>
                          <Form.Item label={t(`${tFields}attend_cooperative_assemblies.title`)}>
                            <Input
                              disabled
                              value={boolTraduction(surveyWorker.attendCooperativeAssemblies)}
                            />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      <InputsRow>
                        {surveyWorker.citedToAssemblies === true ? (
                          <Space>
                            <Form.Item label={t(`${tFields}cited_to_assemblies.title`)}>
                              <Input
                                disabled
                                value={boolTraduction(surveyWorker.citedToAssemblies)}
                              />
                            </Form.Item>
                            <Form.Item
                              label={t(`${tFields}how_was_it_cited.title`)}
                              name="howWasItCited"
                            >
                              <Input disabled />
                            </Form.Item>
                          </Space>
                        ) : (
                          <Space>
                            <Form.Item label={t(`${tFields}cited_to_assemblies.title`)}>
                              <Input
                                disabled
                                value={boolTraduction(surveyWorker.citedToAssemblies)}
                              />
                            </Form.Item>
                          </Space>
                        )}
                      </InputsRow>
                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tFields}know_authorities.title`)}>
                            <Input disabled value={boolTraduction(surveyWorker.knowAuthorities)} />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      <InputsRow>
                        <Space>
                          <Form.Item
                            label={t(`${tFields}observations.title`)}
                            name="cooperativeObservations"
                          >
                            <TextArea disabled autoSize={{ minRows: 1 }} />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                    </>
                  )}

                  <PanelSubSectionTitle
                    className={ruralContractBinaryResponse(FAMILIAR_ECONOMY, disablePanelClass)}
                  >
                    4.3 - {t(`${tWorkers}family_economy`)}
                    {ruralContractBinaryResponse(FAMILIAR_ECONOMY, noApplyMsg)}
                  </PanelSubSectionTitle>
                  {surveyWorker.generalAgriculturalWorkCondition === FAMILIAR_ECONOMY && (
                    <>
                      <InputsRow>
                        <Space>
                          <Form.Item
                            label={t(`${tFields}relationship_grade.title`)}
                            name="relationshipGrade"
                          >
                            <Input disabled />
                          </Form.Item>
                          {surveyWorker.relationshipGrade === 'Otro' && (
                            <>
                              <Form.Item
                                label={t(`${tFields}relationship_grade_detail.title`)}
                                name="relationshipGradeDetail"
                              >
                                <Input disabled />
                              </Form.Item>
                            </>
                          )}
                        </Space>
                      </InputsRow>
                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tFields}live_in_establishment.title`)}>
                            <Input
                              disabled
                              value={boolTraduction(surveyWorker.liveInEstablishment)}
                            />
                          </Form.Item>
                          <Form.Item
                            label={t(`${tFields}there_members_without_relationship.title`)}
                          >
                            <Input
                              disabled
                              value={boolTraduction(surveyWorker.thereMembersWithoutRelationship)}
                            />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      <InputsRow>
                        <Space>
                          <Form.Item
                            label={t(`${tFields}observations.title`)}
                            name="familiarEconomyObservations"
                          >
                            <TextArea disabled autoSize={{ minRows: 1 }} />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                    </>
                  )}

                  <PanelSubSectionTitle
                    className={ruralContractBinaryResponse(SHARECROPPING, disablePanelClass)}
                  >
                    4.4 - {t(`${tWorkers}lease_sharecropping_dairy_associativism`)}
                    {ruralContractBinaryResponse(SHARECROPPING, noApplyMsg)}
                  </PanelSubSectionTitle>
                  {surveyWorker.generalAgriculturalWorkCondition === SHARECROPPING && (
                    <>
                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tFields}link_type.title`)} name="linkType">
                            <Input disabled />
                          </Form.Item>
                          <Form.Item label={t(`${tFields}signed_contract.title`)}>
                            <Input disabled value={boolTraduction(surveyWorker.signedContract)} />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tFields}display.title`)}>
                            <Input disabled value={boolTraduction(surveyWorker.display)} />
                          </Form.Item>
                          <Form.Item label={t(`${tFields}is_approbed.title`)}>
                            <Input disabled value={boolTraduction(surveyWorker.isApprobed)} />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      <InputsRow>
                        <Space>
                          <Form.Item
                            label={t(`${tFields}who_sells_and_distributes.title`)}
                            name="whoSellsAndDistributes"
                          >
                            <Input disabled />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      <InputsRow>
                        <Space>
                          <Form.Item
                            label={t(`${tFields}observations.title`)}
                            name="sharecroppingObservations"
                          >
                            <TextArea disabled autoSize={{ minRows: 1 }} />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                    </>
                  )}
                </Panel>

                <Panel
                  collapsible={surveyWorker.hasIelr ? undefined : 'disabled'}
                  header={
                    <PanelSectionTitle className={surveyWorker.hasIelr ? '' : 'disabled-panel'}>
                      5 -{' '}
                      {`${t(`${tWorkers}supplementary_form`)}${
                        !surveyWorker.hasIelr
                          ? ` - ${t('globals.sections.surveys.modal.no_ielr')}`
                          : ''
                      }`}
                    </PanelSectionTitle>
                  }
                  key={5}
                >
                  {surveyWorker.hasIelr && (
                    <>
                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tFields}has_ielr.title`)}>
                            <Input disabled value={boolTraduction(surveyWorker.hasIelr)} />
                          </Form.Item>
                        </Space>
                      </InputsRow>

                      <GroupTitle>
                        {t(`${tWorkers}recruitment_restrictions_indebtedness`)}
                      </GroupTitle>
                      <InputsRow>
                        <Space>
                          <Form.Item
                            label={t(`${tFields}how_have_you_heard_about_work.title`)}
                            name="howHaveYouHeardAboutWork"
                          >
                            <Input disabled />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      {surveyWorker.howHaveYouHeardAboutWorkDetail.length > 0 && (
                        <>
                          <InputsRow>
                            <Space>
                              <Form.Item
                                label={t(`${tFields}detail.title`)}
                                name="howHaveYouHeardAboutWorkDetail"
                              >
                                <TextArea disabled autoSize={{ minRows: 1 }} />
                              </Form.Item>
                            </Space>
                          </InputsRow>
                        </>
                      )}

                      <InputsRow>
                        <Space>
                          <Form.Item
                            label={t(`${tFields}how_did_you_get_to_work.title`)}
                            name="howDidYouGetToWork"
                          >
                            <Input disabled />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      {surveyWorker.howDidYouGetToWorkDetail.length > 0 && (
                        <>
                          <InputsRow>
                            <Space>
                              <Form.Item
                                label={t(`${tFields}detail.title`)}
                                name="howDidYouGetToWorkDetail"
                              >
                                <TextArea disabled autoSize={{ minRows: 1 }} />
                              </Form.Item>
                            </Space>
                          </InputsRow>
                        </>
                      )}
                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tFields}employer_pays_trip_expenses.title`)}>
                            <Input
                              disabled
                              value={boolTraduction(surveyWorker.employerPaysTripExpenses)}
                            />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tFields}withheld_documentation.title`)}>
                            <Input
                              disabled
                              value={boolTraduction(surveyWorker.withheldDocumentation)}
                            />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      {surveyWorker.withheldDocumentation && (
                        <>
                          <InputsRow>
                            <Space>
                              <Form.Item label={t(`${tFields}who_withheld_documentation.title`)}>
                                <Input
                                  disabled
                                  value={isEmptyReplaceWith(
                                    surveyWorker.whoWithheldDocumentation,
                                    emptyInput,
                                  )}
                                />
                              </Form.Item>
                            </Space>
                          </InputsRow>
                        </>
                      )}
                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tFields}free_to_leave_the_establishment.title`)}>
                            <Input
                              disabled
                              value={boolTraduction(surveyWorker.freeToLeaveTheEstablishment)}
                            />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      {!surveyWorker.freeToLeaveTheEstablishment && (
                        <>
                          {surveyWorker.reasonsForRestrictiveCirculation?.length > 0 && (
                            <>
                              <InputsRow>
                                <Space>
                                  <Form.Item
                                    label={t(`${tFields}reasons_for_restrictive_circulation.title`)}
                                    name="reasonsForRestrictiveCirculation"
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                </Space>
                              </InputsRow>
                            </>
                          )}

                          {surveyWorker.reasonsForRestrictiveCirculationDetail.length > 0 && (
                            <>
                              <InputsRow>
                                <Space>
                                  <Form.Item
                                    label={t(`${tFields}detail.title`)}
                                    name="reasonsForRestrictiveCirculationDetail"
                                  >
                                    <TextArea disabled autoSize={{ minRows: 1 }} />
                                  </Form.Item>
                                </Space>
                              </InputsRow>
                            </>
                          )}
                        </>
                      )}

                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tFields}can_communicate_with_family.title`)}>
                            <Input
                              disabled
                              value={boolTraduction(surveyWorker.canCommunicateWithFamily)}
                            />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      {!surveyWorker.canCommunicateWithFamily && (
                        <>
                          <InputsRow>
                            <Space>
                              <Form.Item
                                label={t(`${tFields}cannot_communicate_with_family_detail.title`)}
                                name="cannotCommunicateWithFamilyDetail"
                              >
                                <Input disabled />
                              </Form.Item>
                            </Space>
                          </InputsRow>
                        </>
                      )}

                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tFields}forced_to_sleep_there.title`)}>
                            <Input
                              disabled
                              value={boolTraduction(surveyWorker.forcedToSleepThere)}
                            />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tFields}received_a_loan.title`)}>
                            <Input disabled value={boolTraduction(surveyWorker.receivedALoan)} />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tFields}salary_is_discounted.title`)}>
                            <Input
                              disabled
                              value={boolTraduction(surveyWorker.salaryIsDiscounted)}
                            />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      {surveyWorker.salaryIsDiscounted && (
                        <>
                          <InputsRow>
                            <Space>
                              <Form.Item label={t(`${tFields}discounted_concepts.title`)}>
                                <Input
                                  disabled
                                  value={
                                    surveyWorker.discountedConcepts &&
                                    surveyWorker.discountedConcepts.length > 0
                                      ? (surveyWorker.discountedConcepts.map((item, key) => {
                                          if (key === 0) {
                                            return item.name.toUpperCase()
                                          }
                                          return ` ${item.name.toUpperCase()}`
                                        }) as unknown as string)
                                      : '-'
                                  }
                                />
                              </Form.Item>
                            </Space>
                          </InputsRow>
                        </>
                      )}
                      {surveyWorker.discountedConceptsDetail.length > 0 && (
                        <>
                          <InputsRow>
                            <Space>
                              <Form.Item
                                label={t(`${tFields}detail.title`)}
                                name="discountedConceptsDetail"
                              >
                                <TextArea disabled autoSize={{ minRows: 1 }} />
                              </Form.Item>
                            </Space>
                          </InputsRow>
                        </>
                      )}
                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tFields}salaries_paid_on_time.title`)}>
                            <Input
                              disabled
                              value={boolTraduction(surveyWorker.salariesPaidOnTime)}
                            />
                          </Form.Item>
                          <Form.Item label={t(`${tFields}owe_payments.title`)}>
                            <Input disabled value={boolTraduction(surveyWorker.owePayments)} />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tFields}work_violence.title`)}>
                            <Input disabled value={boolTraduction(surveyWorker.sufferedViolence)} />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                      {surveyWorker.sufferedViolence && (
                        <>
                          <InputsRow>
                            <Space>
                              <Form.Item label={t(`${tFields}what_type_of_violences.title`)}>
                                <Input
                                  disabled
                                  value={
                                    surveyWorker.whatTypeOfViolences &&
                                    surveyWorker.whatTypeOfViolences.length > 0
                                      ? (surveyWorker.whatTypeOfViolences.map((item, key) => {
                                          if (key === 0) {
                                            return item.name.toUpperCase()
                                          }
                                          return ` ${item.name.toUpperCase()}`
                                        }) as unknown as string)
                                      : '-'
                                  }
                                />
                              </Form.Item>
                            </Space>
                          </InputsRow>
                          {surveyWorker.whatTypeOfViolenceDetail.length > 0 && (
                            <>
                              <InputsRow>
                                <Space>
                                  <Form.Item
                                    label={t(`${tFields}detail.title`)}
                                    name="whatTypeOfViolenceDetail"
                                  >
                                    <TextArea disabled autoSize={{ minRows: 1 }} />
                                  </Form.Item>
                                </Space>
                              </InputsRow>
                            </>
                          )}
                        </>
                      )}
                      {surveyWorker.workerIelrObservations ? (
                        <InputsRow>
                          <Space>
                            <Form.Item
                              label={t(`${tFields}observations.title`)}
                              name="workerIelrObservations"
                            >
                              <TextArea disabled autoSize={{ minRows: 1 }} />
                            </Form.Item>
                          </Space>
                        </InputsRow>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </Panel>
                <Panel
                  header={<PanelSectionTitle>{t(`${tWorkers}generals`)}</PanelSectionTitle>}
                  key={6}
                >
                  <GroupTitle>{t(`${tWorkers}work_security_health`)}</GroupTitle>
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}has_workwear.title`)}>
                        <Input disabled value={boolTraduction(surveyWorker.hasWorkwear)} />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                  <InputsRow>
                    <Space>
                      <Form.Item label={t(`${tFields}has_epp.title`)}>
                        <Input disabled value={boolTraduction(surveyWorker.hasEpp)} />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                  <GroupTitle>{t(`${tWorkers}general_observations`)}</GroupTitle>
                  <InputsRow>
                    <Space>
                      <Form.Item name="workerObservations">
                        <TextArea disabled autoSize={{ minRows: 1 }} />
                      </Form.Item>
                    </Space>
                  </InputsRow>
                </Panel>
              </CollapseForm>
            </StyledForm>
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
    </SurveyModal>
  )
}

export default SeeWorkerModal

import { useMutation } from 'react-query'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { AuthApi, setAuthHeader } from '@api'
import { LOCAL_STORAGE_KEYS, ROUTE_KEYS } from '@constants'
import { useUser } from '@contexts'

interface UseUnregisteredEmployerLoginProps {
  onError?: () => void
}

const useUnregisteredEmployerLogin = (props?: UseUnregisteredEmployerLoginProps) => {
  const history = useHistory()

  const { setState: setUser } = useUser()

  const { t } = useTranslation()

  const { mutate, isLoading } = useMutation(AuthApi.unregisteredEmployerLogin, {
    onSuccess: async ({ data }) => {
      setAuthHeader(data.token)
      setUser(data)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESHED, 'false')
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, data.token)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, data.refreshToken)
      history.push(`${ROUTE_KEYS[data.level].BASE_URL}${ROUTE_KEYS[data.level].HOME}`)
    },
    onError: () => {
      if (props?.onError) {
        props.onError()
      }
      notification.error({
        message: t('login.incorrect_unregistered_login'),
      })
    },
  })

  return { unregisteredEmployerLogin: mutate, unregisteredEmployerLoginisLoading: isLoading }
}

export { useUnregisteredEmployerLogin }

import { useQuery } from 'react-query'

import { DataApi } from '@api'
import { QUERY_KEYS } from '@constants'

const useGetMedia = (surveyId: number) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.MEDIA, surveyId],
    () => {
      return DataApi.getMedia(surveyId)
    },
    {
      enabled: surveyId > 0,
      staleTime: Infinity,
    },
  )
  return { media: data, mediaIsLoading: isLoading }
}

export { useGetMedia }

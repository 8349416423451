import { Empty, Form, Input, Space, Spin, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useTranslation } from 'react-i18next'

import { useGetSurvey } from '@hooks'
import { SolidaryCuit } from '@types'
import { OtherUtils } from '@utils'

import CollapseExpand from '../../../components/CollapseExpand'
import {
  CollapseForm,
  GroupTitle,
  InputsRow,
  PanelSectionTitle,
  StyledForm,
  SurveyModal,
} from '../../../components'

interface SurveyModalProps {
  surveyId: number
  modalTitle: string
  visible: boolean
  close: () => void
}

const { boolTraduction, emptyInput } = OtherUtils

const SeeSurveyModal: React.FC<SurveyModalProps> = ({ surveyId, modalTitle, visible, close }) => {
  const { t } = useTranslation()
  const { survey, surveyIsLoading } = useGetSurvey(surveyId)
  const tSurvey = 'globals.sections.surveys.modal.'
  const tPrefix = 'globals.fields.'
  const { Panel } = CollapseForm
  const [form] = StyledForm.useForm()
  const { Text } = Typography

  const titleRender = () => {
    return (
      <Space
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          paddingRight: '4rem',
        }}
      >
        <h1>{modalTitle + surveyId}</h1>
        <CollapseExpand />
      </Space>
    )
  }
  return (
    <SurveyModal
      visible={visible}
      title={titleRender()}
      onCancel={close}
      footer={false}
      centered
      width={850}
    >
      <Spin spinning={surveyIsLoading}>
        {!surveyIsLoading && survey ? (
          <StyledForm
            className="survey-form"
            layout="vertical"
            name="form_in_modal"
            initialValues={survey}
            form={form}
          >
            <CollapseForm bordered={false}>
              <Panel
                header={<PanelSectionTitle>{t(`${tPrefix}general_information`)}</PanelSectionTitle>}
                key={1}
              >
                <GroupTitle>{t(`${tSurvey}info_survey`)}</GroupTitle>

                <InputsRow>
                  <Space>
                    <Form.Item label={t(`${tPrefix}survey_number.description`)}>
                      <Input disabled value={survey.surveyId || emptyInput} />
                    </Form.Item>
                    <Form.Item label={t(`${tPrefix}provisory_survey_number.description`)}>
                      <Input disabled value={survey.provisionalId || emptyInput} />
                    </Form.Item>
                  </Space>
                </InputsRow>
                <InputsRow>
                  <Space>
                    <Form.Item label={t(`${tPrefix}inspection_order.title`)}>
                      <Input disabled value={survey.inspectionOrderId || emptyInput} />
                    </Form.Item>
                    <Form.Item label={t(`${tPrefix}date_fiscalization.title`)}>
                      <Input disabled value={survey.fiscalizationDate || emptyInput} />
                    </Form.Item>
                  </Space>
                </InputsRow>

                <InputsRow>
                  <Space>
                    <Form.Item label={t(`${tPrefix}start_date.title`)}>
                      <Input disabled value={survey.startDate || emptyInput} />
                    </Form.Item>
                    <Form.Item label={t(`${tPrefix}start_time.title`)}>
                      <Input value={survey.startTime.slice(0, -3) || emptyInput} disabled />
                    </Form.Item>
                  </Space>
                </InputsRow>
                <InputsRow>
                  <Space>
                    <Form.Item label={t(`${tPrefix}end_date.title`)}>
                      <Input disabled value={survey.endDate || emptyInput} />
                    </Form.Item>
                    <Form.Item label={t(`${tPrefix}end_time.title`)}>
                      <Input value={survey.endTime.slice(0, -3) || emptyInput} disabled />
                    </Form.Item>
                  </Space>
                </InputsRow>

                <InputsRow>
                  <Space>
                    <Form.Item label={t(`${tPrefix}edition_reason.title`)}>
                      <Input
                        disabled
                        value={
                          survey.reasonDetail
                            ? `${survey.reason.length ? `${survey.reason}: ` : ''}${
                                survey.reasonDetail
                              }`
                            : survey.reason || emptyInput
                        }
                      />
                    </Form.Item>
                  </Space>
                </InputsRow>

                <InputsRow>
                  <Space>
                    <Form.Item label={t(`${tPrefix}responsible_inspector.title`)}>
                      <Input disabled value={survey.responsibleInspector || emptyInput} />
                    </Form.Item>
                  </Space>
                </InputsRow>
                <InputsRow>
                  <Space>
                    <Form.Item label={t(`${tPrefix}auxiliar_inspectors.title`)}>
                      {survey.auxiliarInspectors.map(inspector => (
                        <TextArea
                          disabled
                          value={inspector || emptyInput}
                          autoSize={{ minRows: 1 }}
                        />
                      ))}
                    </Form.Item>
                  </Space>
                </InputsRow>

                <GroupTitle>{t(`${tSurvey}info_employer`)}</GroupTitle>

                <InputsRow>
                  <Space>
                    <Form.Item label={t(`${tPrefix}cuit.title`)}>
                      <Input disabled value={survey.cuit || emptyInput} />
                    </Form.Item>
                    <Form.Item label={t(`${tPrefix}bussiness.title`)}>
                      <Input disabled value={survey.business || emptyInput} />
                    </Form.Item>
                  </Space>
                </InputsRow>

                <GroupTitle>{t(`${tSurvey}info_establishment`)}</GroupTitle>

                <InputsRow>
                  <Space>
                    <Form.Item label={t(`${tPrefix}establishment.title`)}>
                      <Input disabled value={survey.establishmentName || emptyInput} />
                    </Form.Item>
                  </Space>
                </InputsRow>
                <InputsRow>
                  <Space className="address-row">
                    <Form.Item label={t(`${tPrefix}street_type.title`)}>
                      <Input disabled value={survey.streetType || emptyInput} />
                    </Form.Item>
                    <Form.Item label={t(`${tPrefix}street.title`)}>
                      <Input disabled value={survey.streetName || emptyInput} />
                    </Form.Item>
                    <Form.Item label={t(`${tPrefix}street_number.title`)}>
                      <Input disabled value={survey.streetNumber || emptyInput} />
                    </Form.Item>
                  </Space>
                </InputsRow>
                <InputsRow>
                  <Space>
                    <Form.Item label={t(`${tPrefix}province.title`)}>
                      <Input disabled value={survey.province || emptyInput} />
                    </Form.Item>
                    <Form.Item label={t(`${tPrefix}department.title`)}>
                      <Input disabled value={survey.department || emptyInput} />
                    </Form.Item>
                  </Space>
                </InputsRow>
                <InputsRow>
                  <Space>
                    <Form.Item label={t(`${tPrefix}locality.title`)}>
                      <Input disabled value={survey.locality || emptyInput} />
                    </Form.Item>
                    <Form.Item label={t(`${tPrefix}place.title`)}>
                      <Input disabled value={survey.place || emptyInput} />
                    </Form.Item>
                  </Space>
                </InputsRow>
                <InputsRow>
                  <Space>
                    <Form.Item label={t(`${tPrefix}latitude.title`)}>
                      <Input disabled value={survey.latitude || emptyInput} />
                    </Form.Item>
                    <Form.Item label={t(`${tPrefix}longitude.title`)}>
                      <Input disabled value={survey.longitude || emptyInput} />
                    </Form.Item>
                  </Space>
                </InputsRow>
                <InputsRow>
                  <Space>
                    <Form.Item label={t(`${tPrefix}main_activity.title`)}>
                      <Input disabled value={survey.mainActivity || emptyInput} />
                    </Form.Item>
                    <Form.Item label={t(`${tPrefix}secundary_activity.title`)}>
                      <Input disabled value={survey.secundaryActivity || emptyInput} />
                    </Form.Item>
                  </Space>
                </InputsRow>

                <GroupTitle>{t(`${tSurvey}info_inspection_staff`)}</GroupTitle>

                <InputsRow>
                  <Space>
                    <Form.Item label={t(`${tPrefix}attended_by.title`)}>
                      <Input disabled value={survey.attendedBy || emptyInput} />
                    </Form.Item>
                    <Form.Item label={t(`${tPrefix}as.title`)}>
                      <Input disabled value={survey.as || emptyInput} />
                    </Form.Item>
                  </Space>
                </InputsRow>
                <InputsRow>
                  <Space>
                    <Form.Item label={t(`${tPrefix}document_type.title`)}>
                      <Input disabled value={survey.documentType || emptyInput} />
                    </Form.Item>
                    <Form.Item label={t(`${tPrefix}document_number.title`)}>
                      <Input disabled value={survey.documentNumber || emptyInput} />
                    </Form.Item>
                  </Space>
                </InputsRow>

                <GroupTitle>{t(`${tSurvey}solidary_document`)}</GroupTitle>

                {survey.solidarityCuits && survey.solidarityCuits.length > 0 ? (
                  <>
                    {survey.solidarityCuits.map(({ cuit, business }: SolidaryCuit) => (
                      <InputsRow>
                        <Space>
                          <Form.Item label={t(`${tPrefix}cuit.title`)}>
                            <Input disabled value={cuit || emptyInput} />
                          </Form.Item>
                          <Form.Item label={t(`${tPrefix}bussiness.title`)}>
                            <Input disabled value={business || emptyInput} />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                    ))}
                    {survey.solidarityObservations ? (
                      <InputsRow>
                        <Space>
                          <Form.Item
                            label={`${t(`${tPrefix}observations.title`)} - ${t(
                              `${tSurvey}solidary_document`,
                            )}`}
                          >
                            <TextArea
                              disabled
                              autoSize={{ minRows: 1 }}
                              value={survey.solidarityObservations || emptyInput}
                            />
                          </Form.Item>
                        </Space>
                      </InputsRow>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <Text>{t(`${tSurvey}no_solidary_cuit`)}</Text>
                )}
                <GroupTitle>
                  {`${t(`${tSurvey}observations`)} - ${t(`${tPrefix}employer.title`)}`}
                </GroupTitle>
                <InputsRow>
                  <Space>
                    <Form.Item>
                      <TextArea
                        disabled
                        autoSize={{ minRows: 1 }}
                        value={survey.employerObservations || emptyInput}
                      />
                    </Form.Item>
                  </Space>
                </InputsRow>
              </Panel>

              <Panel
                collapsible={survey.hasIELR ? undefined : 'disabled'}
                header={
                  <PanelSectionTitle className={survey.hasIELR ? '' : 'disabled-panel'}>
                    {`${t(`${tSurvey}rural_labor_exploitation`)}${
                      !survey.hasIELR ? ` - ${t(`${tSurvey}no_ielr`)}` : ''
                    }`}
                  </PanelSectionTitle>
                }
                key={2}
              >
                {survey.hasIELR && (
                  <>
                    <InputsRow>
                      <Space>
                        <Form.Item label={t(`${tPrefix}has_ielr.title`)}>
                          <Input disabled value={boolTraduction(survey.hasIELR) || emptyInput} />
                        </Form.Item>
                      </Space>
                    </InputsRow>

                    <GroupTitle>{t(`${tSurvey}environment_conditions`)}</GroupTitle>
                    <InputsRow>
                      <Space>
                        <Form.Item label={t(`${tPrefix}supplies_living_place.title`)}>
                          <Input
                            disabled
                            value={boolTraduction(survey.hasAccommodationForWorkers) || emptyInput}
                          />
                        </Form.Item>
                      </Space>
                    </InputsRow>
                    <InputsRow>
                      <Space>
                        <Form.Item label={t(`${tPrefix}principal_floor_material.title`)}>
                          <Input
                            disabled
                            value={
                              survey.ielrOtherFloorMaterial
                                ? `${t('other').toUpperCase()} ( ${survey.ielrOtherFloorMaterial} )`
                                : survey.floorMaterial || emptyInput
                            }
                          />
                        </Form.Item>
                        <Form.Item label={t(`${tPrefix}principal_exterior_walls_material.title`)}>
                          <Input
                            disabled
                            value={
                              survey.ielrOtherWallMaterial
                                ? `${t('other').toUpperCase()} ( ${survey.ielrOtherWallMaterial} )`
                                : survey.wallMaterial || emptyInput
                            }
                          />
                        </Form.Item>
                      </Space>
                    </InputsRow>
                    <InputsRow>
                      <Space>
                        <Form.Item label={t(`${tPrefix}principal_roof_material.title`)}>
                          <Input
                            disabled
                            value={
                              survey.ielrOtherRoofMaterial
                                ? `${t('other').toUpperCase()} ( ${survey.ielrOtherRoofMaterial} )`
                                : survey.roofMaterial || emptyInput
                            }
                          />
                        </Form.Item>
                        <Form.Item label={t(`${tPrefix}to_sleep_has.title`)}>
                          <Input disabled value={survey.hasForSleep || emptyInput} />
                        </Form.Item>
                      </Space>
                    </InputsRow>
                    <InputsRow>
                      <Space>
                        <Form.Item label={t(`${tPrefix}other_has_for_sleep.title`)}>
                          <Input disabled value={survey.otherHasForSleep || emptyInput} />
                        </Form.Item>
                      </Space>
                    </InputsRow>
                    <InputsRow>
                      <Space>
                        <Form.Item label={t(`${tPrefix}how_many_rooms.title`)}>
                          <Input disabled value={survey.bedroomsQuantity || emptyInput} />
                        </Form.Item>
                      </Space>
                    </InputsRow>
                    <InputsRow>
                      <Space>
                        <Form.Item label={t(`${tPrefix}how_many_persons_per_room.title`)}>
                          <Input disabled value={survey.peopleByBedRoom || emptyInput} />
                        </Form.Item>
                        <Form.Item label={t(`${tPrefix}has_bathroom.title`)}>
                          <Input
                            disabled
                            value={boolTraduction(survey.hasBathroom) || emptyInput}
                          />
                        </Form.Item>
                      </Space>
                    </InputsRow>
                    <InputsRow>
                      <Space>
                        <Form.Item label={t(`${tPrefix}bathroom_in_the_house.title`)}>
                          <Input
                            disabled
                            value={boolTraduction(survey.hasBathroomInside) || emptyInput}
                          />
                        </Form.Item>
                        <Form.Item label={t(`${tPrefix}bathroom_has.title`)}>
                          <Input
                            disabled
                            value={
                              survey.ielrOtherBathroomHas
                                ? `${t('other').toUpperCase()} ( ${survey.ielrOtherBathroomHas} )`
                                : survey.bathroomHas || emptyInput
                            }
                          />
                        </Form.Item>
                      </Space>
                    </InputsRow>
                    <InputsRow>
                      <Space>
                        <Form.Item label={t(`${tPrefix}has_drinking_water.title`)}>
                          <Input
                            disabled
                            value={boolTraduction(survey.drinkingWather) || emptyInput}
                          />
                        </Form.Item>
                        <Form.Item label={t(`${tPrefix}consumed_water_comes_from.title`)}>
                          <Input disabled value={survey.whaterCameFrom || emptyInput} />
                        </Form.Item>
                      </Space>
                    </InputsRow>
                    <InputsRow>
                      <Space>
                        <Form.Item label={t(`${tPrefix}has_electricity.title`)}>
                          <Input disabled value={survey.hasElectricity || emptyInput} />
                        </Form.Item>
                        <Form.Item label={t(`${tPrefix}electricity_presents.title`)}>
                          <Input
                            disabled
                            value={survey.electricalInstallationState || emptyInput}
                          />
                        </Form.Item>
                      </Space>
                    </InputsRow>
                    <InputsRow>
                      <Space>
                        <Form.Item label={t(`${tPrefix}cooking_fuel.title`)}>
                          <Input disabled value={survey.cookingFuel || emptyInput} />
                        </Form.Item>
                        <Form.Item label={t(`${tPrefix}kitchen_artifacts.title`)}>
                          <Input disabled value={survey.cookingElementsState || emptyInput} />
                        </Form.Item>
                      </Space>
                    </InputsRow>
                    <InputsRow>
                      <Space>
                        <Form.Item label={t(`${tPrefix}has_fridge.title`)}>
                          <Input
                            disabled
                            value={boolTraduction(survey.hasRefrigerator) || emptyInput}
                          />
                        </Form.Item>
                      </Space>
                    </InputsRow>
                    <InputsRow>
                      <Space>
                        <Form.Item label={t(`${tPrefix}observations.title`)}>
                          <TextArea
                            disabled
                            autoSize={{ minRows: 1 }}
                            value={survey.ielrObservations || emptyInput}
                          />
                        </Form.Item>
                      </Space>
                    </InputsRow>
                  </>
                )}
              </Panel>
            </CollapseForm>
          </StyledForm>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
    </SurveyModal>
  )
}

export default SeeSurveyModal

import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { LOCAL_STORAGE_KEYS, ROUTE_KEYS } from '@constants'
import { LevelEnum } from '@types'

import { ChangePassword } from './ChangePassword'
import { Login } from './Login'
import { RecoverPassword } from './RecoverPassword'

const LoginRouter = () => {
  const { url } = useRouteMatch()
  const user = window.localStorage.getItem(LOCAL_STORAGE_KEYS.USER)
  if (user) {
    const userObject = JSON.parse(user)
    const role = userObject.level as LevelEnum
    const lastLocation = window.localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_LOCATION)
    // eslint-disable-next-line no-nested-ternary
    const path = ROUTE_KEYS[role].BASE_URL + ROUTE_KEYS[role].HOME
    window.location.href = lastLocation || path
  }

  return (
    <Switch>
      <Route exact path={url} component={Login} />
      <Route path={`${url}/recover`} component={RecoverPassword} />
      <Route path={`${url}/change-password`} component={ChangePassword} />
    </Switch>
  )
}

export { LoginRouter }

import { Form, Input, notification, Select, Space } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetCloseCauses } from '@hooks'

import ConfirmActionModal from '../../../components/ConfirmActionModal'
import { CloserModal, InputsRow } from '../../../components'
import { useCloseOrder } from '../../hooks/useCloseOrder'

notification.config({
  placement: 'topRight',
  duration: 10,
})

interface OrderCloserPropsType {
  orderId: number | undefined
  isOrderCloserVisible: boolean
  onClose: () => void
}

const OrderCloserModal = ({ orderId, isOrderCloserVisible, onClose }: OrderCloserPropsType) => {
  const { t } = useTranslation()
  const tPrefix = 'globals.sections.orders.'
  const [form] = Form.useForm()
  const { TextArea } = Input
  const requiredMsg = t('globals.fields.required')
  const { Option } = Select
  const [isConfirmActionVisible, setIsConfirmActionVisible] = useState(false)
  const { closeOrder } = useCloseOrder()
  const [body, setBody] = useState({})
  const { closeCauses } = useGetCloseCauses()

  const closeAction = () => {
    form
      .validateFields()
      .then(data => {
        setIsConfirmActionVisible(true)
        setBody({ ...data, id: orderId })
      })
      .catch(() => {
        setIsConfirmActionVisible(false)
        notification.error({
          message: t(`${tPrefix}closer.validation_error`),
        })
      })
  }

  useEffect(() => {
    if (!isConfirmActionVisible) {
      onClose()
      form.resetFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmActionVisible])
  return (
    <CloserModal
      visible={isOrderCloserVisible}
      title={<h1>{t(`${tPrefix}closer.modal_title`) + orderId}</h1>}
      okText={t('globals.continue')}
      cancelText={t('globals.cancel')}
      onCancel={() => {
        onClose()
        form.resetFields()
      }}
      onOk={closeAction}
      centered
    >
      <ConfirmActionModal
        title={`${t(`${tPrefix}closer.close`)}${
          orderId && ` ${t('globals.item_types.order')} #${orderId}`
        }`}
        isConfirmActionVisible={isConfirmActionVisible}
        onClose={() => {
          setIsConfirmActionVisible(false)
        }}
        action={() => {
          setIsConfirmActionVisible(false)
          closeOrder(body)
          form.resetFields()
        }}
      />
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: 'public' }}
      >
        <InputsRow>
          <Space>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: requiredMsg,
                },
              ]}
              name="closeCause"
            >
              <Select
                getPopupContainer={(triggerNode: HTMLElement) =>
                  triggerNode.parentNode as HTMLElement
                }
                placeholder={t(`${tPrefix}closer.close_causes`)}
                allowClear
              >
                {closeCauses?.map(cause => (
                  <Option key={cause.id} value={cause.id}>
                    {cause.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Space>
        </InputsRow>
        <InputsRow>
          <Space>
            <Form.Item
              name="details"
              rules={[
                {
                  required: true,
                  message: requiredMsg,
                },
                {
                  type: 'string',
                  min: 3,
                  message: t(`${tPrefix}closer.close_details.error_msg`),
                },
              ]}
            >
              <TextArea
                maxLength={300}
                rows={4}
                placeholder={t(`${tPrefix}closer.close_details.title`)}
                allowClear
              />
            </Form.Item>
          </Space>
        </InputsRow>
      </Form>
    </CloserModal>
  )
}

export default OrderCloserModal

import React from 'react'
// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
import ReactDOM from 'react-dom'
import { ConfigProvider } from 'antd'
import locale from 'antd/lib/locale/es_ES'
import moment from 'moment'

import { App } from './features/App'
import 'antd/dist/antd.less'
import './i18n'
import reportWebVitals from './reportWebVitals'
import { setAuthHeader, removeAuthHeader, api } from './api'
import { LOCAL_STORAGE_KEYS } from './constants'
import 'moment/locale/es'

// Sentry.init({
//   dsn: 'https://99422958c4b14302b86b6bd8cfb29987@o982355.ingest.sentry.io/5989026',
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// })

moment.locale('es')
if (window.location.href.includes('login?token')) {
  api.post<Response>('/authentication/logout')
  removeAuthHeader()
  window.localStorage.clear()
  const tokens = window.location.search
    .slice(1)
    .split('~')
    .map(tokenKeyValue => tokenKeyValue.split('='))
  const map = new Map(tokens as [string, string][])
  const data = Object.fromEntries(map)
  window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESHED, 'false')
  window.localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, data.token)
  window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, data.refresh)
  window.localStorage.setItem(
    LOCAL_STORAGE_KEYS.USER,
    JSON.stringify({
      name: 'Empleador',
      level: 'EMPLOYER',
      token: data.token,
      refreshToken: data.refresh,
    }),
  )
  window.location.href = window.location.origin
}
const token = window.localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN)!
if (token) {
  setAuthHeader(token)
}

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={locale}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
